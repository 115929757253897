import React, { useState } from 'react'
import { Button } from '@govtechsg/sgds-react/Button'
import { Col, Row } from '@govtechsg/sgds-react'
import { Form } from '@govtechsg/sgds-react/Form'

function UserAddForm({
    departmentList,
    roleList,
    name,
    setName,
    phoneNumber,
    setPhoneNumber,
    jobTitle,
    setJobTitle,
    email,
    setEmail,
    password,
    setPassword,
    roleId,
    setRoleId,
    departmentId,
    setDepartmentId,
    onSubmit,
}) {
    const [error, setError] = useState('')

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)

        const passwordRegex =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,15}$/

        if (passwordRegex.test(e.target.value)) {
            setError('')
        } else {
            setError(
                'Password must contain 1 Special Character, 1 Upper Case Letter, 1 LowerCase Letter, 1 Special Character, and be 8-15 characters long'
            )
        }
    }

    return (
        <Form onSubmit={onSubmit}>
            <div className="bg-white py-2 px-4">
                <div>
                    <div className="btn-purple">
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        size="sm"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        required={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Department</Form.Label>
                                    <Form.Select
                                        aria-label="Department"
                                        size="sm"
                                        onChange={(e) =>
                                            setDepartmentId(e.target.value)
                                        }
                                    >
                                        <option
                                            value=""
                                            disabled={true}
                                            selected
                                        >
                                            Select Department
                                        </option>
                                        {departmentList.map((department) => (
                                            <option
                                                value={department.id}
                                                key={department.id}
                                            >
                                                {department.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Select
                                        aria-label="Role"
                                        size="sm"
                                        onChange={(e) =>
                                            setRoleId(e.target.value)
                                        }
                                        required={true}
                                    >
                                        <option
                                            value=""
                                            disabled={true}
                                            selected
                                        >
                                            Select Role
                                        </option>
                                        {roleList.map((role) => (
                                            <option
                                                value={role.id}
                                                key={role.id}
                                            >
                                                {role.type} ({role.name})
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Job Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        size="sm"
                                        value={jobTitle}
                                        onChange={(e) =>
                                            setJobTitle(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder=""
                                        size="sm"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder=""
                                        size="sm"
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder=""
                                        size="sm"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required={true}
                                    />
                                    {error && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {error}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Row className={`mt-3`}>
                <Col>
                    <div className="d-flex gap-3 btn-purple">
                        <Button variant="outline-dark" href="/people/users">
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Add User
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default UserAddForm
