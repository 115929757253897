import {
    Button,
    Dropdown,
    Form,
    Modal,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import CustomPagination from '@/components/CustomPagination'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function AssetLoan() {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [data, setData] = useState(null)
    const [asset, setAsset] = useState(null)

    const [loanId, setId] = useState('')

    const [showModalApprove, setShowModalApprove] = useState(false)

    const [showModalCancel, setShowModalCancel] = useState(false)

    const [showModalReturn, setShowModalReturn] = useState(false)

    const [showModalExtend, setShowModalExtend] = useState(false)

    const [loanDate, setLoanDate] = useState('')
    const [returnDate, setReturnDate] = useState('')

    const [loanRemark, setLoanRemark] = useState('')

    const [returnRemark, setReturnRemark] = useState('')

    const [expectedReturnDate, setExpectedReturnDate] = useState('')
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset Loan'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = (page = currentPage) => {
        if (typeof id === 'undefined') {
            return
        }
        setLoading(true)
        axios
            .get(
                `/api/v1/asset_loan/?assetId=${id}&page=${page}&size=${itemsPerPage}`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    const handleLoan = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/asset_loan/loan/`, {
                id: loanId,
                loanDate,
                loanRemark,
            })
            .then((response) => {
                toast.success('Loan Approved')
                setLoanDate('')
                setLoanRemark('')
                setShowModalApprove(false)
                getData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleCloseApprove = () => {
        setLoanDate('')
        setLoanRemark('')
        setShowModalApprove(false)
    }

    const handleCancel = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/asset_loan/cancel/${loanId}`)
            .then((response) => {
                toast.success('Loan Cancelled')
                setShowModalCancel(false)
                getData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleCloseCancel = () => {
        setShowModalCancel(false)
    }

    const handleReturn = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/asset_loan/return/`, {
                id: loanId,
                returnRemark,
                returnDate,
            })
            .then((response) => {
                toast.success('Loan Returned')
                setReturnRemark('')
                setShowModalReturn(false)
                getData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleCloseReturn = () => {
        setShowModalReturn(false)
    }

    const handleExtend = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/asset_loan/extend/`, {
                id: loanId,
                expectedReturnDate,
            })
            .then((response) => {
                toast.success('Loan Extended')
                setExpectedReturnDate('')
                setShowModalExtend(false)
                getData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleCloseExtend = () => {
        setShowModalExtend(false)
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAsset({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                        ...res.data,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    useEffect(function () {
        if (asset === null) {
            getAsset()
        }
    }, [])

    useEffect(
        function () {
            getData()
        },
        [currentPage, itemsPerPage]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu
                        reloadList={getData}
                        item={asset}
                        id={id}
                        activeMenu="asset_loan"
                    />
                </>
            )}

            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>DATE</TableHeaderCell>
                                <TableHeaderCell>LOAN TO</TableHeaderCell>
                                <TableHeaderCell>BORROW/LOAN</TableHeaderCell>
                                <TableHeaderCell>
                                    EXP. RETURN DATE
                                </TableHeaderCell>
                                <TableHeaderCell>RETURN DATE</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {currentPage - 1 + index + 1}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <CustomDate
                                                    date={
                                                        item?.expectedLoanDate ||
                                                        '-'
                                                    }
                                                />
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.loanedTo}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.type}
                                            </TableDataCell>
                                            <TableDataCell>
                                                est:{' '}
                                                <CustomDate
                                                    date={
                                                        item?.expectedLoanDate ||
                                                        '-'
                                                    }
                                                />{' '}
                                                {(item.status == 'Approved' ||
                                                    item.status ==
                                                        'Returned') && (
                                                    <>
                                                        / act:{' '}
                                                        <CustomDate
                                                            date={
                                                                item.loanDate ||
                                                                '-'
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </TableDataCell>
                                            <TableDataCell>
                                                est:{' '}
                                                <CustomDate
                                                    date={
                                                        item?.expectedReturnDate ||
                                                        '-'
                                                    }
                                                />{' '}
                                                {item.status == 'Returned' && (
                                                    <>
                                                        {' '}
                                                        / act:{' '}
                                                        <CustomDate
                                                            date={
                                                                item?.returnDate ||
                                                                '-'
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <BadgeCustom
                                                    status={
                                                        item.status ==
                                                        'Approved'
                                                            ? 'On Loan'
                                                            : item.status
                                                    }
                                                />
                                            </TableDataCell>
                                            <TableDataCell>
                                                {JSON.parse(
                                                    localStorage.getItem(
                                                        'currentAccessDtoList'
                                                    )
                                                ).some(
                                                    (access) =>
                                                        access.name ===
                                                        'Update Asset Loan'
                                                ) ? (
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            className={`btn-clear`}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="5"
                                                                viewBox="0 0 16 5"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                                    fill="#667085"
                                                                />
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/loan/edit/${item.id}`
                                                                    )
                                                                }
                                                            >
                                                                Edit Loan
                                                                Request
                                                            </Dropdown.Item>
                                                            {item.status.toLowerCase() ===
                                                                'created' && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setId(
                                                                            item.id
                                                                        )
                                                                        setShowModalApprove(
                                                                            true
                                                                        )
                                                                    }}
                                                                >
                                                                    Approve as
                                                                    On Loan
                                                                </Dropdown.Item>
                                                            )}
                                                            {item.status.toLowerCase() ===
                                                                'approved' && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setId(
                                                                            item.id
                                                                        )
                                                                        setShowModalReturn(
                                                                            true
                                                                        )
                                                                    }}
                                                                >
                                                                    Mark as
                                                                    Returned
                                                                </Dropdown.Item>
                                                            )}
                                                            {item.status.toLowerCase() ===
                                                                'approved' && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setId(
                                                                            item.id
                                                                        )
                                                                        setShowModalExtend(
                                                                            true
                                                                        )
                                                                    }}
                                                                >
                                                                    Extend Loan
                                                                </Dropdown.Item>
                                                            )}
                                                            {item.status.toLowerCase() ===
                                                                'created' && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setId(
                                                                            item.id
                                                                        )
                                                                        setShowModalCancel(
                                                                            true
                                                                        )
                                                                    }}
                                                                >
                                                                    Cancel Loan
                                                                    Request
                                                                </Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ) : null}
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
            </sgds-content-body>
            <Modal
                show={showModalExtend}
                onHide={handleCloseExtend}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Extend Loan</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleExtend}>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlId={'Form.loandate'}>
                                    <Form.Label>Exp. Return Date</Form.Label>
                                    <CustomDatePicker
                                        value={expectedReturnDate}
                                        required={true}
                                        onChange={(e) =>
                                            setExpectedReturnDate(e)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseExtend}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                size={'lg'}
                show={showModalApprove}
                onHide={handleCloseApprove}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve as On Loan</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleLoan}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.loandate'}>
                                    <Form.Label>Loan Date</Form.Label>
                                    <CustomDatePicker
                                        size="md"
                                        value={loanDate}
                                        required={true}
                                        onChange={(e) => setLoanDate(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.loanremarks">
                                    <Form.Label className="form-label">
                                        Loan Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={'form-control'}
                                        onChange={(e) =>
                                            setLoanRemark(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseApprove}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showModalCancel}
                onHide={handleCloseCancel}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className={`text-center`}>
                        Cancel Loan Request
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleCancel}>
                    <Modal.Body>
                        <Row className={`justify-content-center`}>
                            <Col xs={12}>
                                Are you sure you want to cancel the asset loan
                                request?
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-center`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseCancel}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Cancel Request
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                size={'lg'}
                show={showModalReturn}
                onHide={handleCloseReturn}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Return Asset</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleReturn}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.returnDate'}>
                                    <Form.Label>Return Date</Form.Label>
                                    <CustomDatePicker
                                        size={'md'}
                                        value={returnDate}
                                        required={true}
                                        onChange={(e) => setReturnDate(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.remarks">
                                    <Form.Label className="form-label">
                                        Return Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className={'form-control'}
                                        onChange={(e) =>
                                            setReturnRemark(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseReturn}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
