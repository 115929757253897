import CustomDatePicker from '@/components/CustomDatePicker'
import RequiredSymbol from '@/components/RequiredSymbol'
import {
    Button,
    Col,
    Form,
    FormSelect,
    Modal,
    Row,
} from '@govtechsg/sgds-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'

export default function NewAdHocMT({ show, setShow }) {
    const { id } = useParams()
    const handleClose = () => setShow(false)
    const [maintenance, setMaintenance] = useState({
        category: 'Ad-hoc',
        assetId: id,
    })
    const [vendorList, setVendorList] = useState([])

    const handleSubmitForm = (e) => {
        e.preventDefault()
        axios
            .post(`/api/v1/maintenance_schedule/`, maintenance)
            .then((response) => {
                setTimeout(function () {
                    handleClose()
                }, 2500)
                if (response.status === 200) {
                    toast.success('Success Create Ad Hoc Maintenance schedule')
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }

    const getVendorList = () => {
        axios
            .get(`/api/v1/attributes/vendor/list`)
            .then((res) => {
                const newArray = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setVendorList(newArray)
            })
            .catch((err) => {})
    }
    useEffect(() => {
        getVendorList()
    }, [])
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple"
        >
            <Modal.Header closeButton>
                <Modal.Title>New Ad Hoc Maintenance Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmitForm}>
                    <Row className="px-4">
                        <Col md={6}>
                            <Form.Group
                                className="mb-2"
                                controlId="formBasicText"
                            >
                                <Form.Label>
                                    Category <RequiredSymbol />
                                </Form.Label>
                                <FormSelect size="sm" disabled>
                                    <option value="Ad-hoc">Ad-hoc</option>
                                </FormSelect>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.expired">
                                <Form.Label className={'form-label'}>
                                    Scheduled Date
                                </Form.Label>
                                <CustomDatePicker
                                    value={maintenance.scheduledDate}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                scheduledDate: e,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.vendor">
                                <Form.Label className={'form-label'}>
                                    Vendor <RequiredSymbol />
                                </Form.Label>
                                <Select
                                    options={vendorList}
                                    required={true}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                vendorId: e.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.prnumber">
                                <Form.Label className={'form-label'}>
                                    PR Number <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={maintenance.prNo}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                prNo: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.ponumber">
                                <Form.Label className={'form-label'}>
                                    PO Number <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={maintenance.poNo}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                poNo: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.podate">
                                <Form.Label className={'form-label'}>
                                    PO Date <RequiredSymbol />
                                </Form.Label>
                                <CustomDatePicker
                                    required={true}
                                    value={maintenance.poDate}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                poDate: e,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.invoice">
                                <Form.Label className={'form-label'}>
                                    Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    value={maintenance.invoiceNo}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                invoiceNo: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.invoicedate">
                                <Form.Label className={'form-label'}>
                                    Invoice Date
                                </Form.Label>
                                <CustomDatePicker
                                    value={maintenance.invoiceDate}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                invoiceDate: e,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.cost">
                                <Form.Label className={'form-label'}>
                                    Cost (before GST) <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={maintenance.cost}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                cost: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.charging">
                                <Form.Label className={'form-label'}>
                                    Charging Account <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={maintenance.chargingAccount}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                chargingAccount: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.location">
                                <Form.Label className={'form-label'}>
                                    Location
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    value={maintenance.repairLocation}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                repairLocation: e.target.value,
                                            }
                                        })
                                    }
                                />
                                {/*<Select options={unitList} required={true} onChange={(val) => setUnitId(val)}/>*/}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.message">
                                <Form.Label className={'form-label'}>
                                    Message
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className={'form-control'}
                                    value={maintenance.note}
                                    onChange={(e) =>
                                        setMaintenance((prev) => {
                                            return {
                                                ...prev,
                                                note: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="mt-4">
                            <div className="d-flex gap-2">
                                <Button
                                    variant="outline-dark"
                                    className="px-3"
                                    size="sm"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    className="px-3"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
