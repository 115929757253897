import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function ReportFault() {
    const [assetId, setAssetId] = useState('all')
    const [assetList, setAssetList] = useState([])

    const [searchAsset, setSearchAsset] = useState('')
    const [fromDate, setFromDate] = useState('')

    const [toDate, setToDate] = useState('')

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Service & Maintenance Report'
        )
    ) {
        navigate('/dashboard')
    }

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getAssetList()
    }, [searchAsset])
    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="fault" />

            <sgds-content-body>
                <div className="bg-white shadow-sm rounded p-4 btn-purple">
                    <h2>Fault Report</h2>
                    <p className="fw-bold">
                        Generate a report with all the asset in the selected
                        location
                    </p>
                    <Row className="px-3">
                        <Col md={12}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Asset</Form.Label>
                                <Select
                                    options={assetList}
                                    required={true}
                                    onInputChange={(val) => setSearchAsset(val)}
                                    onChange={(e) => setAssetId(e.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Start Date</Form.Label>
                                <CustomDatePicker
                                    size="md"
                                    onChange={(e) => setFromDate(e)}
                                    value={fromDate}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>End Date</Form.Label>
                                <CustomDatePicker
                                    size="md"
                                    onChange={(e) => setToDate(e)}
                                    value={toDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="mt-4">
                            <Button
                                href={`/report/fault/${assetId}?fromDate=${fromDate}&toDate=${toDate}`}
                                size="sm"
                                className="px-3"
                            >
                                Generate
                            </Button>
                        </Col>
                    </Row>
                </div>
            </sgds-content-body>
        </TemplateDashboard>
    )
}
