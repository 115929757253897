import { Button, Col, Dropdown, Row } from '@govtechsg/sgds-react'
import { Link } from 'react-router-dom'
import UploadAttachment from './modal/UploadAttachment'
import { useEffect, useState } from 'react'
import NewAdHocMT from './modal/NewAdHocMT'
import AddLoan from './modal/AddLoan'
import UpdateStatus from './modal/UpdateStatus'
import axios from 'axios'

export default function AssetMenu({
    activeMenu,
    id,
    item = null,
    reloadList = () => {},
}) {
    const [showModalUploadAttachment, setShowModalUploadAttachment] =
        useState(false)
    const [showModalNewAdHocMT, setShowModalNewAdHocMT] = useState(false)
    const [showModalAddLoan, setShowModalAddLoan] = useState(false)
    const [showModalUpdateStatus, setShowModalUpdateStatus] = useState(false)
    const [currentAssetStatus, setCurrentAssetStatus] = useState(null)

    const listMenu = [
        {
            url: '/asset/' + id,
            label: 'Asset Overview',
            name: 'asset_overview',
        },
        {
            url: '/asset/log/' + id,
            label: 'Asset log',
            name: 'asset_log',
        },
        {
            url: '/asset/ticket/' + id,
            label: 'ticket list',
            name: 'asset_ticket',
        },
        {
            url: '/asset/maintenance/' + id,
            label: 'Asset maintenance',
            name: 'asset_maintenance',
        },
        {
            url: '/asset/loan/' + id,
            label: 'Asset loan',
            name: 'asset_loan',
        },
        {
            url: '/asset/attachments/' + id,
            label: 'attachments',
            name: 'asset_attachment',
        },
    ]
    let activeMenuVariant = 'outline-primary'

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentAssetStatus(res.data.status)
                    return
                }
            })
            .catch((error) => {})
    }

    useEffect(function () {
        if (currentAssetStatus !== null) {
            return
        }
        if (item === null) {
            getAsset()
            return
        }
        setCurrentAssetStatus(item.status)
    }, [])
    return (
        <>
            <Row>
                <Col md={9}>
                    <div className="asset-menu d-block wrap">
                        {listMenu.map((item) => {
                            return (
                                <Link to={item.url} key={item.name}>
                                    <Button
                                        variant={
                                            item.name === activeMenu
                                                ? activeMenuVariant
                                                : ''
                                        }
                                        size="sm"
                                        className={`${
                                            item.name === activeMenu
                                                ? 'active '
                                                : ''
                                        }text-uppercase fw-bold`}
                                    >
                                        {item.label}
                                    </Button>
                                </Link>
                            )
                        })}
                    </div>
                </Col>
                <Col md={3} className="justify-content-end">
                    <Dropdown className="justify-content-end d-flex">
                        <Dropdown.Toggle className={`btn-clear p-0`}>
                            <div className="btn-dropdown">
                                <i className="bi bi-three-dots-vertical"></i>
                                <small>Quick action</small>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setShowModalUploadAttachment(true)
                                }}
                            >
                                Upload Attachment
                            </Dropdown.Item>
                            {item !== null &&
                                (currentAssetStatus === 'Deployed' ||
                                    currentAssetStatus === 'In Repair' ||
                                    currentAssetStatus === 'Borrowed Out' ||
                                    currentAssetStatus === 'On Loan') && (
                                    <>
                                        <Dropdown.Item
                                            onClick={() => {
                                                setShowModalNewAdHocMT(true)
                                            }}
                                        >
                                            Create Ad Hoc Maintenance Schedule
                                        </Dropdown.Item>
                                        {JSON.parse(
                                            localStorage.getItem(
                                                'currentAccessDtoList'
                                            )
                                        ).some(
                                            (access) =>
                                                access.name ===
                                                'Create Asset Loan'
                                        ) ? (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setShowModalAddLoan(true)
                                                }}
                                            >
                                                Create Loan
                                            </Dropdown.Item>
                                        ) : null}
                                    </>
                                )}
                            {(currentAssetStatus === 'Deployed' ||
                                currentAssetStatus === 'Decommissioned') && (
                                <Dropdown.Item
                                    onClick={() => {
                                        setShowModalUpdateStatus(true)
                                    }}
                                >
                                    Update Status
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <UploadAttachment
                reloadList={reloadList}
                show={showModalUploadAttachment}
                setShow={setShowModalUploadAttachment}
            />
            {item !== null &&
                (currentAssetStatus === 'Deployed' ||
                    currentAssetStatus === 'In Repair' ||
                    currentAssetStatus === 'Borrowed Out' ||
                    currentAssetStatus === 'On Loan') && (
                    <NewAdHocMT
                        show={showModalNewAdHocMT}
                        setShow={setShowModalNewAdHocMT}
                    />
                )}
            {item !== null &&
                (currentAssetStatus === 'Deployed' ||
                    currentAssetStatus === 'In Repair' ||
                    currentAssetStatus === 'Borrowed Out' ||
                    currentAssetStatus === 'On Loan') && (
                    <AddLoan
                        reloadList={reloadList}
                        show={showModalAddLoan}
                        setShow={setShowModalAddLoan}
                    />
                )}
            {item !== null && (
                <UpdateStatus
                    show={showModalUpdateStatus}
                    setShow={setShowModalUpdateStatus}
                    item={item}
                />
            )}
        </>
    )
}
