import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function CustomDatePicker({
    disabled,
    required,
    size = 'sm',
    value = null,
    onChange,
    minDate = null,
    maxDate = null,
}) {
    const onChange_ = (date) => {
        let date_ = null
        if (date !== null) {
            date_ = moment(date).format('YYYY-MM-DD')
        }
        onChange(date_)
    }

    return (
        <DatePicker
            wrapperClassName="custom-react-wrapper"
            className={`form-control form-control-${size}`}
            selected={value}
            onChange={onChange_}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            showIcon={value !== null && value !== '' ? false : true}
            disabled={disabled}
            required={required}
            todayButton="Today"
            isClearable={value !== null && value !== '' ? true : false}
            toggleCalendarOnIconClick={true}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}
