import React from 'react'
import Sidebar from './Sidebar'
import 'react-toastify/dist/ReactToastify.css'

function TemplateDashboard(props) {
    return (
        <sgds-template-grid class="with-sidenav">
            <Sidebar />
            <sgds-content-area class="gap-4 content-dashboard">
                {props.children}
            </sgds-content-area>
        </sgds-template-grid>
    )
}

export default TemplateDashboard
