import { Button, Modal } from '@govtechsg/sgds-react'

export default function ModalDelete({
    title,
    message,
    show,
    handleClose,
    handleDelete,
}) {
    return (
        <Modal show={show} onHide={handleClose} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="bi bi-exclamation-triangle me-3"></i>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
