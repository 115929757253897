import CustomDatePicker from '@/components/CustomDatePicker'
import {
    Button,
    Col,
    Form,
    FormSelect,
    Modal,
    Row,
} from '@govtechsg/sgds-react'
import axios from 'axios'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
export default function AddLoan({ show, setShow, reloadList = () => {} }) {
    const { id } = useParams()
    const [loan, setLoan] = useState({
        assetId: id,
        loanedTo: '',
        borrowerEmail: '',
        borrowerPhoneNo: '',
        expectedReturnDate: '',
        expectedLoanDate: '',
        type: '',
    })
    const handleClose = () => setShow(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        axios
            .post(`/api/v1/asset_loan/`, loan)
            .then((response) => {
                reloadList()
                handleClose()
                if (response.status === 200) {
                    toast.success('Success Add Loan')
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple"
        >
            <Modal.Header closeButton>
                <Modal.Title>Create New Loan</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit}>
                    <Row className="px-4">
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Loan to</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                    value={loan.loanedTo}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                loanedTo: e.target.value,
                                            }
                                        })
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Internal/External Loan</Form.Label>
                                <FormSelect
                                    size="sm"
                                    value={loan.type}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                type: e.target.value,
                                            }
                                        })
                                    }
                                    required
                                >
                                    <option value="">
                                        Open this select menu
                                    </option>
                                    <option value={'Internal Loan'}>
                                        Internal Loan
                                    </option>
                                    <option value={'External Loan'}>
                                        External Loan
                                    </option>
                                </FormSelect>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    size="sm"
                                    value={loan.borrowerPhoneNo}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                borrowerPhoneNo: e.target.value,
                                            }
                                        })
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    size="sm"
                                    value={loan.borrowerEmail}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                borrowerEmail: e.target.value,
                                            }
                                        })
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Expected Loan Date</Form.Label>
                                <CustomDatePicker
                                    size="sm"
                                    value={loan.expectedLoanDate}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                expectedLoanDate: e,
                                            }
                                        })
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Expected Return Date</Form.Label>
                                <CustomDatePicker
                                    size="sm"
                                    value={loan.expectedReturnDate}
                                    onChange={(e) =>
                                        setLoan((prev) => {
                                            return {
                                                ...prev,
                                                expectedReturnDate: e,
                                            }
                                        })
                                    }
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mt-4">
                            <div className="d-flex gap-2">
                                <Button
                                    variant="outline-dark"
                                    className="px-3"
                                    size="sm"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    className="px-3"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
