import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import * as XLSX from 'xlsx'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import AssetHistoryList from '@/pages/Report/assetHistoryList'
import asset from '@/pages/Asset'
import { useNavigate } from 'react-router-dom'

export default function ReportAssetHistory() {
    const [assetList, setAssetList] = useState([])

    const [searchAsset, setSearchAsset] = useState('')

    const [assetId, setAssetId] = useState('')

    const [logs, setLogs] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Service & Maintenance Report'
        )
    ) {
        navigate('/dashboard')
    }

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                toast.error('You are not allowed')
            })
    }

    const fetchData = async () => {
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/asset_log/?assetId=${assetId}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setLogs(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
            toast.error(error.response.data.message)
        }
    }

    const handleGenerate = (e) => {
        e.preventDefault()

        fetchData()
    }

    const handleExportExcel = () => {
        axios
            .get(`/api/v1/asset_log/list?assetId=${assetId}`)
            .then((res) => {
                const data = res.data.map((item, index) => ({
                    'NO.': index + 1,
                    ASSET: item.name ?? '-',
                    DESCRIPTION: item.description ?? '-',
                    STATUS: item.status,
                    'CURRENT LOCATION': item.buildingName ?? '-',
                    'ASSET USER': item.assetUserName ?? '-',
                    'CREATED BY': item.creatorUsername ?? '-',
                    'CREATION DATE': moment(item.createdAt).format(
                        'YYYY-MM-DD'
                    ),
                }))

                const wb = XLSX.utils.book_new()

                const ws = XLSX.utils.json_to_sheet(data)

                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

                const fileName = `INDIVIDUAL-ASSETS-HISTORICAL-${moment().format(
                    'YYYY-MM-DD'
                )}.xlsx`

                toast.success('Success Export to Excel')

                XLSX.writeFile(wb, fileName)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        if (assetId) fetchData()
    }, [itemsPerPage, currentPage])

    useEffect(() => {
        getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="individual_asset_history" />
            <sgds-content-body>
                {logs.length > 0 ? (
                    <AssetHistoryList
                        handleExportExcel={handleExportExcel}
                        logs={logs}
                        setLogs={setLogs}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPage={itemsPerPage}
                        totalData={totalData}
                    />
                ) : (
                    <div className="bg-white shadow-sm rounded p-4 btn-purple">
                        <h2>Individual Asset Historical Report</h2>
                        <p className="fw-bold">
                            Generate Individual Assets Historical Report
                        </p>
                        <Form onSubmit={handleGenerate}>
                            <Row className="px-3">
                                <Col md={12}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicText"
                                    >
                                        <Form.Label>Asset</Form.Label>
                                        <Form.Text className="text-muted">
                                            Search by typing asset name, tag, or
                                            PO number.
                                        </Form.Text>
                                        <Select
                                            options={assetList}
                                            required={true}
                                            onInputChange={(val) =>
                                                setSearchAsset(val)
                                            }
                                            onChange={(e) =>
                                                setAssetId(e.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Button
                                        type={'submit'}
                                        size="sm"
                                        className="px-3"
                                    >
                                        Generate
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </sgds-content-body>
        </TemplateDashboard>
    )
}
