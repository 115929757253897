export function getQueryParam(url, param) {
  try {
    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);
    return queryParams.get(param);
  } catch (error) {
    if (error instanceof TypeError) {
      // Handle invalid URL error
      console.error("Invalid URL provided");
      return false; // or any other error handling mechanism
    } else {
      throw error; // Re-throw other unexpected errors
    }
  }
}
