import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import moment from 'moment'
import React from 'react'
import CustomPagination from '@/components/CustomPagination'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'

export default function AssetHistoryList({
    logs,
    setLogs,
    setCurrentPage,
    setItemsPerPage,
    currentPage,
    itemsPerPage,
    totalData,
    handleExportExcel,
}) {
    return (
        <>
            <div className="bg-white shadow-sm rounded p-4 btn-purple">
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => setLogs([])}>
                        INDIVIDUAL ASSETS HISTORICAL REPORT
                    </BreadcrumbItem>
                    <BreadcrumbItem active>ASSET</BreadcrumbItem>
                </Breadcrumb>
                <Table borderless={true} hover={true} size="sm" responsive="lg">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>NO</TableHeaderCell>
                            <TableHeaderCell>ASSET</TableHeaderCell>
                            <TableHeaderCell>DESCRIPTION</TableHeaderCell>
                            <TableHeaderCell>STATUS</TableHeaderCell>
                            <TableHeaderCell>CURRENT LOCATION</TableHeaderCell>
                            <TableHeaderCell>ASSET USER</TableHeaderCell>
                            <TableHeaderCell>CREATED BY</TableHeaderCell>
                            <TableHeaderCell>CREATION DATE</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow key={index}>
                                <TableDataCell>
                                    {itemsPerPage * currentPage -
                                        itemsPerPage +
                                        index +
                                        1}
                                </TableDataCell>
                                <TableDataCell>{log.name ?? '-'}</TableDataCell>
                                <TableDataCell>
                                    {log.description ?? '-'}
                                </TableDataCell>
                                <TableDataCell>
                                    <BadgeCustom status={log.status} />
                                </TableDataCell>
                                <TableDataCell>
                                    {log.buildingName ?? '-'}
                                </TableDataCell>
                                <TableDataCell>
                                    {log.assetUserName ?? '-'}
                                </TableDataCell>
                                <TableDataCell>
                                    {log.creatorUsername ?? '-'}
                                </TableDataCell>
                                <TableDataCell>
                                    <CustomDate date={log.createdAt} />
                                </TableDataCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <CustomPagination
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                itemsPerPageDefault={10}
                totalData={totalData}
            />
            <Row className="btn-purple">
                <Col>
                    <Button
                        size="sm"
                        className="px-3"
                        onClick={handleExportExcel}
                        type={'button'}
                    >
                        Export to excel
                    </Button>
                </Col>
            </Row>
        </>
    )
}
