import { Button, Col, Form, Modal, Row } from "@govtechsg/sgds-react";
import MaintenanceForm from "../MaintenanceForm";
export default function NewContract({ show, setShow, assetId, vendorList, formMaintenanceVendor, onChangeFormMaintenanceVendor, handleClickSubmitNewFormMaintenance }) {
    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        handleClickSubmitNewFormMaintenance(function(){
            handleClose()
        });
        }

    return (
        <Modal show={show} onHide={handleClose} centered={true} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>New Maintenance Contract</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 px-6">
                <Form onSubmit={handleSubmit}>
                <MaintenanceForm
                    disabled={false}
                    assetId={assetId}
                    isEdit={false}
                    vendorList={vendorList}
                    formMaintenanceVendor={formMaintenanceVendor}
                    onChangeFormMaintenanceVendor={onChangeFormMaintenanceVendor}

                />
                <Row className="mt-4">
                    <Col>
                        <div className="d-flex gap-3">
                            <Button variant="outline-dark" onClick={handleClose}>Cancel</Button>
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>
                    </Col>
                </Row>
                </Form>
            </Modal.Body>
        </Modal>

    )
}