import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'

export default function ReportAsset() {
    const [id, setId] = useState('all')
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Asset List Report'
        )
    ) {
        navigate('/dashboard')
    }

    const listStatus = [
        {
            value: 'Requested',
            label: 'Requested',
        },
        {
            value: 'Pending',
            label: 'Pending',
        },
        {
            value: 'Commissioned',
            label: 'Commissioned',
        },
        {
            value: 'Deployed',
            label: 'Deployed',
        },
        {
            value: 'In Repair',
            label: 'In Repair',
        },
        {
            value: 'Decommissioned',
            label: 'Decommissioned',
        },
        {
            value: 'Archived',
            label: 'Archived',
        },
        {
            value: 'Borrowed Out',
            label: 'Borrowed Out',
        },
        {
            value: 'On Loan',
            label: 'On Loan',
        },
        {
            value: 'Transferred',
            label: 'Transferred',
        },
        {
            value: 'MIA',
            label: 'MIA',
        },
    ]

    const handleOnChange = (e) => {
        setId(e.value)
    }

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="asset_list" />

            <sgds-content-body>
                <div className="bg-white shadow-sm rounded p-4 btn-purple">
                    <h2>Asset List Report</h2>
                    <p className="fw-bold">
                        Generate a report with all the tasks in the selected
                        period and a summary of all assets
                    </p>
                    <Row className="px-3">
                        <Col md={12}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Status</Form.Label>
                                <Select
                                    options={listStatus}
                                    onChange={handleOnChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="mt-4">
                            <Button
                                // href="/report/asset/1"
                                type="button"
                                size="sm"
                                className="px-3"
                                onClick={() => navigate(`/report/asset/${id}`)}
                            >
                                Generate
                            </Button>
                        </Col>
                    </Row>
                </div>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
