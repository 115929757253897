import { Col, Form, Row } from '@govtechsg/sgds-react'
import { FormSelect } from 'react-bootstrap'

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Select from 'react-select'
import moment from 'moment'
import { toast } from 'react-toastify'
import CustomDatePicker from '../CustomDatePicker'

export default function MaintenanceForm({
    disabled = false,
    assetId,
    isEdit,
    vendorList,
    formMaintenanceVendor,
    onChangeFormMaintenanceVendor,
    handleClickSubmitNewFormMaintenance,
    detailData,
    indexData,
    isUser = false,
}) {
    const preventiveMaintenanceTypeList = [
        'None',
        'Comprehensive',
        'Partial Comprehensive',
        'Non Comprehensive',
    ]

    const contractFrequency = [
        {
            label: '3 Months',
            value: 3,
        },
        {
            label: '6 Months',
            value: 6,
        },
        {
            label: '12 Months',
            value: 12,
        },
    ]

    const [selectedContractFrequency, setSelectedContractFrequency] =
        useState(0)

    const handleOnChangeMaintenanceVendor = (e) => {
        const selectedOptionElement = e.target.options[e.target.selectedIndex]
        // Getting the text content of the selected option
        const selectedOptionContent = selectedOptionElement.textContent

        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                vendorId: e.target.value,
                vendorName: selectedOptionContent,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                vendorId: e.target.value,
                vendorName: selectedOptionContent,
            })
        }
    }

    const handleVendorContractStartDate = (e) => {
        if (isEdit) {
            if (e >= formMaintenanceVendor.poDate) {
                onChangeFormMaintenanceVendor(indexData, {
                    ...formMaintenanceVendor,
                    vendorContractStartDate: e,
                })
            } else {
                toast.error(
                    'Vendor Contract Start Date must be greater than PO Date'
                )
            }
        } else {
            if (e >= formMaintenanceVendor.poDate) {
                onChangeFormMaintenanceVendor({
                    ...formMaintenanceVendor,
                    vendorContractStartDate: e,
                })
            } else {
                toast.error(
                    'Vendor Contract Start Date must be greater than PO Date'
                )
            }
        }
    }

    const handleVendorContractEndDate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                vendorContractEndDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                vendorContractEndDate: e,
            })
        }
    }

    const handleOnChangePreventiveMaintenanceType = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                preventiveMaintenanceType: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                preventiveMaintenanceType: e.target.value,
            })
        }
    }

    const handleOnChangePRNo = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                prNo: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                prNo: e.target.value,
            })
        }
    }

    const handleOnChangePONo = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                poNo: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                poNo: e.target.value,
            })
        }
    }

    const handleOnChangePODate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                poDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                poDate: e,
            })
        }
    }

    const handleOnChangeInvoiceNo = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                invoiceNo: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                invoiceNo: e.target.value,
            })
        }
    }

    const handleOnChangeInvoiceDate = (e) => {
        if (isEdit) {
            if (e <= formMaintenanceVendor.poDate) {
                toast.error('Invoice Date must be greater than PO Date')
            } else {
                onChangeFormMaintenanceVendor(indexData, {
                    ...formMaintenanceVendor,
                    invoiceDate: e,
                })
            }
        } else {
            if (e <= formMaintenanceVendor.poDate) {
                toast.error('Invoice Date must be greater than PO Date')
            } else {
                onChangeFormMaintenanceVendor({
                    ...formMaintenanceVendor,
                    invoiceDate: e,
                })
            }
        }
    }

    const handleOnChangeLastPreventiveMaintenanceDate = (e) => {
        // do logic here
        let currentDate = new Date(e)
        currentDate.setMonth(
            currentDate.getMonth() +
                Number(formMaintenanceVendor.preventiveMaintenanceFrequency)
        )

        let newFutureDate = moment(currentDate).format('YYYY-MM-DD')

        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                lastPreventiveMaintenanceDate: e,
                preventiveMaintenanceDueDate: newFutureDate,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                lastPreventiveMaintenanceDate: e,
                preventiveMaintenanceDueDate: newFutureDate,
            })
        }
    }

    const handleOnChangeLastCertificationDate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                lastCertificationDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                lastCertificationDate: e,
            })
        }
    }

    const handleOnChangePreventiveMaintenanceFrequency = (e) => {
        // do logic here
        let currentDate = new Date(
            formMaintenanceVendor.lastPreventiveMaintenanceDate
        )
        currentDate.setMonth(currentDate.getMonth() + Number(e.target.value))
        let newFutureDate = moment(currentDate).format('YYYY-MM-DD')

        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                preventiveMaintenanceFrequency: Number(e.target.value),
                preventiveMaintenanceDueDate: newFutureDate,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                preventiveMaintenanceFrequency: Number(e.target.value),
                preventiveMaintenanceDueDate: newFutureDate,
            })
        }
    }

    const handleOnChangeCertificationFrequency = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                certificationFrequency: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                certificationFrequency: e.target.value,
            })
        }
    }
    const handleOnChangePreventiveMaintenanceDueDate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                preventiveMaintenanceDueDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                preventiveMaintenanceDueDate: e,
            })
        }
    }
    const handleOnChangeCertificationDueDate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                certificationDueDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                certificationDueDate: e,
            })
        }
    }

    const handleOnChangeRemark = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                remark: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                remark: e.target.value,
            })
        }
    }

    const handleOnChangeWarrantyMonth = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                warrantyMonth: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                warrantyMonth: e.target.value,
            })
        }
    }

    const handleOnChangeWarrantyEndDate = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                warrantyEndDate: e,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                warrantyEndDate: e,
            })
        }
    }

    const handleOnChangeStatus = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                status: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                status: e.target.value,
            })
        }
    }

    const handleOnChangeChargingAccount = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                chargingAccount: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                chargingAccount: e.target.value,
            })
        }
    }

    const handleOnChangeGLNumber = (e) => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, {
                ...formMaintenanceVendor,
                glNumber: e.target.value,
            })
        } else {
            onChangeFormMaintenanceVendor({
                ...formMaintenanceVendor,
                glNumber: e.target.value,
            })
        }
    }

    useEffect(() => {
        if (isEdit) {
            onChangeFormMaintenanceVendor(indexData, detailData)
            // formMaintenanceVendor[0]
        }
    }, [])

    return (
        <div>
            <div id={'maintenance_vendor_' + indexData}>
                <p className="text-purple fw-bold text-uppercase mb-1">
                    Maintenance Vendor
                </p>
            </div>
            <div>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Maintenance Vendor{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            {/* <Select options={vendorList} onChange={handleOnChangeMaintenanceVendor} disabled={disabled} /> */}
                            <FormSelect
                                aria-label="Category"
                                id="defaultSelect"
                                size="sm"
                                disabled={disabled}
                                onChange={handleOnChangeMaintenanceVendor}
                                value={formMaintenanceVendor?.vendorId}
                                required
                            >
                                <option value="">Select Vendor</option>
                                {vendorList.map((data, index) => {
                                    return (
                                        <option key={index} value={data?.id}>
                                            {data?.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Vendor Contract Start Date{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <CustomDatePicker
                                required={true}
                                disabled={disabled}
                                size="sm"
                                onChange={handleVendorContractStartDate}
                                value={
                                    formMaintenanceVendor?.vendorContractStartDate
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Vendor Contract End Date{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <CustomDatePicker
                                required={true}
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleVendorContractEndDate}
                                value={
                                    formMaintenanceVendor?.vendorContractEndDate
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Preventive Maintenance Type{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled}
                                onChange={
                                    handleOnChangePreventiveMaintenanceType
                                }
                                value={
                                    formMaintenanceVendor?.preventiveMaintenanceType
                                }
                                required
                            >
                                <option value="">
                                    Select Maintenance Type
                                </option>
                                {preventiveMaintenanceTypeList.map(
                                    (data, index) => {
                                        return (
                                            <option key={index} value={data}>
                                                {data}
                                            </option>
                                        )
                                    }
                                )}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                PR Number <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangePRNo}
                                value={formMaintenanceVendor?.prNo}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                PO Number <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangePONo}
                                value={formMaintenanceVendor?.poNo}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                PO Date <span className="text-danger">*</span>
                            </Form.Label>
                            <CustomDatePicker
                                required={true}
                                disabled={disabled}
                                size="sm"
                                onChange={handleOnChangePODate}
                                value={formMaintenanceVendor?.poDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Invoice Number </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeInvoiceNo}
                                value={formMaintenanceVendor?.invoiceNo}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Invoice Date </Form.Label>
                            <CustomDatePicker
                                disabled={disabled}
                                size="sm"
                                onChange={handleOnChangeInvoiceDate}
                                value={formMaintenanceVendor?.invoiceDate}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Charging Account{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeChargingAccount}
                                value={formMaintenanceVendor?.chargingAccount}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                GL Number <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeGLNumber}
                                value={formMaintenanceVendor?.glNumber}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-3" id={'maintenance_schedule_' + indexData}>
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        Maintenance Schedule
                    </p>
                </div>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Last Preventive Maintenance Date{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <CustomDatePicker
                                required={true}
                                disabled={disabled}
                                size="sm"
                                onChange={
                                    handleOnChangeLastPreventiveMaintenanceDate
                                }
                                value={
                                    formMaintenanceVendor?.lastPreventiveMaintenanceDate
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Last Certification Date</Form.Label>
                            <CustomDatePicker
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeLastCertificationDate}
                                value={
                                    formMaintenanceVendor?.lastCertificationDate
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Preventive Maintenance Frequency{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled}
                                onChange={
                                    handleOnChangePreventiveMaintenanceFrequency
                                }
                                value={
                                    formMaintenanceVendor?.preventiveMaintenanceFrequency
                                }
                                required
                            >
                                <option value="">Select Frequency</option>
                                {contractFrequency.map((data, index) => {
                                    return (
                                        <option key={index} value={data.value}>
                                            {data.label}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Certification Frequency </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled}
                                onChange={handleOnChangeCertificationFrequency}
                                value={
                                    formMaintenanceVendor?.certificationFrequency
                                }
                            >
                                <option value="">Select Frequency</option>
                                {contractFrequency.map((data, index) => {
                                    return (
                                        <option key={index} value={data.value}>
                                            {data.label}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>
                                Preventive Maintenance Due Date{' '}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <CustomDatePicker
                                disabled={disabled}
                                size="sm"
                                onChange={
                                    handleOnChangePreventiveMaintenanceDueDate
                                }
                                value={
                                    formMaintenanceVendor?.preventiveMaintenanceDueDate
                                }
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Certification Due Date</Form.Label>
                            <CustomDatePicker
                                disabled={disabled}
                                size="sm"
                                onChange={handleOnChangeCertificationDueDate}
                                value={
                                    formMaintenanceVendor?.certificationDueDate
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeRemark}
                                value={formMaintenanceVendor?.remark}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-3" id="supplier">
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        Warranty period
                    </p>
                </div>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Warranty Months</Form.Label>
                            <Form.Control
                                type="number"
                                disabled={disabled || isUser}
                                placeholder=""
                                size="sm"
                                onChange={handleOnChangeWarrantyMonth}
                                value={formMaintenanceVendor?.warrantyMonth}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Warranty End Date</Form.Label>
                            <CustomDatePicker
                                disabled={disabled || isUser}
                                size="sm"
                                onChange={handleOnChangeWarrantyEndDate}
                                value={formMaintenanceVendor?.warrantyEndDate}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
