import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import { Link, useNavigate } from 'react-router-dom'
import TemplateDashboard from '@/components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'

export default function AddTransfer() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Transfer Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const [assetList, setAssetList] = useState([])

    const [assetId, setAssetId] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const [remark, setRemark] = useState('')

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}&status=Deployed`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.id,
                    label: `${item.name} ${
                        item.hanaTag !== null ? ` - ${item.hanaTag}` : ''
                    }${
                        item.assetCategoryName !== null
                            ? ` - ${item.assetCategoryName}`
                            : ''
                    }${
                        item.serialNumber !== null
                            ? ` - ${item.serialNumber}`
                            : ''
                    }${
                        item.supplierPoNo !== null
                            ? ` - ${item.supplierPoNo}`
                            : ''
                    }${
                        item.buildingName !== null
                            ? ` - ${item.buildingName}`
                            : ''
                    }${
                        item.supplierName !== null
                            ? ` - ${item.supplierName}`
                            : ''
                    }`,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        axios
            .post(`/api/v1/asset_transfer/`, {
                assetId,
                remark,
            })
            .then((res) => {
                toast.success('Success Add Asset Transfer')

                setTimeout(() => {
                    navigate('/transfer')
                }, 1000)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>New Transfer Asset</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/transfer')}>
                    Transfer asset
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    New transfer asset
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <Row
                        className={`shadow-sm bg-white p-7`}
                        style={{ rowGap: '10px' }}
                    >
                        <Col xs={12}>
                            <Form.Label>Choose Asset</Form.Label>
                            <Select
                                options={assetList}
                                required={true}
                                onInputChange={(val) => setSearchAsset(val)}
                                onChange={(e) => setAssetId(e.value)}
                            />
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.location">
                                <Form.Label className={'form-label'}>
                                    Remark
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        setRemark(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className={'d-flex mt-4 gap-4'}>
                        <Link
                            className="btn btn-outline-dark sgds"
                            to={'/transfer'}
                        >
                            Cancel
                        </Link>
                        <Button variant="primary" type={'submit'}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
