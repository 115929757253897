import React, { useEffect, useState } from 'react'
import { Navbar } from '@govtechsg/sgds-react/Nav'
import { Container } from '@govtechsg/sgds-react/Container'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import NTULKCLogo from '@/logo.png'
import { Button } from '@govtechsg/sgds-react/Button'
import { Form } from '@govtechsg/sgds-react/Form'
import { Breadcrumb } from '@govtechsg/sgds-react/Breadcrumb'
import { Table } from '@govtechsg/sgds-react/Table'
import { FileUpload } from '@govtechsg/sgds-react/FileUpload'
import axios from 'axios'
import { Scanner } from '@yudiel/react-qr-scanner'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'react-router-dom'
import { getQueryParam } from '@/components/utils/utils'

function PublicPage() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const scanTag = queryParams.get('scanTag')

    const [active, setActive] = useState('home')
    const [showMainPage, setShowMainPage] = useState('main')
    const [selectedActiveScanTag, setSelectedActiveScanTag] = useState(true)
    const [ongoingTicketList, setOngoingTicketList] = useState([1])
    const [selectedFile, setSelectedFile] = useState({})
    const [selectedFileImage1, setSelectedFileImage1] = useState({})
    const [selectedFileImage2, setSelectedFileImage2] = useState({})
    const [selectedFileImage3, setSelectedFileImage3] = useState({})
    const [selectedFileVideo, setSelectedFileVideo] = useState({})

    const [assetTagId, setAssetTagId] = useState(scanTag ? scanTag : '')
    const [assetContent, setAssetContent] = useState({})
    const [createNewTicket, setCreateNewTicket] = useState({
        assetId: '',
        assetName: '',
        userName: '',
        userEmail: '',
        userPhone: '',
        note: '',
        ticketFileDtoList: [],
        createdBy: '',
        creatorUsername: '',
    })

    const [ticketFileDtoListA, setTicketFileDtoListA] = useState([])

    const clickNavbarItem = (eventKey) => {
        setActive(eventKey)
    }

    const onChangeFile = (files, type) => {
        let formData = new FormData()
        let isCanUpload = false
        if (type == 'image1') {
            if (files[0].size <= 5 * 1024 * 1024) {
                isCanUpload = true
                // Check file size (5 MB limit)
                formData.append('file', files[0])
                setSelectedFileImage1(files)
            } else {
                toast.error('Maximum file image must less than 5MB', {
                    autoClose: 10000,
                })
            }
        } else if (type == 'image2') {
            if (files[0].size <= 5 * 1024 * 1024) {
                isCanUpload = true
                // Check file size (5 MB limit)
                formData.append('file', files[0])
                setSelectedFileImage2(files)
            } else {
                toast.error('Maximum file image must less than 5MB', {
                    autoClose: 10000,
                })
            }
        } else if (type == 'image3') {
            if (files[0].size <= 5 * 1024 * 1024) {
                isCanUpload = true
                // Check file size (5 MB limit)
                formData.append('file', files[0])
                setSelectedFileImage3(files)
            } else {
                toast.error('Maximum file image must less than 5MB', {
                    autoClose: 10000,
                })
            }
        } else if (type == 'video') {
            if (files[0].size <= 20 * 1024 * 1024) {
                formData.append('file', files[0])
                setSelectedFileVideo(files)
                isCanUpload = true
            } else {
                toast.error('Maximum file video must less than 20MB', {
                    autoClose: 10000,
                })
            }
        } else {
            // do nothing
            return false
        }

        if (isCanUpload) {
            axios
                .post('/public/upload_ticket_file', formData)
                .then((data, index) => {
                    if (data?.status == 200) {
                        const resp = data?.data
                        if (ticketFileDtoListA.length > 0) {
                            const newArray = [
                                ...ticketFileDtoListA,
                                {
                                    fileName: resp?.fileName,
                                    fileUrl: resp?.url,
                                },
                            ]
                            setTicketFileDtoListA(newArray)
                            setCreateNewTicket({
                                ...createNewTicket,
                                ticketFileDtoList: [
                                    ...ticketFileDtoListA,
                                    {
                                        fileName: resp?.fileName,
                                        fileUrl: resp?.url,
                                    },
                                ],
                            })
                        } else {
                            setCreateNewTicket({
                                ...createNewTicket,
                                ticketFileDtoList: [
                                    {
                                        fileName: resp?.fileName,
                                        fileUrl: resp?.url,
                                    },
                                ],
                            })
                            setTicketFileDtoListA([
                                {
                                    fileName: resp?.fileName,
                                    fileUrl: resp?.url,
                                },
                            ])
                        }
                    }
                })
        }

        setSelectedFile(files)
    }

    const selectedScanAssetTag = (params) => {
        setSelectedActiveScanTag(params)
    }

    const handleClickCheckID = (params) => {
        axios
            .get('/public/asset/' + assetTagId)
            .then((data, index) => {
                if (data.status == 200) {
                    setAssetContent(data?.data)
                    const resp = data?.data
                    setCreateNewTicket({
                        assetId: resp?.id,
                        assetName: resp?.name,
                        userName: '',
                        userEmail: '',
                        userPhone: '',
                        note: '',
                        // ticketFileDtoList: resp?.ticketDtoList,
                        createdBy: '',
                        creatorUsername: '',
                    })
                    if (showMainPage !== 'createTicket') {
                        setShowMainPage('detailTicket')
                    }
                } else {
                    alert('Asset not found')
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message, { autoClose: 10000 })
                // alert('Asset not found')
            })
    }

    const handleSubmitTicket = () => {
        axios
            .post('/public/ticket', createNewTicket)
            .then((data, index) => {
                if (data.status == 200) {
                    toast.success('Success submit your ticket', {
                        position: 'top-right',
                        autoClose: 5000,
                    })

                    setShowMainPage('detailTicket')
                    getOngoingTicketList()
                } else {
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message, { autoClose: 10000 })
            })
    }

    const handleOnChangeFile = async (e) => {
        const fileForm = new FormData()
        fileForm.append('file', e.target.files[0])
        axios
            .post('/public/upload_ticket_file', fileForm)
            .then((data, index) => {})
    }

    const handleOnScanQRCode = (result) => {
        let rawValue = ''
        if (getQueryParam(result[0].rawValue, 'scanTag')) {
            rawValue = getQueryParam(result[0].rawValue, 'scanTag')
        } else {
            rawValue = result[0].rawValue
        }
        // const rawValue = getQueryParam(result[0].rawValue, 'scanTag')
        // const rawValue = result[0].rawValue
        if (rawValue) {
            setAssetTagId(rawValue)
            axios
                .get('/public/asset/' + rawValue)
                .then((data, index) => {
                    if (data.status == 200) {
                        setAssetContent(data?.data)
                        const resp = data?.data
                        setCreateNewTicket({
                            assetId: resp?.id,
                            assetName: resp?.name,
                            userName: '',
                            userEmail: '',
                            userPhone: '',
                            note: '',
                            // ticketFileDtoList: resp?.ticketDtoList,
                            createdBy: '',
                            creatorUsername: '',
                            assetLocation: '',
                            name: '',
                        })

                        setShowMainPage('detailTicket')
                    } else {
                        alert('Asset not found')
                    }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message, {
                        autoClose: 10000,
                    })
                    // alert('Asset not found')
                })
        }
    }

    const getOngoingTicketList = () => {
        axios
            .get('/public/asset/' + assetTagId)
            .then((data, index) => {
                if (data.status == 200) {
                    setAssetContent(data?.data)
                    const resp = data?.data
                    setCreateNewTicket({
                        assetId: resp?.id,
                        assetName: resp?.name,
                        userName: '',
                        userEmail: '',
                        userPhone: '',
                        note: '',
                        // ticketFileDtoList: resp?.ticketDtoList,
                        createdBy: '',
                        creatorUsername: '',
                    })

                    // setShowMainPage('detailTicket')
                } else {
                    alert('Asset not found')
                }
            })
            .catch(() => {
                // alert('Asset not found')
            })
    }

    useEffect(() => {
        if (scanTag) {
            setTimeout(() => {
                handleClickCheckID()
            }, 1000)
        }
        // getOngoingTicketList()
    }, [showMainPage])

    return (
        <div className="body">
            <div className="">
                <Navbar className="background-sidebar-color d-flex align-items-center justify-content-between py-2 px-lg-8">
                    <Navbar.Brand href="/">
                        <img alt="logo img main" src={NTULKCLogo} />
                    </Navbar.Brand>
                    <h3 className="title-public-site">Asset Tracking System</h3>
                </Navbar>
                <Container className="flex align-items-center justify-content-center">
                    <div className="p-5 public-site-content-border shadow mt-5">
                        <div>
                            <h3 className="poppins-title">Fault Ticket</h3>
                            {showMainPage == 'detailTicket' ? (
                                <>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Back
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Asset Summary
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </>
                            ) : showMainPage == 'createTicket' ? (
                                <>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            Back
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item href="/">
                                            Asset Summary
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Create new ticket
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </>
                            ) : (
                                <p className="poppins-text">
                                    To report a broken asset, simply scan the
                                    asset's tag ID or input the tag number into
                                    the asset tracking system. Review the asset
                                    details to see if any tickets have been
                                    submitted. If not, proceed to create a new
                                    ticket, describing the issue in detail. This
                                    helps ensure swift resolution of the
                                    problem.
                                </p>
                            )}
                        </div>
                        <div className="mb-4">
                            {showMainPage == 'detailTicket' ? (
                                <div>
                                    <div className="mb-5">
                                        <h5 className="mb-3">ASSET SUMMARY</h5>
                                        <div className="ms-1">
                                            <div>
                                                <p className="grey-name mb-1">
                                                    ASSET NAME
                                                </p>
                                                <p className="mt-1">
                                                    {assetContent?.name}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="grey-name mb-1">
                                                    CATEGORY
                                                </p>
                                                <p className="mt-1">
                                                    {assetContent?.assetCategoryName
                                                        ? assetContent?.assetCategoryName
                                                        : '-'}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="grey-name mb-1">
                                                    CURRENT LOCATION
                                                </p>
                                                <p className="mt-1">
                                                    {assetContent?.buildingName
                                                        ? assetContent?.buildingName +
                                                          ' - ' +
                                                          assetContent?.locationName
                                                        : '-'}
                                                    {assetContent?.locationRoom !==
                                                        null &&
                                                    assetContent?.locationRoom !==
                                                        ''
                                                        ? ' - ' +
                                                          assetContent.locationRoom
                                                        : ''}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h5>ONGOING TICKET</h5>
                                        </div>
                                        <div className=" background-grey mt-8">
                                            {ongoingTicketList.length > 0 ? (
                                                <Table borderless responsive>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>
                                                                TICKET ID
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell>
                                                                SUBJECT
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell>
                                                                STATUS
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {assetContent
                                                            ?.ticketDtoList
                                                            .length > 0 ? (
                                                            assetContent?.ticketDtoList?.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Table.Row
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <Table.DataCell>
                                                                                {
                                                                                    data?.id
                                                                                }
                                                                            </Table.DataCell>
                                                                            <Table.DataCell>
                                                                                {
                                                                                    data?.name
                                                                                }
                                                                            </Table.DataCell>
                                                                            <Table.DataCell>
                                                                                {
                                                                                    data?.status
                                                                                }
                                                                            </Table.DataCell>
                                                                        </Table.Row>
                                                                    )
                                                                }
                                                            )
                                                        ) : (
                                                            <Table.Row>
                                                                <Table.DataCell>
                                                                    No Ticket
                                                                    Recorded
                                                                </Table.DataCell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            ) : (
                                                <strong>
                                                    No Ticket Recorded
                                                </strong>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <Button
                                            variant="outline-dark"
                                            className=""
                                            onClick={() => {
                                                setShowMainPage('main')
                                            }}
                                        >
                                            Back
                                        </Button>
                                        <button
                                            variant="primary"
                                            className="px-3 ms-3 btn-size-template btn-ntu"
                                            onClick={() => {
                                                setCreateNewTicket({
                                                    ...createNewTicket,
                                                    ticketFileDtoList: [],
                                                })
                                                setTicketFileDtoListA([])
                                                setSelectedFile({})
                                                setShowMainPage('createTicket')
                                            }}
                                        >
                                            Create New Ticket
                                        </button>
                                    </div>
                                </div>
                            ) : showMainPage == 'createTicket' ? (
                                <div>
                                    <Form onSubmit={() => false}>
                                        <Row>
                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>
                                                    Asset Id
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.assetId
                                                    }
                                                    readOnly={true}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                lg={6}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>
                                                    Asset Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.assetName
                                                    }
                                                    readOnly={true}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                lg={4}
                                                className="mb-3"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>
                                                    User Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.creatorUsername
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            creatorUsername:
                                                                e.target.value,
                                                            userName:
                                                                e.target.value,
                                                            createdBy:
                                                                e.target.value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                lg={4}
                                                className="mb-3"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.userEmail
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            userEmail:
                                                                e.target.value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                lg={4}
                                                className="mb-3"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.userPhone
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            userPhone:
                                                                e.target.value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                xs={12}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>
                                                    CURRENT LOCATION
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.assetLocation
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            assetLocation:
                                                                e.target.value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                xs={12}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>SUBJECT</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.name
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            name: e.target
                                                                .value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                xs={12}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>NOTE</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter"
                                                    value={
                                                        createNewTicket?.note
                                                    }
                                                    onChange={(e) => {
                                                        setCreateNewTicket({
                                                            ...createNewTicket,
                                                            note: e.target
                                                                .value,
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                xs={12}
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>Media</Form.Label>
                                                <div>
                                                    <Form.Label>
                                                        Image (max 5MB)
                                                    </Form.Label>
                                                    <div>
                                                        <FileUpload
                                                            controlId="fileupload1"
                                                            onChangeFile={(e) =>
                                                                onChangeFile(
                                                                    e,
                                                                    'image1'
                                                                )
                                                            }
                                                            selectedFile={
                                                                selectedFileImage1
                                                            }
                                                            multiple={false}
                                                        >
                                                            <i className="bi bi-upload me-2"></i>
                                                            Choose a file
                                                        </FileUpload>
                                                    </div>
                                                    <div>
                                                        <FileUpload
                                                            controlId="fileupload2"
                                                            onChangeFile={(e) =>
                                                                onChangeFile(
                                                                    e,
                                                                    'image2'
                                                                )
                                                            }
                                                            selectedFile={
                                                                selectedFileImage2
                                                            }
                                                            multiple={false}
                                                        >
                                                            <i className="bi bi-upload me-2"></i>
                                                            Choose a file
                                                        </FileUpload>
                                                    </div>
                                                    <div>
                                                        <FileUpload
                                                            controlId="fileupload3"
                                                            onChangeFile={(e) =>
                                                                onChangeFile(
                                                                    e,
                                                                    'image3'
                                                                )
                                                            }
                                                            selectedFile={
                                                                selectedFileImage3
                                                            }
                                                            multiple={false}
                                                        >
                                                            <i className="bi bi-upload me-2"></i>
                                                            Choose a file
                                                        </FileUpload>
                                                    </div>
                                                    <Form.Label>
                                                        Video (max 20MB)
                                                    </Form.Label>
                                                    <div>
                                                        <FileUpload
                                                            controlId="fileupload4"
                                                            onChangeFile={(e) =>
                                                                onChangeFile(
                                                                    e,
                                                                    'video'
                                                                )
                                                            }
                                                            selectedFile={
                                                                selectedFileVideo
                                                            }
                                                            multiple={false}
                                                        >
                                                            <i className="bi bi-upload me-2"></i>
                                                            Choose a file
                                                        </FileUpload>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <div className="mt-4">
                                            <Button
                                                variant="outline-dark"
                                                className=""
                                                onClick={() => {
                                                    setShowMainPage(
                                                        'detailTicket'
                                                    )
                                                }}
                                            >
                                                Back
                                            </Button>
                                            <button
                                                variant="primary"
                                                className="px-3 ms-3 btn-size-template btn-ntu"
                                                type="button"
                                                onClick={() =>
                                                    // setShowMainPage('main')
                                                    handleSubmitTicket()
                                                }
                                            >
                                                Submit Ticket
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            ) : (
                                <>
                                    <div className="d-flex justify-content-center gap-1">
                                        <button
                                            // variant="outline-primary"
                                            // active={selectedActiveScanTag ? true : false}
                                            size="sm"
                                            className={`w-100 btn-transparent-normal-ntu ${
                                                selectedActiveScanTag
                                                    ? 'btn-transparent-active-ntu'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                selectedScanAssetTag(true)
                                            }
                                        >
                                            <>SCAN ASSET TAG</>
                                        </button>
                                        <button
                                            // variant="outline-primary"
                                            // active={!selectedActiveScanTag ? true : false}
                                            size="sm"
                                            className={`w-100 btn-transparent-normal-ntu ${
                                                !selectedActiveScanTag
                                                    ? 'btn-transparent-active-ntu'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                selectedScanAssetTag(false)
                                            }
                                        >
                                            <>SUBMIT ASSET TAG ID</>
                                        </button>
                                    </div>
                                    <div>
                                        {selectedActiveScanTag ? (
                                            <div className="">
                                                <div className="d-flex justify-content-center">
                                                    <div className="mt-8">
                                                        <Scanner
                                                            formats={[
                                                                'qr_code',
                                                                'code_128',
                                                            ]}
                                                            onScan={(result) =>
                                                                handleOnScanQRCode(
                                                                    result
                                                                )
                                                            }
                                                        />
                                                        {/*
                                                        <img
                                                            src={
                                                                LogoScanBarcode
                                                            }
                                                            alt="logo scan barcode"
                                                        />

                                                        */}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="mt-5">
                                                        <button
                                                            className="btn-ntu py-2 px-3"
                                                            size="sm"
                                                        >
                                                            Scan Asset Barcode
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="">
                                                <div className="d-flex flex-column align-items-center">
                                                    <div className="mt-8 w-100">
                                                        <Form
                                                            className="frm-asset-tag-id"
                                                            onSubmit={(e) => {
                                                                e.preventDefault()
                                                                handleClickCheckID()
                                                            }}
                                                        >
                                                            <Form.Group
                                                                className="mb-3 w-100"
                                                                controlId="exampleForm.ControlInput1"
                                                            >
                                                                <Form.Label>
                                                                    Asset Tag ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    size="sm"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAssetTagId(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    value={
                                                                        assetTagId
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                    <div className="w-100 text-center">
                                                        <button
                                                            className="btn-ntu btn-full-mobile py-2 px-3"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleClickCheckID()
                                                            }
                                                        >
                                                            Check ID
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
            <ToastContainer autoClose={10000} />
        </div>
    )
}

export default PublicPage
