import {
    Badge,
    Dropdown,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import CustomPagination from '@/components/CustomPagination'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ModalDelete from '@/components/ModalDelete'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'

export default function AssetAttachment() {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [data, setData] = useState(null)
    const [dataDelete, setDataDelete] = useState(null)
    const [asset, setAsset] = useState(null)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = (page = currentPage) => {
        if (typeof id === 'undefined') {
            return
        }
        setLoading(true)
        axios
            .get(
                `/api/v1/asset/asset_attachment/?assetId=${id}&page=${page}&size=${itemsPerPage}`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    const deleteAttachment = (id, name) => {
        axios
            .delete(`/api/v1/asset/delete_attachment/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setDataDelete({})
                    toast.success(`Success Delete ${name}`)
                    getData()
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAsset({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                        ...res.data,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    useEffect(function () {
        if (asset === null) {
            getAsset()
        }
    }, [])

    useEffect(
        function () {
            getData()
        },
        [currentPage, itemsPerPage]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu
                        reloadList={getData}
                        id={id}
                        item={asset}
                        activeMenu="asset_attachment"
                    />
                </>
            )}

            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>FILE NAME</TableHeaderCell>
                                <TableHeaderCell>CATEGORY</TableHeaderCell>
                                <TableHeaderCell>DESCRIPTION</TableHeaderCell>
                                <TableHeaderCell>ATTACHMENT</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {currentPage - 1 + index + 1}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.fileName || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.category}
                                            </TableDataCell>

                                            <TableDataCell>
                                                {item.description || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <div className="d-flex gap-1">
                                                    <i className="bi bi-file-pdf"></i>
                                                    {item.fileName}
                                                </div>
                                            </TableDataCell>
                                            <TableDataCell>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            href={item.fileUrl}
                                                        >
                                                            Download Attachment
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDataDelete({
                                                                    title: item.fileName,
                                                                    id: item.id,
                                                                    show: true,
                                                                })
                                                            }}
                                                            className={`text-danger`}
                                                        >
                                                            Delete Attachment
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete attachment"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteAttachment(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
