import Cookies from 'js-cookie'

export function checkAccess(val) {
    const customAccessAdminOnly = [
        'Approve loan',
        'Approve transfer',
        'Reject transfer',
    ]
    const roleType = Cookies.get('roleType')
    if (val === 'USER') {
        if (roleType === 'USER') {
            return true
        }
        return false
    }
    if (roleType === 'ADMIN' && customAccessAdminOnly.includes(val)) {
        return true
    }

    return JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
        (access) => access.name === val
    )
}
