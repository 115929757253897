import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import LogList from '@/pages/Report/logList'
import { toast, ToastContainer } from 'react-toastify'
import * as XLSX from 'xlsx'
import moment from 'moment'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function Report() {
    const navigate = useNavigate()

    const [userList, setUserList] = useState([])

    const [userId, setUserId] = useState(null)

    const [fromDate, setFromDate] = useState('')

    const [toDate, setToDate] = useState('')

    const [logs, setLogs] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Audit Log'
        )
    ) {
        navigate('/dashboard')
    }

    const getUserList = () => {
        axios
            .get(`/api/v1/user/list/`)
            .then((res) => {
                const optionList = res.data.map((data) => ({
                    label: data.name,
                    value: data.id,
                }))

                setUserList(optionList)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const fetchData = async () => {
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/system_log/?size=${itemsPerPage}&page=${currentPage}&fromDate=${fromDate}&toDate=${toDate}&${
                        userId !== null && userId !== ''
                            ? `&userId=${userId}`
                            : ''
                    }`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setLogs(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
            toast.error(error.response.data.message)
        }
    }

    const handleGenerate = (e) => {
        e.preventDefault()

        fetchData()
    }

    const handleExportExcel = () => {
        axios
            .get(
                `/api/v1/system_log/list?fromDate=${fromDate}&toDate=${toDate}${
                    userId !== null && userId !== '' ? `&userId=${userId}` : ''
                }`
            )
            .then((res) => {
                const data = res.data

                const wb = XLSX.utils.book_new()

                const ws = XLSX.utils.json_to_sheet(data)

                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

                const fileName = `Audit-log-${moment().format(
                    'YYYY-MM-DD'
                )}.xlsx`

                toast.success('Success Export to Excel')

                XLSX.writeFile(wb, fileName)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getUserList()
    }, [])

    useEffect(() => {
        if (userId) fetchData()
    }, [itemsPerPage, currentPage])

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="audit_log" />

            <sgds-content-body>
                {logs.length > 0 ? (
                    <LogList
                        handleExportExcel={handleExportExcel}
                        logs={logs}
                        setLogs={setLogs}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPage={itemsPerPage}
                        totalData={totalData}
                    />
                ) : (
                    <div className="bg-white shadow-sm rounded p-4 btn-purple">
                        <h2>Audit Log Report</h2>
                        <p className="fw-bold">
                            Generate a report with all the tasks in the selected
                            period
                        </p>
                        <Form onSubmit={handleGenerate}>
                            <Row className="px-3">
                                <Col md={12}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicText"
                                    >
                                        <Form.Label>User</Form.Label>
                                        <Select
                                            options={userList}
                                            onChange={(e) => setUserId(e.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicText"
                                    >
                                        <Form.Label>Start Date</Form.Label>
                                        <CustomDatePicker
                                            size="md"
                                            onChange={(e) => setFromDate(e)}
                                            value={fromDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicText"
                                    >
                                        <Form.Label>End Date</Form.Label>
                                        <CustomDatePicker
                                            size="md"
                                            onChange={(e) => setToDate(e)}
                                            value={toDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Button
                                        type={'submit'}
                                        size="sm"
                                        className="px-3"
                                    >
                                        Generate
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
