import React, { useEffect, useState } from 'react'
import { SideNav } from '@govtechsg/sgds-react/SideNav'
import { Dropdown } from '@govtechsg/sgds-react'
import NTULKCLogo from '../logo.png'
import avatar from '@/images/avatar.png'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { checkAccess } from '@/helpers/checkAccess'

function Sidebar() {
    const [active, setActive] = useState('dashboard')
    const [showSidebar, setShowSidebar] = useState(false)
    const location = useLocation()
    const pathSegments = location.pathname.split('/')
    const urlString = pathSegments[1]

    const logout = () => {
        Cookies.remove('authKey')
        Cookies.remove('menuList')
        setTimeout(function () {
            window.location.href = '/login'
        }, 1000)
    }

    const name = Cookies.get('name')

    useEffect(() => {
        setActive(urlString)
    }, [urlString])

    const menuList = JSON.parse(Cookies.get('menuList'))
    const handleShowSidebar = () => {
        setShowSidebar(true)
    }
    return (
        <>
            <div className="hamburger" onClick={handleShowSidebar}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                </svg>
            </div>
            <sgds-aside-area
                style={{
                    display: showSidebar ? 'block' : '',
                    width: showSidebar ? '300px' : '',
                    position: showSidebar ? 'fixed' : '',
                    top: showSidebar ? 0 : '',
                    left: showSidebar ? 0 : '',
                    bottom: showSidebar ? 0 : '',
                    zIndex: 1050,
                }}
                className={`d-block background-sidebar-color ntu-sidebar`}
            >
                <div className="mt-2 ms-3">
                    <img src={NTULKCLogo} alt="logo ntu lkc" />
                </div>
                <SideNav
                    activeKey={active}
                    // activeNavLinkKey={sideActiveLink}
                    className="mt-8"
                >
                    <SideNav.Item eventKey="dashboard">
                        <Link
                            to={'/dashboard'}
                            className={`${
                                active !== 'dashboard' && active !== ''
                                    ? 'collapsed '
                                    : ''
                            }btn sgds`}
                            onClick={() => {
                                setActive('dashboard')
                            }}
                        >
                            <i className="bi bi-grid-3x3-gap-fill"></i>
                            Dashboard
                        </Link>
                    </SideNav.Item>

                    {menuList.includes('Department') ? (
                        <SideNav.Item eventKey="department">
                            <Link
                                to={'/department'}
                                className={`${
                                    active !== 'department' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('department')
                                }}
                            >
                                <i className="bi bi-building"></i>
                                Department
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Asset') ? (
                        <SideNav.Item eventKey="asset">
                            <Link
                                className={`${
                                    active !== 'asset' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('asset')
                                }}
                                to={'/asset'}
                            >
                                <i className="bi bi-hdd"></i>
                                Asset
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Maintenance Schedules') ? (
                        <SideNav.Item eventKey={'maintenance'}>
                            <Link
                                className={`${
                                    active !== 'maintenance' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('maintenance')
                                }}
                                to={`/maintenance`}
                            >
                                <i className="bi bi-calendar"></i>
                                Maintenance Schedule
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Asset Loan') ? (
                        <SideNav.Item eventKey={'loan'}>
                            <Link
                                className={`${
                                    active !== 'loan' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('loan')
                                }}
                                to={`/loan`}
                            >
                                <i className="bi bi-folder"></i>
                                Loan
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Transfer Asset') ? (
                        <SideNav.Item eventKey={'transfer'}>
                            <Link
                                className={`${
                                    active !== 'transfer' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('transfer')
                                }}
                                to={`/transfer`}
                            >
                                <i className="bi bi-box-seam"></i>
                                Transfer Asset
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Tickets') ? (
                        <SideNav.Item eventKey={'ticket'}>
                            <Link
                                className={`${
                                    active !== 'ticket' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('ticket')
                                }}
                                to={`/ticket`}
                            >
                                <i className="bi bi-exclamation-triangle"></i>
                                Tickets
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Location,Building') ? (
                        <SideNav.Item eventKey="location">
                            <Link
                                className={`${
                                    active !== 'location' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('location')
                                }}
                                to={'/location/building'}
                            >
                                <i className="bi bi-geo-fill"></i>
                                Location
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('Report') ? (
                        <SideNav.Item eventKey="report">
                            <Link
                                className={`${
                                    active !== 'report' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('report')
                                }}
                                to={`/report`}
                            >
                                <i className="bi bi-speedometer2"></i>
                                Reports
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {menuList.includes('People,Roles') ||
                    menuList.includes('People,Users') ? (
                        <SideNav.Item eventKey="people">
                            <Link
                                className={`${
                                    active !== 'people' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('people')
                                }}
                                to={'/people/users'}
                            >
                                <i className="bi bi-person"></i>
                                People
                            </Link>
                        </SideNav.Item>
                    ) : null}

                    {!checkAccess('USER') &&
                    (menuList.includes('Attributes,Asset Category') ||
                        menuList.includes('Attributes,Asset Sub Category') ||
                        menuList.includes('Attributes,Vendor') ||
                        menuList.includes('Asset User')) ? (
                        <SideNav.Item eventKey="attribute">
                            <Link
                                className={`${
                                    active !== 'attribute' ? 'collapsed ' : ''
                                }btn sgds`}
                                onClick={() => {
                                    setActive('attribute')
                                }}
                                to={'/attribute/category'}
                            >
                                <i className="bi bi-pin"></i>
                                Attributes
                            </Link>
                        </SideNav.Item>
                    ) : null}
                </SideNav>

                <div className="account-sidebar">
                    <Dropdown drop="end">
                        <Dropdown.Toggle className={`btn-clear`}>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={avatar}
                                    width="50"
                                    height="50"
                                    style={{ borderRadius: '50%' }}
                                    alt="avatar"
                                />
                                <div className="">
                                    <p className="fw-bold m-0">{name}</p>
                                    <div className="d-flex align-items-center gap-3">
                                        <div
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: 'green',
                                                borderRadius: '50%',
                                            }}
                                        ></div>
                                        <p className="m-0">Online</p>
                                    </div>
                                </div>
                                <i className="bi bi-chevron-down"></i>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={logout}>
                                Sign Out
                            </Dropdown.Item>
                            <Dropdown.Item href="/profile">
                                Edit Profile
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </sgds-aside-area>
            <div
                onClick={() => setShowSidebar(false)}
                className={`backdrop ${showSidebar ? 'd-block' : 'd-none'}`}
            ></div>
        </>
    )
}

export default Sidebar
