import TemplateDashboard from '../../components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    BreadcrumbItem,
    Button,
} from 'react-bootstrap'
import { Breadcrumb, FormLabel } from '@govtechsg/sgds-react'
import { Form } from '@govtechsg/sgds-react/Form'
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import { Col } from '@govtechsg/sgds-react/Col'
import { Row } from '@govtechsg/sgds-react/Row'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import moment from 'moment'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function MassCheckout(props) {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    const [options, setOptions] = useState([])
    const [selectedAssets, setSelectedAssets] = useState([])

    const [checkOutDate, setCheckOutDate] = useState('')

    const [checkOutRemark, setCheckOutRemark] = useState('')

    const [assetList, setAssetList] = useState([])

    const [serviceNo, setServiceNo] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const handleSelectChange = (selectedOptions) => {
        const isDupe = selectedAssets.some(
            (asset) => asset.value === selectedOptions.value
        )

        if (!isDupe) {
            setSelectedAssets((prevSelectedAssets) => [
                ...prevSelectedAssets,
                selectedOptions,
            ])
        }
    }

    const removeSelected = (index) => {
        setSelectedAssets((prevSelectedAssets) => {
            const newArr = [...prevSelectedAssets]

            newArr.splice(index, 1)

            return newArr
        })
    }

    // const loadOptions = (inputValue) => {
    //     if (!inputValue) {
    //         setOptions([]);
    //         return;
    //     }
    //
    //     const filtered = assetList.filter(option => {
    //         const label = option.label ? option.label.toLowerCase() : '';
    //         const value = option.value ? option.value.toLowerCase() : '';
    //         return label.includes(inputValue.toLowerCase()) || value.includes(inputValue.toLowerCase());
    //     });
    //     setOptions(filtered);
    // };

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = selectedAssets.map((data) => ({
            id: data.value,
            checkOutDate: moment(checkOutDate).format('YYYY-MM-DD'),
            checkOutRemark,
            serviceNo,
        }))

        axios
            .patch(`/api/v1/maintenance_schedule/check_out/`, data)
            .then((response) => {
                toast.success('Success Check Out')

                setTimeout(() => {
                    navigate('/maintenance')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getScheduleList = () => {
        axios
            .get(
                `/api/v1/maintenance_schedule/?assetName=${searchAsset}&status=Checked In`
            )
            .then((res) => {
                const formattedData = res.data.content.map((data) => ({
                    label: data.assetName,
                    value: data.id,
                    isSelected: false,
                    assetName: data.assetName,
                    checkInDate: data.checkInDate,
                    assetSerialNumber: data.assetSerialNumber,
                    repairLocation: data.repairLocation,
                    assetTag: data.assetTag,
                    checkInRemark: data.checkInRemark,
                    note: data.note,
                }))
                setAssetList(formattedData)
            })
            .catch((err) => {
                console.error(err.response.data.message)
                err.handleGlobally && err.handleGlobally()
            })
    }

    useEffect(() => {
        getScheduleList()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Mass Check Out</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to={'/maintenance'}>Maintenance schedule</Link>
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    Mass check out
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <div className={`row shadow-sm bg-white p-7`}>
                        <div className={'col-12'}>
                            <Form.Group className="mb-3" controlId="Form.asset">
                                <Form.Label className={'form-label'}>
                                    Select asset
                                </Form.Label>

                                {/*<Select*/}
                                {/*    onChange={handleSelectChange}*/}
                                {/*    value={selectedAssets}*/}
                                {/*    options={assetList}*/}
                                {/*    isMulti*/}
                                {/*/>*/}

                                <Select
                                    required
                                    options={assetList}
                                    onInputChange={(val) => setSearchAsset(val)}
                                    onChange={handleSelectChange}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-12">
                            <p>
                                Showing ({selectedAssets.length}) selected asset
                                to check out
                            </p>

                            <div>
                                {selectedAssets.map((asset, index) => (
                                    <Row key={index}>
                                        <Col xs={11}>
                                            <Accordion className={'mb-2'}>
                                                <AccordionItem eventKey={index}>
                                                    <AccordionHeader>
                                                        {asset.label}
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Asset Name
                                                                </FormLabel>
                                                                <p>
                                                                    {
                                                                        asset.label
                                                                    }
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Check In
                                                                    Date
                                                                </FormLabel>
                                                                <p>
                                                                    {asset.checkInDate ??
                                                                        '-'}
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Asset Serial
                                                                    Number
                                                                </FormLabel>
                                                                <p>
                                                                    {
                                                                        asset.assetSerialNumber
                                                                    }
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Repair
                                                                    Location
                                                                </FormLabel>
                                                                <p>
                                                                    {
                                                                        asset.repairLocation
                                                                    }
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Asset Tag
                                                                </FormLabel>
                                                                <p>
                                                                    {
                                                                        asset.assetTag
                                                                    }
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Check in
                                                                    Remark
                                                                </FormLabel>
                                                                <p>
                                                                    {asset.checkInRemark ??
                                                                        '-'}
                                                                </p>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <FormLabel>
                                                                    Note
                                                                </FormLabel>
                                                                <p>
                                                                    {asset.note}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={() =>
                                                    removeSelected(index)
                                                }
                                                variant={'danger'}
                                            >
                                                <i className="bi bi-x form-control-icon"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={'row gap-4 mt-4'}>
                        <div className="col-3">
                            <Form.Group controlId="Form.checkindate">
                                <Form.Label className={'form-label'}>
                                    Check out date
                                </Form.Label>
                                <CustomDatePicker
                                    size="md"
                                    required={true}
                                    maxDate={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => setCheckOutDate(e)}
                                    value={checkOutDate}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-3">
                            <Form.Group controlId="Form.reportnumber">
                                <Form.Label className={'form-label'}>
                                    Service/Inspection Report Number
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        setServiceNo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-12">
                            <Form.Group controlId="Form.message">
                                <Form.Label className={'form-label'}>
                                    Mass Check out Remarks
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        setCheckOutRemark(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>

                        <div className={'d-flex mt-3'} style={{ gap: '20px' }}>
                            <Link
                                className="btn btn-outline-dark sgds"
                                to={'/maintenance'}
                            >
                                Cancel
                            </Link>
                            <Button variant="primary" type={'submit'}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
