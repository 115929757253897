import React, { useEffect, useState } from 'react'
import { Button } from '@govtechsg/sgds-react/Button'
import { Col, Row } from '@govtechsg/sgds-react'
import { Form } from '@govtechsg/sgds-react/Form'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

function RoleEditForm() {
    const [parentAccessList, setParentAccessList] = useState([])
    const [selectedModule, setSelectedModule] = useState('')
    const [accessListByParent, setAccessListByParent] = useState([])
    const [name, setName] = useState('')
    const [type, setType] = useState('ADMIN')

    const [checkedAccess, setCheckedAccess] = useState([])
    const navigate = useNavigate()

    const { id } = useParams()

    const customStyles = {
        option: (provided, state) => {
            const isCheckedChild = checkedAccess.some(
                (item) => item.parentAccessName === state.data.value
            )
            return {
                ...provided,
                color: isCheckedChild ? 'green' : 'black',
            }
        },
        singleValue: (provided, state) => {
            const isCheckedChild = checkedAccess.some(
                (item) => item.parentAccessName === state.data.value
            )
            return {
                ...provided,
                color: isCheckedChild ? 'green' : 'black',
            }
        },
    }

    const getParentAccess = () => {
        axios
            .get(`/api/v1/access/parent_list`)
            .then((res) => {
                const transformedArray = res.data.map((item) => ({
                    label: item,
                    value: item,
                }))

                setParentAccessList(transformedArray)
            })
            .catch((err) => console.error(err.response.data.message))
    }

    const getAccessByParent = () => {
        axios
            .get(`/api/v1/access/?moduleName=${selectedModule}`)
            .then((res) => {
                setAccessListByParent(res.data)
            })
            .catch((err) => console.error(err.response.data.message))
    }

    const getAllAccess = () => {
        axios
            .get(`/api/v1/access/`)
            .then((res) => {
                setCheckedAccess(res.data)
            })
            .catch((err) => console.error(err.response.data.message))
    }

    const getCurrentRole = () => {
        axios
            .get(`/api/v1/role/${id}`)
            .then((res) => {
                setType(res.data.type)
                setName(res.data.name)
                setCheckedAccess(res.data.currentAccessDtoList)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleCheckAccess = (access, module, checked) => {
        if (checked)
            setCheckedAccess(
                checkedAccess.filter(
                    (item) =>
                        !(
                            item.name === access &&
                            item.parentAccessName === module
                        )
                )
            )
        else
            setCheckedAccess([
                ...checkedAccess,
                {
                    name: access,
                    parentAccessName: module,
                },
            ])
    }

    const handleCheckAll = () => {
        const uncheckedItems = accessListByParent.filter(
            (item) =>
                !checkedAccess.some(
                    (checkedItem) =>
                        checkedItem.name === item.name &&
                        checkedItem.parentAccessName === item.parentAccessName
                )
        )
        setCheckedAccess([...checkedAccess, ...uncheckedItems])
    }

    const handleUncheckAll = () => {
        const remainingItems = checkedAccess.filter(
            (checkedItem) =>
                !accessListByParent.some(
                    (item) =>
                        item.name === checkedItem.name &&
                        item.parentAccessName === checkedItem.parentAccessName
                )
        )
        setCheckedAccess(remainingItems)
    }

    const handleAllowAll = (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            getAllAccess()
        } else {
            setCheckedAccess([])
        }
    }

    const handleSubmitRole = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/role/`, {
                id,
                name,
                type,
                accessDtoList: checkedAccess,
            })
            .then((response) => {
                //toast
                toast.success('Success Edit Role')

                setTimeout(() => {
                    navigate('/people/roles')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    function sortActionsByPrefix(actions) {
        const prefixes = ['Create', 'View', 'Update', 'Delete']
        return actions.sort((a, b) => {
            const prefixA = prefixes.findIndex((prefix) => a.startsWith(prefix))
            const prefixB = prefixes.findIndex((prefix) => b.startsWith(prefix))
            return prefixA - prefixB
        })
    }

    useEffect(() => {
        getParentAccess()
        getCurrentRole()
    }, [])

    useEffect(() => {
        if (selectedModule) getAccessByParent()
    }, [selectedModule])

    return (
        <Form onSubmit={handleSubmitRole}>
            <div className="bg-white py-2 px-4">
                <div className="btn-purple">
                    <Row className="">
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Role Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                    value={name}
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Role Type</Form.Label>
                                <Form.Select
                                    aria-label="Category"
                                    size="sm"
                                    value={type}
                                    required={true}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value={'ADMIN'}>ADMIN</option>
                                    <option value={'USER'}>USER</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={12} className="mt-4 mb-5">
                            <h3>Set up role access</h3>
                        </Col>
                        <Col md={12}>
                            <h5>User Access</h5>
                            <div>
                                <Form.Check
                                    id="allow-all"
                                    label={'Allow access to all module'}
                                    onChange={handleAllowAll}
                                ></Form.Check>
                            </div>
                        </Col>
                        <Col md={12} className="mt-4 ">
                            <div>
                                <Row className="" style={{ height: '30vh' }}>
                                    <Col md={6}>
                                        <h5>Module</h5>
                                        <Select
                                            options={parentAccessList}
                                            isSearchable={true}
                                            styles={customStyles}
                                            onChange={(e) =>
                                                setSelectedModule(e.value)
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className="d-flex">
                                        <Row>
                                            <Col md={12}>
                                                <h5>Access to</h5>
                                                <div>
                                                    {sortActionsByPrefix(
                                                        accessListByParent.map(
                                                            (access) =>
                                                                access.name
                                                        )
                                                    ).map((action) => {
                                                        const access =
                                                            accessListByParent.find(
                                                                (access) =>
                                                                    access.name.startsWith(
                                                                        action
                                                                    )
                                                            )
                                                        if (access) {
                                                            return (
                                                                <Form.Check
                                                                    label={
                                                                        access.name
                                                                    }
                                                                    id={
                                                                        access.name
                                                                    }
                                                                    key={
                                                                        access.name
                                                                    }
                                                                    checked={checkedAccess.some(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.name ===
                                                                            access.name
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckAccess(
                                                                            access.name,
                                                                            access.parentAccessName,
                                                                            checkedAccess.some(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item.name ===
                                                                                    access.name
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        return null
                                                    })}
                                                </div>
                                            </Col>
                                            <Col
                                                md={12}
                                                className={`d-flex gap-3`}
                                                style={{
                                                    height: 'fit-content',
                                                }}
                                            >
                                                <Button
                                                    onClick={handleCheckAll}
                                                >
                                                    Check All
                                                </Button>
                                                <Button
                                                    onClick={handleUncheckAll}
                                                >
                                                    Uncheck All
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className={`mt-3`}>
                <Col>
                    <div className="d-flex gap-3 btn-purple">
                        <Button variant="outline-dark" href="/people/roles">
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Edit User Role
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default RoleEditForm
