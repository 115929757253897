import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import CustomPagination from '@/components/CustomPagination'
import React from 'react'
import moment from 'moment/moment'
import CustomDate from '@/components/CustomDate'

export default function LogList({
    logs,
    setLogs,
    setCurrentPage,
    setItemsPerPage,
    currentPage,
    itemsPerPage,
    totalData,
    handleExportExcel,
}) {
    return (
        <>
            <div className="bg-white shadow-sm rounded p-4 btn-purple">
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => setLogs([])}>
                        AUDIT LOG REPORT
                    </BreadcrumbItem>
                    <BreadcrumbItem active>ALL USER</BreadcrumbItem>
                </Breadcrumb>
                <Table borderless={true} hover={true} size="sm" responsive="lg">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>NO</TableHeaderCell>
                            <TableHeaderCell>IP ADDRESS</TableHeaderCell>
                            <TableHeaderCell width={'50%'}>
                                DESCRIPTION
                            </TableHeaderCell>
                            <TableHeaderCell>CREATED BY</TableHeaderCell>
                            <TableHeaderCell>CREATION DATE</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {logs.map((log, index) => (
                            <TableRow key={index}>
                                <TableDataCell>
                                    {itemsPerPage * currentPage -
                                        itemsPerPage +
                                        index +
                                        1}
                                </TableDataCell>
                                <TableDataCell>
                                    {log.ipAddress ?? '-'}
                                </TableDataCell>
                                <TableDataCell>{log.description}</TableDataCell>
                                <TableDataCell>
                                    {log.creatorUsername}
                                </TableDataCell>
                                <TableDataCell>
                                    <CustomDate date={log.createdAt} />
                                </TableDataCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <CustomPagination
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                itemsPerPageDefault={10}
                totalData={totalData}
            />
            <Row className="btn-purple">
                <Col>
                    <Button
                        size="sm"
                        className="px-3"
                        onClick={handleExportExcel}
                        type={'button'}
                    >
                        Export to excel
                    </Button>
                </Col>
            </Row>
        </>
    )
}
