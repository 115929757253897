import {
    Dropdown,
    Form,
    FormControl,
    FormControlGroup,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AttributeMenu from '@/components/Attribute/AttributeMenu'
import {Link, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import CustomPagination from '@/components/CustomPagination'
import axios from 'axios'
import {toast} from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'

export default function SubCategory() {
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [categoryData, setCategoryData] = useState(null)
    const [categoryDataDelete, setCategoryDataDelete] = useState(null)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset Sub Category'
        )
    ) {
        navigate('/dashboard')
    }

    const getCategory = (page = currentPage) => {
        setLoading(true)
        axios
            .get(
                `/api/v1/attributes/asset_sub_category?page=${page}&size=${itemsPerPage}&name=${
                    search.length === 1 ? '' : search
                }`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setCategoryData(response.data)
                    return
                }
            })
            .catch((error) => {
                setLoading(false)
                error.handleGlobally && error.handleGlobally()
            })
    }

    const deleteCategory = (id, name) => {
        axios
            .delete(`/api/v1/attributes/asset_sub_category/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setCategoryDataDelete({})
                    toast.success(`Success Delete ${name}`)
                    getCategory()
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }

    useEffect(
        function () {
            if (search === '') {
                getCategory()
                return
            }
            clearTimeout(searchTimeout)
            setSearchTimeout(
                setTimeout(function () {
                    getCategory(1)
                }, 1000)
            )
        },
        [currentPage, itemsPerPage, search]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <AttributeMenu activeMenu="sub_category"/>
            </sgds-content-header-top>
            <h2 className={`mb-0`}>Asset Sub-Category</h2>
            <div>
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Search sub category</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </div>
                    {
                        JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                            (access) => access.name === 'Create Asset Sub Category'
                        ) ? (
                            <div className="col-md-3">
                                <div className="d-flex align-items-center justify-content-end h-100">
                                    <div className="d-flex mt-3">
                                        <div className="p-1">
                                            <Link
                                                to={'/attribute/sub-category/add'}
                                                className="btn btn-outline-dark sgds"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="bi bi-plus-circle"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>
                                    ASSET CATEGORY
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    ASSET SUB CATEGORY
                                </TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={3}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && categoryData?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={3}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                categoryData?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {itemsPerPage *
                                                    (currentPage - 1) +
                                                    index +
                                                    1}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.categoryName}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.name}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                                                                (access) => access.name === 'Update Asset Sub Category'
                                                            ) ? (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/attribute/sub-category/edit/${item.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    Edit Category
                                                                </Dropdown.Item>
                                                            ) : null
                                                        }

                                                        {
                                                            JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                                                                (access) => access.name === 'Delete Asset Sub Category'
                                                            ) ? (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setCategoryDataDelete(
                                                                            {
                                                                                title: item.name,
                                                                                id: item.id,
                                                                                show: true,
                                                                            }
                                                                        )
                                                                    }}
                                                                    className={`text-danger`}
                                                                >
                                                                    Delete
                                                                </Dropdown.Item>
                                                            ) : null
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={categoryData?.totalElements}
                    />
                </Row>
                <ModalDelete
                    show={categoryDataDelete?.show}
                    title="Delete Category"
                    message={`Are you sure wanto to delete ${categoryDataDelete?.title}?`}
                    handleClose={() => {
                        setCategoryDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteCategory(
                            categoryDataDelete.id,
                            categoryDataDelete.title
                        )
                    }}
                />
            </sgds-content-body>
        </TemplateDashboard>
    )
}
