import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    FormSelect,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

export default function AssetLocationList() {
    return (
        <TemplateDashboard>
            <sgds-template-grid>
                <sgds-content-area>
                    <ReportMenu activeMenu="asset_location" />

                    <sgds-content-body>
                        <div className="bg-white shadow-sm rounded p-4 btn-purple">
                            <Breadcrumb>
                                <BreadcrumbItem href="/report/location">
                                    ASSET LOCATION REPORT
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    BUILDING BLOCK 1
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <Table
                                borderless={true}
                                hover={true}
                                size="sm"
                                responsive="lg"
                            >
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>NO</TableHeaderCell>
                                        <TableHeaderCell>ASSET</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableDataCell>1</TableDataCell>
                                        <TableDataCell>
                                            Qiagen TissueRuptor (230V, 50-60Hz,
                                            EU/CH) (hand-held)
                                        </TableDataCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <Row className="btn-purple">
                            <Col>
                                <Button size="sm" className="px-3">
                                    Print
                                </Button>
                            </Col>
                        </Row>
                    </sgds-content-body>
                </sgds-content-area>
            </sgds-template-grid>
        </TemplateDashboard>
    )
}
