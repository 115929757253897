import React from 'react'
import { Routes, Route, Outlet, Link } from "react-router-dom";

function About() {
  return (
    <div>
      <p>This is About Page</p>
    </div>
  )
}

export default About