import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import About from './components/About'
import Layout from './components/Layout'
import Cookies from 'js-cookie'
import React from 'react'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Users from '@/pages/People/Users'
import UserAdd from '@/pages/People/Users/Add'
import UserEdit from '@/pages/People/Users/Edit'
import Admin from '@/pages/People/Admin'
import AdminAdd from '@/pages/People/Admin/Add'
import AdminEdit from '@/pages/People/Admin/Edit'
import UserRole from '@/pages/People/Roles'
import UserRoleAdd from '@/pages/People/Roles/Add'
import UserRoleEdit from '@/pages/People/Roles/Edit'
import Maintenance from '@/pages/Maintenance/Maintenance'
import AddMaintenance from '@/pages/Maintenance/Add'
import EditMaintenance from '@/pages/Maintenance/Edit'
import MassCheckin from '@/pages/Maintenance/MassCheckin'
import MassCheckout from '@/pages/Maintenance/MassCheckout'
import Asset from '@/pages/Asset'
import AssetDetail from '@/pages/Asset/detail'
import AddAsset from '@/pages/Asset/add'
import EditAsset from '@/pages/Asset/edit'
import AssetLog from '@/pages/Asset/log'
import AssetTicket from '@/pages/Asset/ticket'
import AssetMaintenance from '@/pages/Asset/maintenance'
import AssetLoan from '@/pages/Asset/loan'
import AssetAttachment from '@/pages/Asset/attachments'
import Loan from '@/pages/Loan/Loan'
import AddLoan from '@/pages/Loan/Add'
import EditLoan from '@/pages/Loan/Edit'
import Transfer from '@/pages/Transfer/Transfer'
import AddTransfer from '@/pages/Transfer/Add'
import Ticket from '@/pages/Ticket/Ticket'
import TicketDetail from '@/pages/Ticket/Detail'
import Department from '@/pages/Department'
import AddDepartment from '@/pages/Department/add'
import EditDepartment from '@/pages/Department/edit'
import TicketMaintenance from '@/pages/Ticket/Maintenance'
import Dashboard from '@/pages/Dashboard'
import AssetMaintenanceLog from '@/pages/Asset/maintenanceLog'
import Report from '@/pages/Report'
import ReportAsset from '@/pages/Report/asset'
import AssetList from '@/pages/Report/assetList'
import ReportMaintenance from '@/pages/Report/maintenance'
import MaintenaceList from '@/pages/Report/maintenanceList'
import ReportAssetHistory from '@/pages/Report/assetHistory'
import ReportAssetLocation from '@/pages/Report/assetLocation'
import AssetLocationList from '@/pages/Report/assetLocationList'
import ReportFault from '@/pages/Report/fault'
import FaultList from '@/pages/Report/faultList'
import AddCategory from '@/pages/Attribute/Category/add'
import EditCategory from '@/pages/Attribute/Category/edit'
import AddSubCategory from '@/pages/Attribute/SubCategory/add'
import EditSubCategory from '@/pages/Attribute/SubCategory/edit'
import SubCategory from '@/pages/Attribute/SubCategory'
import Categories from '@/pages/Attribute/Category'
import Vendors from '@/pages/Attribute/Vendors'
import AddVendor from '@/pages/Attribute/Vendors/add'
import EditVendor from '@/pages/Attribute/Vendors/edit'
import AssetUser from '@/pages/Attribute/AssetUser'
import AddAssetUser from '@/pages/Attribute/AssetUser/add'
import EditAssetUser from '@/pages/Attribute/AssetUser/edit'
import EditProfile from '@/pages/Profile'
import PublicPage from '@/pages/PublicPage/PublicPage'
import ImportAsset from '@/pages/Asset/import'
import Building from '@/pages/Location/Building'
import AddBuilding from '@/pages/Location/Building/add'
import EditBuilding from '@/pages/Location/Building/edit'
import AddPoint from '@/pages/Location/Unit/add'
import EditPoint from '@/pages/Location/Unit/edit'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import Unit from 'src/pages/Location/Unit'

const errorComposer = (error) => {
    return () => {
        const statusCode = error.response ? error.response.status : null
        if (statusCode === 500) {
            if (error.response.data.message.includes('Token has Expired')) {
                toast.error(
                    'Opps,... Your session has Expired, you will redirect to login!'
                )
                Cookies.remove('authKey')
                setTimeout(function () {
                    window.location.href = '/login'
                }, 2000)
                return
            }
        }
    }
}

axios.interceptors.response.use(undefined, function (error) {
    error.handleGlobally = errorComposer(error)

    return Promise.reject(error)
})

const isAuthenticated = () => {
    const authKey = Cookies.get('authKey')
    if (authKey) {
        axios.defaults.headers = {
            Authorization: `Bearer ${authKey}`,
        }
    }
    return !!authKey // Return true if authKey exists, false otherwise
}

function App() {
    return (
        <div className="App ">
            <ToastContainer autoClose={10000} />
            {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
            <Routes>
                {isAuthenticated() ? (
                    <>
                        <Route path="/login" element={<Navigate to="/dashboard" />} />
                        <Route path="/logout" element={<Logout />} exact />
                        <Route
                            path="/profile"
                            element={<EditProfile />}
                            exact
                        />
                        <Route path="/" element={<Layout />}>
                            <Route index element={<PublicPage />} />
                            <Route path="about" element={<About />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="department">
                                <Route index element={<Department />} />
                                <Route path="add" element={<AddDepartment />} />
                                <Route
                                    path="edit/:id"
                                    element={<EditDepartment />}
                                />
                            </Route>
                            <Route path="location">
                                <Route path="building">
                                    <Route index element={<Building />}></Route>
                                    <Route
                                        path="add"
                                        element={<AddBuilding />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<EditBuilding />}
                                    />
                                </Route>
                                <Route path="unit">
                                    <Route index element={<Unit />}></Route>
                                    <Route path="add" element={<AddPoint />} />
                                    <Route
                                        path="edit/:id"
                                        element={<EditPoint />}
                                    />
                                </Route>
                            </Route>
                            <Route path="asset">
                                <Route index element={<Asset />}></Route>
                                <Route
                                    path=":id"
                                    element={<AssetDetail />}
                                ></Route>
                                <Route
                                    path="add"
                                    element={<AddAsset />}
                                ></Route>
                                <Route
                                    path="edit/:id"
                                    element={<EditAsset />}
                                ></Route>
                                <Route
                                    path="log/:id"
                                    element={<AssetLog />}
                                ></Route>
                                <Route
                                    path="ticket/:id"
                                    element={<AssetTicket />}
                                ></Route>
                                <Route
                                    path="maintenance/:id"
                                    element={<AssetMaintenance />}
                                ></Route>
                                <Route
                                    path="maintenance-log/:id"
                                    element={<AssetMaintenanceLog />}
                                ></Route>
                                <Route
                                    path="loan/:id"
                                    element={<AssetLoan />}
                                ></Route>
                                <Route
                                    path="attachments/:id"
                                    element={<AssetAttachment />}
                                ></Route>
                                <Route
                                    path="import"
                                    element={<ImportAsset />}
                                ></Route>
                            </Route>
                            <Route path="report">
                                <Route index element={<Report />}></Route>
                                <Route
                                    path="asset"
                                    element={<ReportAsset />}
                                ></Route>
                                <Route
                                    path="asset/:id"
                                    element={<AssetList />}
                                ></Route>
                                <Route
                                    path="maintenance"
                                    element={<ReportMaintenance />}
                                ></Route>
                                <Route
                                    path="maintenance/:id"
                                    element={<MaintenaceList />}
                                ></Route>
                                <Route
                                    path="individual-asset-history"
                                    element={<ReportAssetHistory />}
                                ></Route>
                                <Route
                                    path="location"
                                    element={<ReportAssetLocation />}
                                ></Route>
                                <Route
                                    path="location/:id"
                                    element={<AssetLocationList />}
                                ></Route>
                                <Route
                                    path="fault"
                                    element={<ReportFault />}
                                ></Route>
                                <Route
                                    path="fault/:id"
                                    element={<FaultList />}
                                ></Route>
                            </Route>
                            <Route path="attribute">
                                <Route path="category">
                                    <Route
                                        index
                                        element={<Categories />}
                                    ></Route>
                                    <Route
                                        path="add"
                                        element={<AddCategory />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<EditCategory />}
                                    />
                                </Route>
                                <Route path="sub-category">
                                    <Route
                                        index
                                        element={<SubCategory />}
                                    ></Route>
                                    <Route
                                        path="add"
                                        element={<AddSubCategory />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<EditSubCategory />}
                                    />
                                </Route>
                                <Route path="vendor">
                                    <Route index element={<Vendors />}></Route>
                                    <Route path="add" element={<AddVendor />} />
                                    <Route
                                        path="edit/:id"
                                        element={<EditVendor />}
                                    />
                                </Route>
                                <Route path="asset-user">
                                    <Route
                                        index
                                        element={<AssetUser />}
                                    ></Route>
                                    <Route
                                        path="add"
                                        element={<AddAssetUser />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<EditAssetUser />}
                                    />
                                </Route>
                            </Route>
                            {/*<Route path="*" element={<NoMatch/>}/>*/}
                            <Route path="people">
                                <Route path="users">
                                    <Route index element={<Users />}></Route>
                                    <Route path="add" element={<UserAdd />} />
                                    <Route
                                        path="edit/:id"
                                        element={<UserEdit />}
                                    />
                                </Route>
                                <Route path="admin">
                                    <Route index element={<Admin />}></Route>
                                    <Route path="add" element={<AdminAdd />} />
                                    <Route
                                        path="edit/:id"
                                        element={<AdminEdit />}
                                    />
                                </Route>
                                <Route path="roles">
                                    <Route index element={<UserRole />}></Route>
                                    <Route
                                        path="add"
                                        element={<UserRoleAdd />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<UserRoleEdit />}
                                    />
                                </Route>
                            </Route>
                            <Route path="maintenance">
                                <Route index element={<Maintenance />} />
                                <Route
                                    path="add/:ticketId?/:ticketNo?"
                                    element={<AddMaintenance />}
                                />
                                <Route
                                    path="edit/:id"
                                    element={<EditMaintenance />}
                                />
                                <Route
                                    path="mass-checkin"
                                    element={<MassCheckin />}
                                />
                                <Route
                                    path="mass-checkout"
                                    element={<MassCheckout />}
                                />
                            </Route>
                            <Route path={'loan'}>
                                <Route index element={<Loan />} />
                                <Route path="add" element={<AddLoan />} />
                                <Route path="edit/:id" element={<EditLoan />} />
                            </Route>

                            <Route path={'transfer'}>
                                <Route index element={<Transfer />} />
                                <Route path="add" element={<AddTransfer />} />
                            </Route>

                            <Route path={'ticket'}>
                                <Route index element={<Ticket />} />
                                <Route
                                    path={'detail/:id'}
                                    element={<TicketDetail />}
                                />
                                <Route
                                    path={'maintenance/:id'}
                                    element={<TicketMaintenance />}
                                />
                            </Route>
                        </Route>
                    </>
                ) : (
                    <>
                        {/* <Route path="/" element={<div>public site taruh disini <a href="/login">LOGIN</a></div>} /> */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} exact />
                        <Route path="/*" element={<Navigate to={'/login'} />} />
                    </>
                )}
                <Route path="/" element={<PublicPage />} />
            </Routes>
        </div>
    )
}

export default App
