import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    FormSelect,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import * as XLSX from 'xlsx'
import moment from 'moment/moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {} from 'react-router-dom'
import CustomPagination from '@/components/CustomPagination'

export default function FaultList() {
    const { id } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)
    const [content, setContent] = useState([])
    const [searchParams] = useSearchParams()
    const fromDate = searchParams.get('fromDate')
    const toDate = searchParams.get('toDate')

    const handleExportExcel = () => {
        axios
            .get(
                `/api/v1/ticket/?size=999999&fromDate=${fromDate}&toDate=${toDate}${
                    id !== null && id !== '' && id !== 'all'
                        ? `&assetId=${id}`
                        : ''
                }`
            )
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.content

                    const wb = XLSX.utils.book_new()

                    const ws = XLSX.utils.json_to_sheet(data)

                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

                    const fileName = `Asset-fault-${moment().format(
                        'YYYY-MM-DD'
                    )}.xlsx`

                    toast.success('Success Export to Excel')

                    XLSX.writeFile(wb, fileName)
                }
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        axios
            .get(
                `/api/v1/ticket/?page=${currentPage}&size=${itemsPerPage}&fromDate=${fromDate}&toDate=${toDate}${
                    id !== null && id !== '' && id !== 'all'
                        ? `&assetId=${id}`
                        : ''
                }`
            )
            .then((resp) => {
                if (resp.status == 200) {
                    setContent(resp.data.content)
                    setTotalData(resp.data.totalElements)
                }
            })
            .catch(() => {
                toast.error('You are not allowed')
            })
    }, [])

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="fault" />

            <sgds-content-body>
                <div className="bg-white shadow-sm rounded p-4 btn-purple">
                    <Breadcrumb>
                        <BreadcrumbItem href="/report/fault">
                            FAULT REPORT
                        </BreadcrumbItem>
                        {/* <BreadcrumbItem active>BUILDING BLOCK 1</BreadcrumbItem> */}
                    </Breadcrumb>
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>ASSET NAME</TableHeaderCell>
                                <TableHeaderCell>SUBJECT</TableHeaderCell>
                                <TableHeaderCell>SUBMIT BY</TableHeaderCell>
                                <TableHeaderCell>ASSIGN TO</TableHeaderCell>
                                <TableHeaderCell>BUILDING</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {content.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableDataCell>
                                            {index +
                                                1 +
                                                (currentPage - 1) *
                                                    itemsPerPage}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.assetName}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data?.name}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.creatorUsername}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.assignedUserName}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.assetBuildingLocation}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <Badge bg="warning">
                                                {data.status}
                                            </Badge>
                                        </TableDataCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
                <Row className="btn-purple">
                    <Col>
                        <Button
                            size="sm"
                            className="px-3"
                            onClick={handleExportExcel}
                        >
                            Export to excel
                        </Button>
                    </Col>
                </Row>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
