import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import CustomPagination from '@/components/CustomPagination'
import React from 'react'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'

export default function MaintenaceList({
    list,
    setList,
    setCurrentPage,
    setItemsPerPage,
    currentPage,
    itemsPerPage,
    totalData,
    handleExportExcel,
}) {
    return (
        <>
            <div className="bg-white shadow-sm rounded p-4 btn-purple">
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => setList([])}>
                        SERVICE & MAINTENANCE REPORT
                    </BreadcrumbItem>
                    <BreadcrumbItem active>ASSET</BreadcrumbItem>
                </Breadcrumb>
                <Table borderless={true} hover={true} size="sm" responsive="lg">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>NO</TableHeaderCell>
                            <TableHeaderCell width={'15%'}>
                                ASSET NAME
                            </TableHeaderCell>
                            <TableHeaderCell>CATEGORY</TableHeaderCell>
                            <TableHeaderCell>SCHEDULED DATE</TableHeaderCell>
                            <TableHeaderCell>CHECK IN DATE</TableHeaderCell>
                            <TableHeaderCell>CHECK OUT DATE</TableHeaderCell>
                            <TableHeaderCell>TOTAL DOWN TIME</TableHeaderCell>
                            <TableHeaderCell>STATUS</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow key={index}>
                                <TableDataCell>{item.runNumber}</TableDataCell>
                                <TableDataCell>{item.assetName}</TableDataCell>
                                <TableDataCell>{item.category}</TableDataCell>
                                <TableDataCell>
                                    <CustomDate date={item.scheduledDate} />
                                </TableDataCell>
                                <TableDataCell>
                                    <CustomDate
                                        date={item.checkInDate || '-'}
                                    />
                                </TableDataCell>
                                <TableDataCell>
                                    <CustomDate
                                        date={item.checkOutDate || '-'}
                                    />
                                </TableDataCell>
                                <TableDataCell>
                                    {item.totalDowntime ?? '-'}
                                </TableDataCell>
                                <TableDataCell>
                                    <BadgeCustom status={item.status} />
                                </TableDataCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <CustomPagination
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                itemsPerPageDefault={10}
                totalData={totalData}
            />
            <Row className="btn-purple">
                <Col>
                    <Button
                        size="sm"
                        className="px-3"
                        onClick={handleExportExcel}
                        type={'button'}
                    >
                        Export to excel
                    </Button>
                </Col>
            </Row>
        </>
    )
}
