import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import SubCategoryForm from '@/components/Attribute/SubCategoryForm'
import { Link, useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

export default function AddSubCategory() {
    const [name, setName] = useState('')
    const refCategory = useRef('')

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Asset Sub Category'
        )
    ) {
        navigate('/dashboard')
    }

    const postSubCategory = () => {
        let categoryId = refCategory?.current?.value
        if (
            typeof categoryId === 'undefined' ||
            categoryId === '' ||
            categoryId === null
        ) {
            toast.error('Opps,... Please select category!')
            return
        }
        axios
            .post(`/api/v1/attributes/asset_sub_category`, {
                name,
                categoryId,
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success  Sub Category')
                    setTimeout(function () {
                        navigate('/attribute/sub-category')
                    }, 2500)
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                if (error.response.data.message.includes('same Name')) {
                    toast.error(
                        'Opps,... This Sub Category has already been added to the system. Please check your details!'
                    )
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postSubCategory()
    }

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>New Asset Sub-Category</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/attribute/sub-category">Attributes</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>New Asset Sub-Category</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        <SubCategoryForm
                            refCategory={refCategory}
                            name={name}
                            setName={setName}
                        />
                    </div>
                    <Row>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/attribute/sub-category')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
