import { Button, Col, Form, FormSelect, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

export default function ReportAssetLocation() {
    return (
        <TemplateDashboard>
            <sgds-template-grid>
                <sgds-content-area>
                    <ReportMenu activeMenu="asset_location" />

                    <sgds-content-body>
                        <div className="bg-white shadow-sm rounded p-4 btn-purple">
                            <h2>Asset Location Report</h2>
                            <p className="fw-bold">
                                Generate a report with all the asset in the
                                selected location
                            </p>
                            <Row className="px-3">
                                <Col md={12}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicText"
                                    >
                                        <Form.Label>Location</Form.Label>
                                        <FormSelect
                                            aria-label="Category"
                                            id="defaultSelect"
                                            size="sm"
                                        >
                                            <option>
                                                Open this select menu
                                            </option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </FormSelect>
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Button
                                        href="/report/location/1"
                                        size="sm"
                                        className="px-3"
                                    >
                                        Generate
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </sgds-content-body>
                </sgds-content-area>
            </sgds-template-grid>
        </TemplateDashboard>
    )
}
