import { useEffect, useState } from 'react'
import {
    Dropdown,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import CustomPagination from '../CustomPagination'
import Cookies from 'js-cookie'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import ModalDelete from '../ModalDelete'
import { toast } from 'react-toastify'

export default function DeparmentList({ search }) {
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)
    const [dataDepartments, setDataDepartments] = useState([])
    const [loading, setLoading] = useState(true)
    const [departmentDataDelete, setDepartmentDataDelete] = useState(null)
    const token = Cookies.get('authKey')

    const checkAccess = (role) => {
        return JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === role
        )
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/department/?name=${search}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setDataDepartments(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }
    const deleteDepartment = (id, name) => {
        axios
            .delete(`/api/v1/department/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setDepartmentDataDelete({})
                    toast.success(`Success Delete ${name}`)
                    fetchData()
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }
    useEffect(() => {
        fetchData()
    }, [search, currentPage, itemsPerPage])

    return (
        <>
            <div className="shadow-sm bg-white px-3 py-2 rounded">
                <Table borderless={true} hover={true} size="sm" responsive="lg">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>NO</TableHeaderCell>
                            <TableHeaderCell>NAME</TableHeaderCell>
                            <TableHeaderCell>NOTES</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableDataCell
                                    className="text-center w-100"
                                    colSpan={4}
                                >
                                    Loading...
                                </TableDataCell>
                            </TableRow>
                        )}
                        {!loading &&
                            dataDepartments.map((department, index) => (
                                <TableRow key={index}>
                                    <TableDataCell>
                                        {(currentPage - 1) * itemsPerPage +
                                            index +
                                            1}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {department.name}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {department.note ?? '-'}
                                    </TableDataCell>
                                    <TableDataCell>
                                        <Dropdown>
                                            {(checkAccess(
                                                'Update Department'
                                            ) ||
                                                checkAccess(
                                                    'Delete Department'
                                                )) && (
                                                <Dropdown.Toggle
                                                    className={`btn-clear`}
                                                >
                                                    <i className="bi bi-three-dots"></i>
                                                </Dropdown.Toggle>
                                            )}
                                            <Dropdown.Menu>
                                                {checkAccess(
                                                    'Update Department'
                                                ) ? (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            navigate(
                                                                `/department/edit/${department.id}`
                                                            )
                                                        }
                                                    >
                                                        Edit Department
                                                    </Dropdown.Item>
                                                ) : (
                                                    ''
                                                )}
                                                {checkAccess(
                                                    'Delete Department'
                                                ) ? (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setDepartmentDataDelete(
                                                                {
                                                                    title: department.name,
                                                                    id: department.id,
                                                                    show: true,
                                                                }
                                                            )
                                                        }}
                                                        className={`text-danger`}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>
                                                ) : (
                                                    ''
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </TableDataCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
            <Row>
                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
            </Row>
            <ModalDelete
                show={departmentDataDelete?.show}
                title="Delete Department"
                message={`Are you sure wanto to delete ${departmentDataDelete?.title}?`}
                handleClose={() => {
                    setDepartmentDataDelete({})
                }}
                handleDelete={() => {
                    deleteDepartment(
                        departmentDataDelete.id,
                        departmentDataDelete.title
                    )
                }}
            />
        </>
    )
}
