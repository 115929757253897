import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import { Link, useNavigate, useParams } from 'react-router-dom'
import TemplateDashboard from '@/components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import RequiredSymbol from '@/components/RequiredSymbol'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function EditLoan() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Asset Loan'
        )
    ) {
        navigate('/dashboard')
    }

    const { id } = useParams()

    const [assetList, setAssetList] = useState([])

    const [assetId, setAssetId] = useState('')

    const [loanedTo, setLoanedTo] = useState('')

    const [borrowerEmail, setBorrowerEmail] = useState('')

    const [borrowerPhoneNo, setBorrowerPhoneNo] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const [expectedLoanDate, setExpectedLoanDate] = useState('')

    const [expectedReturnDate, setExpectedReturnDate] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [type, setType] = useState('Internal Loan')

    const handleSubmit = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/asset_loan/`, {
                id,
                assetId,
                loanedTo,
                borrowerEmail,
                borrowerPhoneNo,
                expectedLoanDate,
                expectedReturnDate,
                type,
            })
            .then((response) => {
                toast.success('Success Edit Loan')

                setTimeout(() => {
                    navigate('/loan')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}`)
            .then((res) => {
                const formattedData = res.data.content.map((data) => ({
                    label: data.name,
                    value: data.id,
                }))
                setAssetList(formattedData)
            })
            .catch((err) => {
                console.error(err.response.data.message)
                err.handleGlobally && err.handleGlobally()
            })
    }

    const getCurrentLoan = () => {
        axios
            .get(`/api/v1/asset_loan/${id}`)
            .then((res) => {
                setAssetId(res.data.assetId)
                setAssetList([
                    {
                        value: res.data.assetId,
                        label: res.data.assetName,
                    },
                ])
                setLoanedTo(res.data.loanedTo)
                setExpectedLoanDate(res.data.expectedLoanDate)
                setExpectedReturnDate(res.data.expectedReturnDate)
                setBorrowerEmail(res.data.borrowerEmail)
                setBorrowerPhoneNo(res.data.borrowerPhoneNo)
                setType(res.data.type)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getCurrentLoan()
    }, [id])

    useEffect(() => {
        if (searchAsset) getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Loan Asset</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/loan')}>
                    Loan asset
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    Edit loan asset
                </BreadcrumbItem>
            </Breadcrumb>

            {isLoading && <>Loading...</>}

            {!isLoading && (
                <sgds-content-body>
                    <Form onSubmit={handleSubmit}>
                        <Row
                            className={`shadow-sm bg-white p-7`}
                            style={{ rowGap: '10px' }}
                        >
                            <Col xs={12}>
                                <Form.Label className={'form-label'}>
                                    Choose asset <RequiredSymbol />
                                </Form.Label>

                                <Select
                                    value={assetList.find(
                                        (option) => option.value === assetId
                                    )}
                                    options={assetList}
                                    required={true}
                                    onInputChange={(val) => setSearchAsset(val)}
                                    onChange={(e) => setAssetId(e.value)}
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Group controlId="Form.loanto">
                                    <Form.Label className={'form-label'}>
                                        Loan to <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type={'text'}
                                        value={loanedTo}
                                        onChange={(e) => {
                                            setLoanedTo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group controlId="Form.phone">
                                    <Form.Label className={'form-label'}>
                                        Phone Number
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        value={borrowerPhoneNo}
                                        onChange={(e) => {
                                            setBorrowerPhoneNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group controlId="Form.email">
                                    <Form.Label className={'form-label'}>
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type={'email'}
                                        value={borrowerEmail}
                                        onChange={(e) => {
                                            setBorrowerEmail(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group controlId="Form.loandate">
                                    <Form.Label className={'form-label'}>
                                        Expected Loan Date <RequiredSymbol />
                                    </Form.Label>
                                    <CustomDatePicker
                                        size="md"
                                        value={expectedLoanDate}
                                        required={true}
                                        onChange={(e) => setExpectedLoanDate(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group controlId="Form.return">
                                    <Form.Label className={'form-label'}>
                                        Expected Return Date <RequiredSymbol />
                                    </Form.Label>
                                    <CustomDatePicker
                                        size="md"
                                        value={expectedReturnDate}
                                        required={true}
                                        onChange={(e) =>
                                            setExpectedReturnDate(e)
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group controlId="Form.location">
                                    <Form.Label className={'form-label'}>
                                        Internal/External Loan{' '}
                                        <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Select
                                        onChange={(e) =>
                                            setType(e.target.value)
                                        }
                                        value={type}
                                    >
                                        <option value={'Internal Loan'}>
                                            Internal Loan
                                        </option>
                                        <option value={'External Loan'}>
                                            External Loan
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className={'d-flex mt-4 gap-4'}>
                            <Link
                                className="btn btn-outline-dark sgds"
                                to={'/loan'}
                            >
                                Cancel
                            </Link>
                            <Button variant="primary" type={'submit'}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </sgds-content-body>
            )}

            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
