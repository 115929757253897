import {
    Dropdown,
    Form,
    FormControl,
    FormControlGroup,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AttributeMenu from '@/components/Attribute/AttributeMenu'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import CustomPagination from '@/components/CustomPagination'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'

export default function AssetUser() {
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [data, setData] = useState(null)
    const [dataDelete, setDataDelete] = useState(null)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset User'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = (page = currentPage) => {
        setLoading(true)
        axios
            .get(
                `/api/v1/attributes/asset_user?page=${page}&size=${itemsPerPage}&name=${
                    search.length === 1 ? '' : search
                }`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                setLoading(false)
                setData({ content: [] })
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }

    const deleteAssetUser = (id, name) => {
        axios
            .delete(`/api/v1/attributes/asset_user/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setDataDelete({})
                    toast.success(`Success Delete ${name}`)
                    getData()
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }

    useEffect(
        function () {
            if (search === '') {
                getData()
                return
            }
            clearTimeout(searchTimeout)
            setSearchTimeout(
                setTimeout(function () {
                    getData(1)
                }, 1000)
            )
        },
        [currentPage, itemsPerPage, search]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <AttributeMenu activeMenu="asset_users" />
            </sgds-content-header-top>
            <h2 className={`mb-0`}>Asset Users</h2>
            <div>
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Search user</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </div>
                    {JSON.parse(
                        localStorage.getItem('currentAccessDtoList')
                    ).some((access) => access.name === 'Create Asset User') ? (
                        <div className="col-md-3">
                            <div className="d-flex align-items-center justify-content-end h-100">
                                <div className="d-flex mt-3">
                                    <div className="p-1">
                                        <Link
                                            to={'/attribute/asset-user/add'}
                                            className="btn btn-outline-dark sgds"
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="bi bi-plus-circle"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>NAME</TableHeaderCell>
                                <TableHeaderCell>PHONE</TableHeaderCell>
                                <TableHeaderCell>EMAIL</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={5}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={5}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {itemsPerPage *
                                                    (currentPage - 1) +
                                                    index +
                                                    1}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.name}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.phoneNumber}
                                            </TableDataCell>

                                            <TableDataCell>
                                                {item.email}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Update Asset User'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/attribute/asset-user/edit/${item.id}`
                                                                    )
                                                                }
                                                            >
                                                                Edit Asset User
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Delete Asset User'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setDataDelete(
                                                                        {
                                                                            title: item.name,
                                                                            id: item.id,
                                                                            show: true,
                                                                        }
                                                                    )
                                                                }}
                                                                className={`text-danger`}
                                                            >
                                                                Delete
                                                            </Dropdown.Item>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete Asset user"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteAssetUser(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
