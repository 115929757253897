import {
    Dropdown,
    Form,
    FormControl,
    FormControlGroup,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import CustomPagination from '@/components/CustomPagination'
import Locationmenu from '@/components/Location/LocationMenu'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'
import { checkAccess } from '@/helpers/checkAccess'

export default function Building() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Building'
        )
    ) {
        navigate('/dashboard')
    }

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const [search, setSearch] = useState('')

    const [dataDelete, setDataDelete] = useState(null)
    const [searchTimeout, setSearchTimeout] = useState(null)

    const fetchData = async (page = null) => {
        setLoading(true)
        let _currentPage = currentPage
        if (page !== null) {
            {
                _currentPage = page
            }
        }
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/location/building?name=${search}&size=${itemsPerPage}&page=${_currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setData(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }

    const deleteBuilding = (id, name) => {
        axios
            .delete(`/api/v1/location/building/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Building ${name}`)
                fetchData()
            })
            .catch((error) => {
                console.error(error.response.data.message)
            })
    }

    useEffect(() => {
        if (search === '') {
            fetchData()
            return
        }
        clearTimeout(searchTimeout)
        setSearchTimeout(
            setTimeout(function () {
                fetchData(1)
            }, 1000)
        )
    }, [search, currentPage, itemsPerPage])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <Locationmenu activeMenu="location_building" />
            </sgds-content-header-top>
            <h2 className={`mb-0`}>Buildings</h2>
            <div>
                <div className="row justify-content-between align-items-end">
                    <div className="col-9 col-md-4">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Search Building</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </div>
                    {checkAccess('Create Building') ? (
                        <div className="col-3 d-flex justify-content-end">
                            <Link
                                to={'/location/building/add'}
                                className="btn btn-outline-dark sgds"
                            >
                                <i
                                    aria-hidden="true"
                                    className="bi bi-plus-circle"
                                />
                            </Link>
                        </div>
                    ) : null}
                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table borderless={true} hover={true} size="sm" responsive>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>BUILDING</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        className="text-center w-100"
                                        colSpan={3}
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data.map((row, index) => (
                                    <TableRow>
                                        <TableDataCell>
                                            {itemsPerPage * (currentPage - 1) +
                                                index +
                                                1}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.name}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <Dropdown>
                                                {(checkAccess(
                                                    'Update Building'
                                                ) ||
                                                    checkAccess(
                                                        'Delete Building'
                                                    )) && (
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu>
                                                    {checkAccess(
                                                        'Update Building'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(
                                                                    `/location/building/edit/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            Edit Building
                                                        </Dropdown.Item>
                                                    ) : null}
                                                    {checkAccess(
                                                        'Delete Building'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDataDelete({
                                                                    title:
                                                                        row?.name ||
                                                                        '',
                                                                    id: row.id,
                                                                    show: true,
                                                                })
                                                            }}
                                                            className={`text-danger`}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    ) : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TableDataCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>

                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />

                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete Building"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteBuilding(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
