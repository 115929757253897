import {
    Button,
    Col,
    FileUpload,
    Form,
    FormSelect,
    Modal,
    Row,
} from '@govtechsg/sgds-react'
import axios from 'axios'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function UploadAttachment({
    show,
    setShow,
    reloadList = () => {},
}) {
    const { id } = useParams()
    const [selectedFile, setSelectedFile] = useState({})
    const [attachment, setAttachment] = useState({ assetId: id })
    const listCategory = [
        'Procurement documents',
        'Commissioning',
        'Maintenance',
        'Fault reporting',
        'Loan',
        'Transfer',
        'Disposal',
        'MIA',
    ]

    const listDescription = {
        'Procurement documents': [
            'PR',
            'PO',
            'Invoices',
            'Service Reports',
            'Others',
        ],
        Commissioning: ['Commissioning report', 'Others'],
        Maintenance: [
            'Maintenance contract',
            'PR',
            'PO',
            'Invoices',
            'Service Reports',
            'Others',
        ],
        'Fault reporting': [
            'PR',
            'PO',
            'Invoices',
            'Service Reports',
            'Others',
        ],
        Loan: ['Loan form', 'Others'],
        Transfer: ['Transfer memo', 'Request form', 'Others'],
        Disposal: ['Request form', 'Collection form', 'Others'],
        MIA: ['Write-off Memo', 'Loss form', 'Others'],
    }

    const uploadFile = (file, callback) => {
        const formData = new FormData()
        formData.append('file', file)
        axios.post('/api/v1/asset/upload_attachment', formData).then((data) => {
            const response = data?.data
            setAttachment((prev) => {
                return {
                    ...prev,
                    ...{
                        fileName: response?.fileName,
                        fileUrl: response?.url,
                    },
                }
            })
            callback()
        })
    }

    const onChangeFile = (file) => {
        if (file.length === 0) {
            setSelectedFile(file)
            setAttachment((prev) => {
                return {
                    ...prev,
                    ...{
                        fileName: null,
                        fileUrl: null,
                    },
                }
            })
            return
        }

        const size_ = file[0].size
        const type_ = file[0].type
        if (size_ > 10000000) {
            toast.warning('Oops... the maximum size of image allowed is 10MB!')
            return
        }
        if (['application/pdf'].includes(type_)) {
            uploadFile(file[0], function () {
                setSelectedFile(file)
            })
            return
        }
        toast.warning('Oops... Only pdf format is allowed!')
        return
    }
    const handleClose = () => setShow(false)

    const postCreate = () => {
        if (
            typeof attachment.fileName === 'undefined' ||
            attachment.fileName == null ||
            typeof attachment.fileUrl === 'undefined' ||
            attachment.fileUrl == null
        ) {
            toast.warning('Please upload your document!')
            return false
        }
        axios
            .post(`/api/v1/asset/attach_to_asset/`, attachment)
            .then((response) => {
                reloadList()
                handleClose()
                if (response.status === 200) {
                    toast.success('Success Add Attachment')
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postCreate()
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple"
        >
            <Modal.Header closeButton>
                <Modal.Title>Upload Attachment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmitForm}>
                    <Row className="px-4">
                        <Col xs={12}>
                            <p className="fw-bold mb-1">Document</p>
                            <Form>
                                <FileUpload
                                    controlId="fileupload2"
                                    onChangeFile={onChangeFile}
                                    selectedFile={selectedFile}
                                    multiple={false}
                                >
                                    <i className="bi bi-upload me-2"></i>Choose
                                    a file
                                </FileUpload>
                            </Form>
                        </Col>
                        <Col md={12}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicText"
                            >
                                <Form.Label>Document Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                    value={attachment?.fileName}
                                    onChange={(e) => {
                                        setAttachment((prev) => {
                                            return {
                                                ...prev,
                                                fileName: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicSelect"
                            >
                                <Form.Label>Category</Form.Label>
                                <FormSelect
                                    size="sm"
                                    value={attachment?.category}
                                    onChange={(e) => {
                                        setAttachment((prev) => {
                                            return {
                                                ...prev,
                                                category: e.target.value,
                                            }
                                        })
                                    }}
                                    required
                                >
                                    <option value="">Select Category</option>
                                    {listCategory.map((item) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </FormSelect>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicSelect"
                            >
                                <Form.Label>Description</Form.Label>
                                <FormSelect
                                    size="sm"
                                    value={attachment?.description}
                                    onChange={(e) => {
                                        setAttachment((prev) => {
                                            return {
                                                ...prev,
                                                description: e.target.value,
                                            }
                                        })
                                    }}
                                    required
                                >
                                    <option value="">Select Description</option>
                                    {listDescription[attachment?.category]?.map(
                                        (item) => {
                                            return (
                                                <option value={item}>
                                                    {item}
                                                </option>
                                            )
                                        }
                                    )}
                                </FormSelect>
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="mt-4">
                            <div className="d-flex gap-2">
                                <Button
                                    variant="outline-dark"
                                    className="px-3"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
