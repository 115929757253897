import React from 'react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem } from 'react-bootstrap'
import RoleEditForm from '@/components/People/Roles/Edit'
import { ToastContainer } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

function Edit() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Role'
        )
    ) {
        navigate('/dashboard')
    }

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={'mb-0'}>Edit User Role</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/people/roles')}>
                    People
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit Role</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <RoleEditForm />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}

export default Edit
