import {
    Button,
    DatePicker,
    Dropdown,
    FormControl,
    FormControlGroup,
    Modal,
    Form,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import MaintenanceMenu from '@/components/Asset/MaintenanceMenu'
import React, { useState, useEffect } from 'react'
import CustomPagination from '@/components/CustomPagination'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import BadgeCustom from '@/components/BadgeCustom'
import ModalDelete from '@/components/ModalDelete'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { Toast } from 'bootstrap'
import CustomDate from '@/components/CustomDate'

export default function AssetMaintenanceLog() {
    let { id } = useParams()
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [assetDetail, setAssetDetail] = useState({})
    const [vendorList, setVendorList] = useState([])
    const [menuItems, setMenuItems] = useState([])
    const [activeItems, setActiveItems] = useState('')
    const [disabledField, setDisabledField] = useState([true])
    const [asset, setAsset] = useState(null)
    const [formMaintenanceVendor, setFormMaintenanceVendor] = useState({
        vendorId: '',
        vendorName: '',
        assetId: id,
        vendorContractStartDate: '',
        vendorContractEndDate: '',
        prNo: '',
        poNo: '',
        poDate: '',
        invoiceNo: '',
        invoiceDate: '',
        preventiveMaintenanceType: '',
        lastPreventiveMaintenanceDate: '',
        preventiveMaintenanceFrequency: 0,
        preventiveMaintenanceDueDate: '',
        lastCertificationDate: '',
        certificationFrequency: 0,
        certificationDueDate: '',
        remark: '',
        warrantyMonth: 0,
        warrantyEndDate: '',
        status: 'Pending',
    })
    const [formMaintenanceVendorEdit, setFormMaintenanceVendorEdit] = useState(
        []
    )

    const [contentMaintenanceLogs, setContentMaintenanceLogs] = useState([])
    const [showModalCheckin, setShowModalCheckin] = React.useState(false)

    const [showModalCheckout, setShowModalCheckout] = React.useState(false)
    const [selectedSchedule, setSelectedSchedule] = useState('')

    const [checkInDate, setCheckInDate] = useState()

    const [checkInRemark, setCheckInRemark] = useState('')

    const [checkOutDate, setCheckOutDate] = useState()

    const [checkOutRemark, setCheckOutRemark] = useState('')

    const [serviceNo, setServiceNo] = useState('')
    const [dataDelete, setDataDelete] = useState(null)
    const [totalData, setTotalData] = useState(0)

    /*
    const assetDetail = {
        asset_name:
            '562 nm edge BrightLine (R) single-edge standard epi-fluorescence dichroic beamsplitter 38 mm x 45 mm x 1.1 mm',
        tag: '003001000028',
        category: 'Microscope accessories',
        status: 'Decomissioned',
    }
    */
    const handleCloseCheckin = () => {
        setShowModalCheckin(false)
    }

    const handleCloseCheckout = () => {
        setShowModalCheckout(false)
    }

    const handleSubmitCheckin = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/maintenance_schedule/check_in/`, [
                {
                    id: selectedSchedule,
                    checkInDate: moment(checkInDate).format('YYYY-MM-DD'),
                    checkInRemark,
                },
            ])
            .then((res) => {
                toast.success('Success check-in')
                setShowModalCheckin(false)
                getAssetMaintenanceLog()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleSubmitCheckout = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/maintenance_schedule/check_out/`, [
                {
                    id: selectedSchedule,
                    checkOutDate: moment(checkOutDate).format('YYYY-MM-DD'),
                    checkOutRemark,
                    serviceNo,
                },
            ])
            .then((res) => {
                toast.success('Success check-out')
                setShowModalCheckout(false)
                getAssetMaintenanceLog()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const deleteSchedule = (id, name) => {
        axios
            .delete(`/api/v1/maintenance_schedule/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Maintenance Schedule ${name}`)
                // fetchData()
                getAssetMaintenanceLog()
            })
            .catch((error) => {
                toast.error('Failed to delete maintenance schedule')
            })
    }

    const getListVendor = () => {
        axios
            .get('/api/v1/attributes/vendor')
            .then((resp) => {
                setVendorList(resp?.data?.content)
            })
            .catch((err) => {
                err.handleGlobally && err.handleGlobally()
            })
    }

    const getAssetDetail = () => {
        axios
            .get('/api/v1/asset/' + id)
            .then((resp) => {
                if (resp.status == 200) {
                    setAssetDetail(resp.data)
                    setAsset({
                        serial_number: resp.data.serialNumber,
                        asset_name: resp.data.name,
                        tag: resp.data.hanaTag,
                        category: resp.data.assetCategoryName,
                        status: resp.data.status,
                        images: resp.data.assetFileDtoList,
                        ...resp.data,
                    })

                    let contentMenuItems = []
                    resp.data.assetMaintenanceContractDtoList.map(
                        (data, index) => {
                            contentMenuItems.push({
                                key: data?.vendorContractStartDate,
                                label: data?.vendorName,
                            })
                            if (index === 0) {
                                setActiveItems(data.vendorContractStartDate)
                                setDisabledField([true])
                            } else {
                                setDisabledField((prevArray) => [
                                    ...prevArray,
                                    true,
                                ])
                            }
                        }
                    )
                    setMenuItems(contentMenuItems)
                }
            })
            .catch((err) => {
                err.handleGlobally && err.handleGlobally()
            })
    }

    const onChangeFormMaintenanceVendor = (keyValueContent) => {
        setFormMaintenanceVendor(keyValueContent)
    }

    const handleClickSubmitNewFormMaintenance = async () => {
        await axios
            .post('/api/v1/asset/maintenance_contract/', formMaintenanceVendor)
            .then((resp) => {
                toast.success('Success submit entry')
                setFormMaintenanceVendor({
                    ...formMaintenanceVendor,
                    vendorId: '',
                    vendorName: '',
                    assetId: id,
                    vendorContractStartDate: '',
                    vendorContractEndDate: '',
                    prNo: '',
                    poNo: '',
                    poDate: '',
                    invoiceNo: '',
                    invoiceDate: '',
                    preventiveMaintenanceType: '',
                    lastPreventiveMaintenanceDate: '',
                    preventiveMaintenanceFrequency: 0,
                    preventiveMaintenanceDueDate: '',
                    lastCertificationDate: '',
                    certificationFrequency: 0,
                    certificationDueDate: '',
                    remark: '',
                    warrantyMonth: 0,
                    warrantyEndDate: '',
                    status: 'Pending',
                })
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const getAssetMaintenanceLog = () => {
        axios
            .get('/api/v1/maintenance_schedule/?assetId=' + id)
            .then((resp) => {
                if (resp.status == 200) {
                    setTotalData(resp?.data?.totalElements)
                    setContentMaintenanceLogs(resp?.data?.content)
                }
            })
            .catch((err) => {
                toast.error('Data not found')
            })
    }

    useEffect(() => {
        getAssetDetail()
        getListVendor()
        getAssetMaintenanceLog()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu
                        id={id}
                        item={asset}
                        activeMenu="asset_maintenance"
                    />
                </>
            )}

            <sgds-content-body>
                <MaintenanceMenu
                    activeMenu="maintenance_log"
                    assetId={id}
                    vendorList={vendorList}
                    onChangeFormMaintenanceVendor={
                        onChangeFormMaintenanceVendor
                    }
                    formMaintenanceVendor={formMaintenanceVendor}
                    handleClickSubmitNewFormMaintenance={
                        handleClickSubmitNewFormMaintenance
                    }
                />
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>
                                    MAINTENANCE TYPE
                                </TableHeaderCell>
                                <TableHeaderCell>SCHEDULE DATE</TableHeaderCell>
                                <TableHeaderCell>CHECK IN</TableHeaderCell>
                                <TableHeaderCell>CHECK OUT</TableHeaderCell>
                                <TableHeaderCell>DOWN TIME</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {contentMaintenanceLogs?.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}

                            {contentMaintenanceLogs.map((data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableDataCell>
                                            {data?.category}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={
                                                    data?.scheduledDate || '-'
                                                }
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={data?.checkInDate || '-'}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={data?.checkOutDate || '-'}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data?.totalDowntime}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <BadgeCustom status={data.status} />
                                        </TableDataCell>
                                        <TableDataCell>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className={`btn-clear`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="5"
                                                        viewBox="0 0 16 5"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                            fill="#667085"
                                                        />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Update Maintenance Schedule'
                                                    ) ? (
                                                        <>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setShowModalCheckin(
                                                                        true
                                                                    )

                                                                    setSelectedSchedule(
                                                                        data.id
                                                                    )
                                                                }}
                                                            >
                                                                Check in
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setShowModalCheckout(
                                                                        true
                                                                    )

                                                                    setSelectedSchedule(
                                                                        data.id
                                                                    )
                                                                }}
                                                            >
                                                                Check out
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/maintenance/edit/${data.id}`
                                                                    )
                                                                }
                                                            >
                                                                Edit Maintenance
                                                                Schedule
                                                            </Dropdown.Item>
                                                        </>
                                                    ) : null}

                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Delete Maintenance Schedule'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDataDelete({
                                                                    title:
                                                                        data?.runNumber ||
                                                                        '',
                                                                    id: data.id,
                                                                    show: true,
                                                                })
                                                            }}
                                                            className={`text-danger`}
                                                        >
                                                            Delete Schedule
                                                        </Dropdown.Item>
                                                    ) : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TableDataCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={totalData}
                    />
                    {/*
                    ambil dari asset logs

                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />

                    */}
                </Row>
            </sgds-content-body>
            <Modal
                size={'lg'}
                show={showModalCheckin}
                onHide={handleCloseCheckin}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Check In</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitCheckin}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.checkindate'}>
                                    <Form.Label>Checkin Date</Form.Label>
                                    <Form.Control
                                        type={'date'}
                                        required={true}
                                        onChange={(e) =>
                                            setCheckInDate(e.target.value)
                                        }
                                        max={moment().format('YYYY-MM-DD')}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.checkinremarks">
                                    <Form.Label className="form-label">
                                        Mass Check in Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        onChange={(e) =>
                                            setCheckInRemark(e.target.value)
                                        }
                                        className={'form-control'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseCheckin}
                        >
                            Close
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                size={'lg'}
                show={showModalCheckout}
                onHide={handleCloseCheckout}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Check Out</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitCheckout}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.checkoutdate'}>
                                    <Form.Label>Checkout Date</Form.Label>
                                    <Form.Control
                                        type={'date'}
                                        required={true}
                                        onChange={(e) =>
                                            setCheckOutDate(e.target.value)
                                        }
                                        max={moment().format('YYYY-MM-DD')}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.reportnumber">
                                    <Form.Label className={'form-label'}>
                                        Service/Inspection Report Number
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        onChange={(e) => {
                                            setServiceNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.checkoutremarks">
                                    <Form.Label className="form-label">
                                        Mass Check out Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        onChange={(e) =>
                                            setCheckOutRemark(e.target.value)
                                        }
                                        className={'form-control'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseCheckout}
                        >
                            Close
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalDelete
                show={dataDelete?.show}
                title="Delete Maintenance Schedule"
                message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                handleClose={() => {
                    setDataDelete({})
                }}
                handleDelete={() => {
                    deleteSchedule(dataDelete.id, dataDelete.title)
                }}
            />
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
