import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import VendorForm from '@/components/Attribute/VendorForm'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

export default function AddVendor() {
    const [vendor, setVendor] = useState({})

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Vendor'
        )
    ) {
        navigate('/dashboard')
    }

    const postCreate = () => {
        axios
            .post(`/api/v1/attributes/vendor`, {
                name: vendor.name,
                contactNo: vendor.contactNo,
                contactPersonName: vendor.contactPersonName,
                email: vendor.email,
                webUrl: vendor.webUrl,
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success Add Vendor')
                    setTimeout(function () {
                        navigate('/attribute/vendor')
                    }, 2500)
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
                if (error.response.data.message.includes('same Name')) {
                    toast.error(
                        'Opps,... This vendor has already been added to the system. Please check your details!'
                    )
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postCreate()
    }

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Add New Vendor</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/attribute/vendor">Attributes</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>New Vendor</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        <VendorForm data={vendor} onChange={setVendor} />
                    </div>
                    <Row className="mt-4">
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/attribute/vendor')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
