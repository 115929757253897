import TemplateDashboard from '../../components/TemplateDashboard'
import {
    DatePicker,
    Dropdown,
    FormControl,
    FormControlGroup,
    Modal,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from '@govtechsg/sgds-react/Form'
import CustomPagination from '../../components/CustomPagination'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import ModalDelete from '@/components/ModalDelete'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function Maintenance() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    const [showModalCheckin, setShowModalCheckin] = React.useState(false)

    const [showModalCheckout, setShowModalCheckout] = React.useState(false)

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const [selectedSchedule, setSelectedSchedule] = useState('')

    const [checkInDate, setCheckInDate] = useState()

    const [checkInRemark, setCheckInRemark] = useState('')

    const [checkOutDate, setCheckOutDate] = useState()

    const [checkOutRemark, setCheckOutRemark] = useState('')

    const [serviceNo, setServiceNo] = useState('')

    const [filterStatus, setFilterStatus] = useState('')

    const [searchAssetName, setSearchAssetName] = useState('')

    const [startDate, setStartDate] = useState('')

    const [endDate, setEndDate] = useState('')

    const [dataDelete, setDataDelete] = useState(null)

    const handleCloseCheckin = () => {
        setShowModalCheckin(false)
    }

    const handleCloseCheckout = () => {
        setShowModalCheckout(false)
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/maintenance_schedule/?status=${filterStatus}&assetName=${searchAssetName}&fromDate=${startDate}&toDate=${endDate}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setData(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }

    const handleSubmitCheckin = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/maintenance_schedule/check_in/`, [
                {
                    id: selectedSchedule,
                    checkInDate: moment(checkInDate).format('YYYY-MM-DD'),
                    checkInRemark,
                },
            ])
            .then((res) => {
                toast.success('Success check-in')
                setShowModalCheckin(false)
                fetchData()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleSubmitCheckout = (e) => {
        e.preventDefault()

        axios
            .patch(`/api/v1/maintenance_schedule/check_out/`, [
                {
                    id: selectedSchedule,
                    checkOutDate: moment(checkOutDate).format('YYYY-MM-DD'),
                    checkOutRemark,
                    serviceNo,
                },
            ])
            .then((res) => {
                toast.success('Success check-out')
                setShowModalCheckout(false)
                fetchData()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleFilterDate = (dates) => {
        if (dates) {
            setStartDate(moment(dates.start).format('YYYY-MM-DD'))
            setEndDate(moment(dates.end).format('YYYY-MM-DD'))
        } else {
            setStartDate('')
            setEndDate('')
        }
    }

    const deleteSchedule = (id, name) => {
        axios
            .delete(`/api/v1/maintenance_schedule/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Maintenance Schedule ${name}`)
                fetchData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        fetchData()
    }, [
        filterStatus,
        searchAssetName,
        startDate,
        endDate,
        itemsPerPage,
        currentPage,
    ])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Maintenance Schedule</h2>
            </sgds-content-header-top>

            <Row className={`justify-content-between`}>
                <Col xxl={8} className={`d-flex gap-3 flex-wrap`}>
                    <Form.Group controlId="search">
                        <Form.Label>Search Asset</Form.Label>
                        <FormControlGroup
                            icon={<i className="bi bi-search"></i>}
                        >
                            <FormControl
                                placeholder={'Search Asset'}
                                onChange={(e) =>
                                    setSearchAssetName(e.target.value)
                                }
                            />
                        </FormControlGroup>
                    </Form.Group>

                    <Form.Group controlId="filterstatus">
                        <Form.Label>Filter by status</Form.Label>
                        <Form.Select
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value={'Scheduled'}>Scheduled</option>
                            <option value={'Checked In'}>Checked In</option>
                            <option value={'Checked Out'}>Checked Out</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group controlId="filterdate">
                        <Form.Label>Filter by date</Form.Label>
                        <DatePicker
                            mode={`range`}
                            onChangeDate={handleFilterDate}
                            placeholder={`dd/mm/yyyy - dd/mm/yyyy`}
                            dateFormat={'DD/MM/YYYY'}
                        ></DatePicker>
                    </Form.Group>
                </Col>

                <Col
                    xxl={4}
                    className={`d-flex gap-3 align-items-xxl-end mt-4 mt-xxl-0 flex-column flex-xxl-row  justify-content-xxl-end`}
                >
                    {JSON.parse(
                        localStorage.getItem('currentAccessDtoList')
                    ).some(
                        (access) =>
                            access.name === 'Update Maintenance Schedule'
                    ) ? (
                        <>
                            <Link
                                to={'/maintenance/mass-checkin'}
                                className="btn btn-outline-dark sgds"
                            >
                                Mass Check in
                            </Link>

                            <Link
                                to={'/maintenance/mass-checkout'}
                                className="btn btn-outline-dark sgds"
                            >
                                Mass Check out
                            </Link>
                        </>
                    ) : null}

                    {JSON.parse(
                        localStorage.getItem('currentAccessDtoList')
                    ).some(
                        (access) =>
                            access.name === 'Create Maintenance Schedule'
                    ) ? (
                        <Link
                            to={'/maintenance/add'}
                            className="btn btn-outline-dark sgds"
                        >
                            <i className="bi bi-plus-circle form-control-icon"></i>
                        </Link>
                    ) : null}
                </Col>
            </Row>

            <sgds-content-body>
                <Table
                    responsive
                    borderless={true}
                    className={`shadow-sm bg-white`}
                >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>ID</TableHeaderCell>
                            <TableHeaderCell width={'15%'}>
                                ASSET NAME
                            </TableHeaderCell>
                            <TableHeaderCell>CATEGORY</TableHeaderCell>
                            <TableHeaderCell>SCHEDULED DATE</TableHeaderCell>
                            <TableHeaderCell>CHECK IN DATE</TableHeaderCell>
                            <TableHeaderCell>CHECK OUT DATE</TableHeaderCell>
                            <TableHeaderCell>TOTAL DOWN TIME</TableHeaderCell>
                            <TableHeaderCell>STATUS</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableDataCell
                                    className="text-center w-100"
                                    colSpan={9}
                                >
                                    Loading...
                                </TableDataCell>
                            </TableRow>
                        )}

                        {!loading && data.length <= 0 && (
                            <TableRow>
                                <TableDataCell
                                    className="text-center w-100"
                                    colSpan={9}
                                >
                                    No Data
                                </TableDataCell>
                            </TableRow>
                        )}

                        {!loading &&
                            data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableDataCell>
                                        {row.runNumber}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.assetName}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.category}
                                    </TableDataCell>
                                    <TableDataCell>
                                        <CustomDate
                                            date={row.scheduledDate || '-'}
                                        />
                                    </TableDataCell>
                                    <TableDataCell>
                                        <CustomDate
                                            date={row.checkInDate || '-'}
                                        />
                                    </TableDataCell>
                                    <TableDataCell>
                                        <CustomDate
                                            date={row.checkOutDate || '-'}
                                        />
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.totalDowntime ?? '-'}
                                    </TableDataCell>
                                    <TableDataCell>
                                        <BadgeCustom status={row.status} />
                                    </TableDataCell>
                                    <TableDataCell>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                className={`btn-clear`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="5"
                                                    viewBox="0 0 16 5"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                        fill="#667085"
                                                    />
                                                </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {JSON.parse(
                                                    localStorage.getItem(
                                                        'currentAccessDtoList'
                                                    )
                                                ).some(
                                                    (access) =>
                                                        access.name ===
                                                        'Update Maintenance Schedule'
                                                ) ? (
                                                    <>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowModalCheckin(
                                                                    true
                                                                )

                                                                setSelectedSchedule(
                                                                    row.id
                                                                )
                                                            }}
                                                        >
                                                            Check in
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowModalCheckout(
                                                                    true
                                                                )

                                                                setSelectedSchedule(
                                                                    row.id
                                                                )
                                                            }}
                                                        >
                                                            Check out
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(
                                                                    `/maintenance/edit/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            Edit Maintenance
                                                            Schedule
                                                        </Dropdown.Item>
                                                    </>
                                                ) : null}

                                                {JSON.parse(
                                                    localStorage.getItem(
                                                        'currentAccessDtoList'
                                                    )
                                                ).some(
                                                    (access) =>
                                                        access.name ===
                                                        'Delete Maintenance Schedule'
                                                ) ? (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setDataDelete({
                                                                title:
                                                                    row?.runNumber ||
                                                                    '',
                                                                id: row.id,
                                                                show: true,
                                                            })
                                                        }}
                                                        className={`text-danger`}
                                                    >
                                                        Delete Schedule
                                                    </Dropdown.Item>
                                                ) : null}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </TableDataCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
            </sgds-content-body>

            <Modal
                size={'lg'}
                show={showModalCheckin}
                onHide={handleCloseCheckin}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Check In</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitCheckin}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.checkindate'}>
                                    <Form.Label>Checkin Date</Form.Label>
                                    <CustomDatePicker
                                        required={true}
                                        size="md"
                                        onChange={(e) => setCheckInDate(e)}
                                        maxDate={moment().format('YYYY-MM-DD')}
                                        value={checkInDate}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.checkinremarks">
                                    <Form.Label className="form-label">
                                        Check in Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        onChange={(e) =>
                                            setCheckInRemark(e.target.value)
                                        }
                                        className={'form-control'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseCheckin}
                        >
                            Close
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                size={'lg'}
                show={showModalCheckout}
                onHide={handleCloseCheckout}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Check Out</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitCheckout}>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group controlId={'Form.checkoutdate'}>
                                    <Form.Label>Checkout Date</Form.Label>
                                    <CustomDatePicker
                                        required={true}
                                        size="md"
                                        onChange={(e) => setCheckOutDate(e)}
                                        maxDate={moment().format('YYYY-MM-DD')}
                                        value={checkOutDate}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.reportnumber">
                                    <Form.Label className={'form-label'}>
                                        Service/Inspection Report Number
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        onChange={(e) => {
                                            setServiceNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} className={`mt-3`}>
                                <Form.Group controlId="Form.checkoutremarks">
                                    <Form.Label className="form-label">
                                        Mass Check out Remarks
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        onChange={(e) =>
                                            setCheckOutRemark(e.target.value)
                                        }
                                        className={'form-control'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-start`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseCheckout}
                        >
                            Close
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalDelete
                show={dataDelete?.show}
                title="Delete Maintenance Schedule"
                message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                handleClose={() => {
                    setDataDelete({})
                }}
                handleDelete={() => {
                    deleteSchedule(dataDelete.id, dataDelete.title)
                }}
            />
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
