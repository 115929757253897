import { Badge } from '@govtechsg/sgds-react'

export default function BadgeCustom({ status }) {
    let color = 'primary'
    let _status = status.toLowerCase()
    switch (_status) {
        case 'deployed':
        case 'returned':
        case 'completed':
        case 'closed':
            color = 'primary'
            break
        case 'on loan':
        case 'transferred':
        case 'assigned':
            color = 'secondary'
            break
        case 'checked in':
        case 'checked out':
        case 'approved':
        case 'open':
        case 'reopened':
            color = 'success'
            break
        case 'in repair':
        case 'cancelled':
        case 'rejected':
            color = 'danger'
            break
        case 'requested':
        case 'pending':
            color = 'warning'
            break
        case 'commissioned':
        case 'scheduled':
        case 'created':
        case 'in progress':
            color = 'info'
            break
        case 'borrowed out':
            color = 'dark'
            break
        case 'decommissioned':
            color = 'light'
            break
        case 'archived':
        case 'mia':
            color = 'disabled'
            break

        default:
            break
    }
    return <Badge bg={`outline-${color}`}>{status}</Badge>
}
