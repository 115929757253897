import React, { useState } from 'react'
import { Form, FormControl, FormControlGroup } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import { toast, ToastContainer } from 'react-toastify'
import DeparmentList from '@/components/Department/DepartMentList'

export default function Department() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Department'
        )
    ) {
        navigate('/dashboard')
    }

    const [search, setSearch] = useState('')

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Departments</h2>
            </sgds-content-header-top>
            <div>
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Search department</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </div>
                    {JSON.parse(
                        localStorage.getItem('currentAccessDtoList')
                    ).some((access) => access.name === 'Create Department') ? (
                        <div className="col-md-3">
                            <div className="d-flex align-items-center justify-content-end h-100">
                                <div className="d-flex mt-3">
                                    <div className="p-1">
                                        <Link
                                            to={'/department/add'}
                                            className="btn btn-outline-dark sgds"
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="bi bi-plus-circle"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <sgds-content-body>
                <DeparmentList search={search} />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
