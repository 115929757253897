import { Form, InputGroup } from '@govtechsg/sgds-react'

export default function Phone({ onChange, value }) {
    const onlyNumberKey = (evt) => {
        // Only ASCII character in that range allowed
        const ASCIICode = evt.which ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return evt.preventDefault()
        return
    }
    return (
        <>
            <Form.Label htmlFor="phone">Phone Number</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Text id="basic-addon1">+65</InputGroup.Text>
                <Form.Control
                    id="phone"
                    type="text"
                    placeholder="855673423"
                    value={value}
                    onChange={onChange}
                    aria-label="Phone"
                    aria-describedby="basic-addon1"
                    pattern="\d*"
                    maxLength="8"
                    onKeyPress={(e) => onlyNumberKey(e)}
                    autoComplete="tel-local"
                />
            </InputGroup>
        </>
    )
}
