import React, { useEffect, useState } from 'react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'

export default function Dashboard() {
    const [listDashboard, setListDashboard] = useState([
        {
            icon: 'bi bi-hdd',
            label: 'Total Asset',
            total: 0,
            link: '/asset',
        },
        {
            icon: 'bi bi-bell-slash',
            label: 'Maintenance',
            total: 0,
            link: '/maintenance',
        },
        {
            icon: 'bi bi-exclamation-triangle',
            label: 'Tickets',
            total: 0,
            link: '/ticket',
        },
        {
            icon: 'bi bi-building',
            label: 'Department',
            total: 0,
            link: '/department',
        },
        {
            icon: 'bi bi-folder',
            label: 'Loan',
            total: 0,
            link: '/loan',
        },
    ])

    const [activeTicketList, setActiveTicketList] = useState([])

    const [activeMaintenanceScheduleList, setActiveMaintenanceScheduleList] =
        useState([])

    const [activeAssetLoanList, setActiveAssetLoansList] = useState([])

    const getDashboard = () => {
        axios
            .get(`/dashboard/`)
            .then((response) => {
                setListDashboard([
                    {
                        icon: 'bi bi-hdd',
                        label: 'Total Asset',
                        total: response.data.totalAsset,
                        link: '/asset',
                    },
                    {
                        icon: 'bi bi-bell-slash',
                        label: 'Maintenance',
                        total: response.data.totalMaintenance,
                        link: '/maintenance',
                    },
                    {
                        icon: 'bi bi-exclamation-triangle',
                        label: 'Tickets',
                        total: response.data.totalTicket,
                        link: '/ticket',
                    },
                    {
                        icon: 'bi bi-building',
                        label: 'Department',
                        total: response.data.totalDepartment,
                        link: '/department',
                    },
                    {
                        icon: 'bi bi-folder',
                        label: 'Loan',
                        total: response.data.totalLoan,
                        link: '/loan',
                    },
                ])

                setActiveTicketList(response.data.activeTicketList)

                setActiveMaintenanceScheduleList(
                    response.data.activeMaintenanceScheduleList
                )

                setActiveAssetLoansList(response.data.activeAssetLoanList)
            })
            .catch((error) => {
                console.error(error.response.data.message)
            })
    }

    useEffect(() => {
        getDashboard()
    }, [])

    return (
        <TemplateDashboard>
            <div className="w-100 d-flex gap-3 flex-wrap flex-xl-nowrap">
                {listDashboard.map((item, index) => {
                    return (
                        <div
                            className="bg-white px-4 py-2 border border-1 rounded w-100"
                            key={index}
                        >
                            <div>
                                <div className="mb-1">
                                    <div className="d-flex gap-2">
                                        <i className={item.icon}></i>
                                        <p className="text-uppercase fw-bold mb-1">
                                            {item.label}
                                        </p>
                                    </div>
                                </div>
                                <h2 className="text-purple">{item.total}</h2>
                                <Link to={item.link}>View all</Link>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="mt-3 dashboard">
                <p className="fw-bold text-purple text-uppercase mb-3">
                    Maintenance Schedule
                </p>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>ASSET NAME</TableHeaderCell>
                                <TableHeaderCell>SCHEDULE DATE</TableHeaderCell>
                                <TableHeaderCell>CATEGORY</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {activeMaintenanceScheduleList.map(
                                (data, index) => (
                                    <TableRow>
                                        <TableDataCell width={'50%'}>
                                            {data.assetName}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={data.scheduledDate}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.category}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <BadgeCustom status={data.status} />
                                        </TableDataCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className="dashboard">
                <p className="fw-bold text-purple text-uppercase mb-3">
                    Active Tickets
                </p>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>SUBJECT</TableHeaderCell>
                                <TableHeaderCell>LAST REPLY</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {activeTicketList.map((data, index) => (
                                <TableRow key={index}>
                                    <TableDataCell>{data.note}</TableDataCell>
                                    <TableDataCell>
                                        {data.totalDowntime}
                                    </TableDataCell>
                                    <TableDataCell>
                                        <BadgeCustom status={data.status} />
                                    </TableDataCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className="dashboard">
                <p className="fw-bold text-purple text-uppercase mb-3">
                    Loan List
                </p>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>ASSET NAME</TableHeaderCell>
                                <TableHeaderCell>LOAN TO</TableHeaderCell>
                                <TableHeaderCell>LOAN DATE</TableHeaderCell>
                                <TableHeaderCell>
                                    EXP. RETURN DATE
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {activeAssetLoanList.map((data, index) => (
                                <TableRow key={index}>
                                    <TableDataCell width={'50%'}>
                                        {data.assetName}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {data.loanedTo}
                                    </TableDataCell>
                                    <TableDataCell>
                                        <CustomDate
                                            date={data.loanDate ?? '-'}
                                        />
                                    </TableDataCell>
                                    <TableDataCell>
                                        <CustomDate
                                            date={data.expectedReturnDate}
                                        />
                                    </TableDataCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </TemplateDashboard>
    )
}
