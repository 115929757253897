import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Col,
    Row,
    Form,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import MaintenanceMenu from '@/components/Asset/MaintenanceMenu'
import AssetMenuVertical from '@/components/Asset/AssetMenuVertical'
import MaintenanceForm from '@/components/Asset/MaintenanceForm'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'
import moment from 'moment'
import { checkAccess } from '@/helpers/checkAccess'

export default function AssetMaintenance() {
    let { id } = useParams()

    const [maintenanceContractList, setMaintenanceContractList] = useState([])
    const [assetDetail, setAssetDetail] = useState({})
    const [vendorList, setVendorList] = useState([])

    const [menuItems, setMenuItems] = useState([])
    const [activeItems, setActiveItems] = useState(`index_0`)
    const [disabledField, setDisabledField] = useState([true])
    const [asset, setAsset] = useState(null)

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    // const assetDetail = {
    //     asset_name:
    //         '562 nm edge BrightLine (R) single-edge standard epi-fluorescence dichroic beamsplitter 38 mm x 45 mm x 1.1 mm',
    //     tag: '003001000028',
    //     category: 'Microscope accessories',
    //     status: 'Decomissioned',
    // }

    // const menuItems = [
    //     {
    //         key: 'contract(2024-03-24)',
    //         label: '123213 (2024-03-24)',
    //     },
    //     {
    //         key: 'contract(2024-03-16)',
    //         label: 'Contract (2024-03-16)',
    //     },
    // ]

    const [formMaintenanceVendor, setFormMaintenanceVendor] = useState({
        vendorId: '',
        vendorName: '',
        assetId: id,
        vendorContractStartDate: '',
        vendorContractEndDate: '',
        prNo: '',
        poNo: '',
        poDate: '',
        invoiceNo: '',
        invoiceDate: '',
        preventiveMaintenanceType: '',
        lastPreventiveMaintenanceDate: '',
        preventiveMaintenanceFrequency: 0,
        preventiveMaintenanceDueDate: '',
        lastCertificationDate: '',
        certificationFrequency: 0,
        certificationDueDate: '',
        remark: '',
        warrantyMonth: 0,
        warrantyEndDate: '',
        status: 'Pending',
    })

    const [formMaintenanceVendorEdit, setFormMaintenanceVendorEdit] = useState(
        []
    )

    const getListVendor = () => {
        axios
            .get('/api/v1/attributes/vendor?size=99999&page=1')
            .then((resp) => {
                setVendorList(resp?.data?.content)
            })
    }

    const getAssetDetail = () => {
        axios
            .get('/api/v1/asset/' + id)
            .then((resp) => {
                if (resp.status == 200) {
                    setAssetDetail(resp.data)
                    setAsset({
                        serial_number: resp.data.serialNumber,
                        asset_name: resp.data.name,
                        tag: resp.data.hanaTag,
                        category: resp.data.assetCategoryName,
                        status: resp.data.status,
                        images: resp.data.assetFileDtoList,
                        ...resp.data,
                    })

                    let contentMenuItems = []
                    resp.data.assetMaintenanceContractDtoList.map(
                        (data, index) => {
                            contentMenuItems.push({
                                key: `index_${index}`,
                                label:
                                    'CONTRACT (' +
                                    moment(
                                        data?.vendorContractStartDate
                                    ).format('DD-MM-YYYY') +
                                    ')',
                                children: [
                                    {
                                        key: 'maintenance_vendor_' + index,
                                        label: 'Vendor',
                                    },
                                    {
                                        key: 'maintenance_schedule_' + index,
                                        label: 'Maintenance Schedule',
                                    },
                                ],
                            })
                            if (index === 0) {
                                setActiveItems(`index_${index}`)
                                setDisabledField([true])
                            } else {
                                setDisabledField((prevArray) => [
                                    ...prevArray,
                                    true,
                                ])
                            }
                        }
                    )
                    setMenuItems(contentMenuItems)
                }
            })
            .catch((err) => {
                err.handleGlobally && err.handleGlobally()
            })
    }

    const onChangeFormMaintenanceVendor = (keyValueContent) => {
        setFormMaintenanceVendor(keyValueContent)
    }

    const onChangeFormMaintenanceVendorEdit = (index, keyValueContent) => {
        setFormMaintenanceVendorEdit((prevState) => ({
            ...prevState,
            [index]: keyValueContent,
        }))
    }

    const handleClickSubmitNewFormMaintenance = async (callback = () => {}) => {
        await axios
            .post('/api/v1/asset/maintenance_contract/', formMaintenanceVendor)
            .then((resp) => {
                toast.success('Success submit entry')
                setFormMaintenanceVendor({
                    ...formMaintenanceVendor,
                    vendorId: '',
                    vendorName: '',
                    assetId: id,
                    vendorContractStartDate: '',
                    vendorContractEndDate: '',
                    prNo: '',
                    poNo: '',
                    poDate: '',
                    invoiceNo: '',
                    invoiceDate: '',
                    preventiveMaintenanceType: '',
                    lastPreventiveMaintenanceDate: '',
                    preventiveMaintenanceFrequency: 0,
                    preventiveMaintenanceDueDate: '',
                    lastCertificationDate: '',
                    certificationFrequency: 0,
                    certificationDueDate: '',
                    remark: '',
                    warrantyMonth: 0,
                    warrantyEndDate: '',
                    chargingAccount: '',
                    glNumber: '',
                    status: 'Pending',
                })
                callback()
                getAssetDetail()
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onHandleClickEditInformation = (index) => {
        updateDisabledField(index)
    }

    const updateDisabledField = (index) => {
        // Retrieve the current state array and create a copy
        const newArray = [...disabledField]

        // Modify the copy (e.g., change the content of the first item)
        newArray[index] = !newArray[index]

        // Update the state with the new array
        setDisabledField(newArray)
    }

    const onHandleClickSubmitFormMaintenanceEdit = async (index) => {
        await axios
            .patch(
                '/api/v1/asset/maintenance_contract/',
                formMaintenanceVendorEdit[index]
            )
            .then((resp) => {
                onHandleClickEditInformation(index)
                toast.success('Success submit entry')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onChangeActiveItems = (val) => {
        setActiveItems(val)
    }

    const toggleAccordion = (index) => {
        setActiveItems((prevOpenAccordion) =>
            prevOpenAccordion === index ? null : index
        )
    }

    const copyContentToClipboard = (index) => {
        toast.warning('Content copied to clipboard!')
        const data = formMaintenanceVendorEdit[index]
        setFormMaintenanceVendor(data)
    }

    useEffect(() => {
        getAssetDetail()
        getListVendor()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu
                        id={id}
                        item={asset}
                        activeMenu="asset_maintenance"
                    />
                </>
            )}

            <sgds-content-body>
                <MaintenanceMenu
                    activeMenu="maintenance_contract"
                    assetId={id}
                    vendorList={vendorList}
                    onChangeFormMaintenanceVendor={
                        onChangeFormMaintenanceVendor
                    }
                    formMaintenanceVendor={formMaintenanceVendor}
                    handleClickSubmitNewFormMaintenance={
                        handleClickSubmitNewFormMaintenance
                    }
                />
                <div className="bg-white shadow-sm rounded p-4">
                    <Row>
                        <Col md={3}>
                            <AssetMenuVertical
                                items={menuItems}
                                activeItem={activeItems}
                                isHaveChildren={true}
                                onChangeActiveItems={onChangeActiveItems}
                            />
                        </Col>
                        <Col md={9}>
                            <Accordion defaultActiveKey={activeItems}>
                                {assetDetail?.assetMaintenanceContractDtoList?.map(
                                    (data, index) => {
                                        return (
                                            <AccordionItem
                                                eventKey={`index_${index}`}
                                                open={
                                                    activeItems ===
                                                    `index_${index}`
                                                }
                                                onToggle={() =>
                                                    toggleAccordion(
                                                        `index_${index}`
                                                    )
                                                }
                                            >
                                                <AccordionHeader>
                                                    <div className="d-flex gap-3">
                                                        <h4>
                                                            {'CONTRACT '} (
                                                            <CustomDate
                                                                date={
                                                                    data?.vendorContractStartDate
                                                                }
                                                            />{' '}
                                                            -{' '}
                                                            <CustomDate
                                                                date={
                                                                    data?.vendorContractEndDate
                                                                }
                                                            />
                                                            )
                                                        </h4>
                                                        <div>
                                                            <BadgeCustom
                                                                status={
                                                                    data.status
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody>
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault()
                                                            onHandleClickSubmitFormMaintenanceEdit(
                                                                index
                                                            )
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-end">
                                                            <div>
                                                                <Button
                                                                    href="#"
                                                                    variant="primary"
                                                                    className="me-3 "
                                                                    onClick={() => {
                                                                        copyContentToClipboard(
                                                                            index
                                                                        )
                                                                    }}
                                                                >
                                                                    <i className="bi bi-clipboard"></i>{' '}
                                                                    <small>
                                                                        Copy
                                                                    </small>
                                                                </Button>

                                                                {disabledField[
                                                                    index
                                                                ] ? (
                                                                    <Button
                                                                        type={
                                                                            'button'
                                                                        }
                                                                        href="#"
                                                                        variant="light"
                                                                        onClick={() =>
                                                                            onHandleClickEditInformation(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="bi bi-pencil"></i>{' '}
                                                                        <small>
                                                                            Edit
                                                                            information
                                                                        </small>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="submit"
                                                                        variant="primary"
                                                                    >
                                                                        <i className="bi bi-pencil"></i>{' '}
                                                                        <small>
                                                                            Submit
                                                                        </small>
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <MaintenanceForm
                                                            disabled={
                                                                disabledField[
                                                                    index
                                                                ]
                                                            }
                                                            assetId={id}
                                                            isEdit={true}
                                                            isUser={checkAccess(
                                                                'USER'
                                                            )}
                                                            vendorList={
                                                                vendorList
                                                            }
                                                            onChangeFormMaintenanceVendor={
                                                                onChangeFormMaintenanceVendorEdit
                                                            }
                                                            formMaintenanceVendor={
                                                                formMaintenanceVendorEdit[
                                                                    index
                                                                ]
                                                            }
                                                            indexData={index}
                                                            detailData={data}
                                                        />
                                                    </Form>
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    }
                                )}
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
