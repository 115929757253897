import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Image } from '@govtechsg/sgds-react'
import { Table } from '@govtechsg/sgds-react/Table'

import axios from 'axios'
import { toast } from 'react-toastify'

function ShowImage({ show, setShow, value }) {
    // Sample data for the table
    const data = [
        { id: 1, name: 'Item 1', imageUrl: 'https://via.placeholder.com/150' },
        { id: 2, name: 'Item 2', imageUrl: 'https://via.placeholder.com/150' },
        { id: 3, name: 'Item 3', imageUrl: 'https://via.placeholder.com/150' },
    ]
    const [images, setImages] = useState(value?.images)

    const handleClose = () => setShow(false)

    const handleOnClickDeleteImage = (id) => {
        axios
            .delete('/api/v1/asset/delete_file/' + id)
            .then(() => {
                toast.success('Success delete image')
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
            .catch(() => {
                toast.error('Oops, something went wrong')
            })
    }

    useEffect(() => {
        setImages(value?.images)
    }, [])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple"
        >
            <div
                style={{
                    height: 'auto',
                    // margin: '0 auto',
                    // maxWidth: 64,
                    // width: '100',
                    padding: '2rem 2rem',
                }}
            >
                <div>
                    {/* <Button variant="outline-primary">Upload Image</Button> */}
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Img</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {images?.map((content, index) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.DataCell>
                                            {index + 1}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <img
                                                src={content.fileUrl}
                                                alt={content.fileName}
                                                width={'200px'}
                                            />
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <Button
                                                variant="outline-danger"
                                                onClick={() =>
                                                    handleOnClickDeleteImage(
                                                        content.id
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </Table.DataCell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </Modal>
    )
}

export default ShowImage
