import { Form, FormControl, FormControlGroup } from '@govtechsg/sgds-react/Form'
import { Button } from 'react-bootstrap'
import Logo from '@/images/ntu-logo.png'
import { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Alert } from '@govtechsg/sgds-react'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isError, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        axios
            .post(`/login`, {
                email,
                password,
            })
            .then((res) => {
                Cookies.set('authKey', res.data.authKey)
                Cookies.set('name', res.data.name)
                Cookies.set('roleType', res.data.roleType)
                Cookies.set('email', res.data.email)
                Cookies.set('menuList', JSON.stringify(res.data.menuList))
                localStorage.setItem(
                    'currentAccessDtoList',
                    JSON.stringify(res.data.currentAccessDtoList)
                )
                //redirect to /
                setTimeout(function () {
                    window.location.href = '/dashboard'
                }, 1000)
            })
            .catch((err) => {
                setError(true)
                setErrorMessage('Username or password is wrong!')
                console.error(err.response.data.message)
            })
    }

    return (
        <div className="container d-flex justify-content-center h-100 btn-purple">
            <div className="mt-8">
                <div className="w-100 d-flex align-items-center">
                    <img
                        src={Logo}
                        width={250}
                        alt="logo"
                        className="mx-auto"
                    />
                </div>
                <p className="text-center fw-bold text-muted">
                    Lee Kong Chian School of Medicine
                </p>
                <Alert variant="warning" show={isError}>
                    <div>
                        <i className="bi bi-exclamation-circle me-4"></i>
                        {errorMessage}
                    </div>
                </Alert>
                <div className="bg-white shadow-lg p-4 mt-8">
                    <h3 className="text-center text-purple">Sign In</h3>
                    <p className="text-center fw-lighter">
                        Sign in to your account to start your session
                    </p>
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-muted">
                                Account
                            </Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-envelope"></i>}
                            >
                                <FormControl
                                    type="email"
                                    placeholder="Enter email"
                                    autoComplete="username"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </FormControlGroup>
                        </Form.Group>
                        <Form.Group
                            className="mb-5"
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="text-muted">
                                Password
                            </Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-lock"></i>}
                            >
                                <FormControl
                                    type={`${
                                        showPassword ? 'text' : 'password'
                                    }`}
                                    placeholder="Password"
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    required
                                />
                                {!showPassword && (
                                    <i
                                        className="bi bi-eye icon-end"
                                        onClick={() => {
                                            setShowPassword(true)
                                        }}
                                    ></i>
                                )}
                                {showPassword && (
                                    <i
                                        className="bi bi-eye-slash icon-end"
                                        onClick={() => {
                                            setShowPassword(false)
                                        }}
                                    ></i>
                                )}
                            </FormControlGroup>
                        </Form.Group>
                        <Button className="w-100" size="sm" type="submit">
                            Sign In
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}
