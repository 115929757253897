import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import DepartmentForm from '@/components/Department/DepartmentForm'
import React, { useEffect, useState } from 'react'
import { Form } from '@govtechsg/sgds-react/Form'
import Cookies from 'js-cookie'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

export default function EditDepartment() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Department'
        )
    ) {
        navigate('/dashboard')
    }

    const [name, setName] = useState('')
    const [assetTagPrefix, setAssetTagPrefix] = useState('')
    const [note, setNote] = useState('')
    const [roleId, setRoleId] = useState('')
    const [roleList, setRoleList] = useState([])

    const { id } = useParams()

    const getDepartmentById = () => {
        axios
            .get(`/api/v1/department/${id}`)
            .then((response) => {
                setName(response.data.name ?? '')
                setAssetTagPrefix(response.data.assetTagPrefix ?? '')
                setNote(response.data.note ?? '')
                setRoleId(response.data.roleId ?? '')
            })
            .catch((error) => {
                console.error(error.response.data.message)
            })
    }

    const patchDepartment = () => {
        axios
            .patch(`/api/v1/department/`, {
                id,
                name,
                assetTagPrefix,
                note,
                roleId,
            })
            .then((response) => {
                //toast
                toast.success('Success Edit Department')

                setTimeout(() => {
                    navigate('/department')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        patchDepartment()
    }

    const getRoleList = () => {
        axios
            .get(`/api/v1/role/`)
            .then((res) => {
                setRoleList(res.data.content)
            })
            .catch((err) => {
                console.error(err.response.data.message)
                err.handleGlobally && err.handleGlobally()
            })
    }

    useEffect(() => {
        getRoleList()
        getDepartmentById()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Department</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/department')}>
                    Departments
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit department</BreadcrumbItem>
            </Breadcrumb>
            <Form onSubmit={handleSubmitForm}>
                <sgds-content-body>
                    <div className="bg-white py-2 px-4">
                        <DepartmentForm
                            name={name}
                            setName={setName}
                            assetTagPrefix={assetTagPrefix}
                            setAssetTagPrefix={setAssetTagPrefix}
                            note={note}
                            setNote={setNote}
                            roleId={roleId}
                            setRoleId={setRoleId}
                            roleList={roleList}
                        />
                    </div>
                    <Row>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/department')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type={'submit'}
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </sgds-content-body>
            </Form>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
