import {
    Form,
    FormSelect,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import CustomPagination from '@/components/CustomPagination'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'

export default function AssetLog() {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [data, setData] = useState(null)
    const [status, setStatus] = useState('All')
    const [asset, setAsset] = useState(null)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset Log'
        )
    ) {
        navigate('/dashboard')
    }

    const listStatus = [
        'Requested',
        'Pending',
        'Commissioned',
        'Deployed',
        'In Repair',
        'Decommissioned',
        'Archived',
        'Borrowed Out',
        'On Loan',
        'Transferred',
        'MIA',
    ]

    const getData = (page = currentPage) => {
        if (typeof id === 'undefined') {
            return
        }
        setLoading(true)
        axios
            .get(
                `/api/v1/asset_log/?assetId=${id}&page=${page}&size=${itemsPerPage}&assetStatus=${
                    status !== 'All' ? status : ''
                }`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAsset({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                        ...res.data,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    useEffect(function () {
        if (asset === null) {
            getAsset()
        }
    }, [])

    useEffect(
        function () {
            getData()
        },
        [currentPage, itemsPerPage, status]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu id={id} item={asset} activeMenu="asset_log" />
                </>
            )}

            <div>
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group
                            className="mb-0"
                            controlId="formControlWithIcon"
                        >
                            <Form.Label>Filter by status</Form.Label>
                            <FormSelect
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                }}
                            >
                                <option>All</option>
                                {listStatus.map((item, index) => {
                                    return (
                                        <option value={item} key={index}>
                                            {item}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>TIMESTAMP</TableHeaderCell>
                                <TableHeaderCell>ACTIVITY</TableHeaderCell>
                                <TableHeaderCell>LOCATION</TableHeaderCell>
                                <TableHeaderCell>CREATED BY</TableHeaderCell>
                                <TableHeaderCell>
                                    ASSET NAME UPDATES
                                </TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={6}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                <CustomDate
                                                    date={item.createdAt}
                                                    time={true}
                                                />
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.description || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.buildingName}
                                            </TableDataCell>

                                            <TableDataCell>
                                                {item.creatorUsername || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.name}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <BadgeCustom
                                                    status={item.status}
                                                />
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
