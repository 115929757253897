import {
    Button,
    Col,
    Form,
    FormSelect,
    Modal,
    Row,
} from '@govtechsg/sgds-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
export default function UpdateStatus({ show, item = null, setShow }) {
    const { id } = useParams()
    const [status, setStatus] = useState(null)
    const handleClose = () => setShow(false)
    const listStatus = [
        'Requested',
        'Pending',
        'Commissioned',
        'Deployed',
        'Decommissioned',
        'Archived',
        'MIA',
    ]

    const [currentListStatus, setCurrentListStatus] = useState([])

    const [assetDetail, setAssetDetail] = useState(null)
    const navigate = useNavigate()

    const checkStatus = (val) => {
        switch (val) {
            case 'Deployed':
                setCurrentListStatus([
                    listStatus[4],
                    listStatus[5],
                    listStatus[6],
                ])
                break
            case 'Decommissioned':
                setCurrentListStatus([listStatus[3], listStatus[5]])
                break

            default:
                break
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        axios
            .patch(`/api/v1/asset/update_status`, {
                id: id,
                status,
            })
            .then((response) => {
                setTimeout(function () {
                    handleClose()
                }, 2500)
                if (response.status === 200) {
                    toast.success('Success Update Status')
                    setTimeout(function () {
                        window.location.href = '/asset/' + id
                    }, 2000)
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                if (res.status === 200) {
                    setAssetDetail({
                        title: res.data.name,
                    })
                    checkStatus(res.data.status)
                    return
                }
            })
            .catch((error) => {})
    }

    useEffect(function () {
        if (assetDetail !== null) {
            return
        }
        if (item === null) {
            getAsset()
            return
        }
        setAssetDetail({
            title: item.name,
        })
        checkStatus(item.status)
    }, [])
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="xs"
            className="btn-purple"
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmitForm}>
                    <Row className="px-4">
                        <Col xs={12}>
                            <p className="fw-bold mb-1">Asset Name</p>
                            <p className="mt-0">{assetDetail?.title}</p>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Status</Form.Label>
                                <FormSelect
                                    size="sm"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    required
                                >
                                    <option value="">Select status</option>
                                    {currentListStatus.map((item) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    })}
                                </FormSelect>
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mt-4">
                            <div className="d-flex gap-2">
                                <Button
                                    variant="outline-dark"
                                    className="px-3"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <ToastContainer autoClose={10000} />
        </Modal>
    )
}
