import React, { useEffect, useState } from 'react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem } from 'react-bootstrap'
import UserAddForm from '@/components/People/Users/Add'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

function UserAdd() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create User'
        )
    ) {
        navigate('/dashboard')
    }

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [roleId, setRoleId] = useState('')
    const [profilePictureUrl, setProfilePictureUrl] = useState('')
    const [departmentId, setDepartmentId] = useState('')

    const [departmentList, setDepartmentList] = useState([])
    const [roleList, setRoleList] = useState([])

    const onSubmit = (e) => {
        e.preventDefault()

        axios
            .post(`/api/v1/user/`, {
                name,
                phoneNumber,
                jobTitle,
                email,
                password,
                roleId,
                departmentId,
            })
            .then((response) => {
                //toast
                toast.success('Success Add User')

                setTimeout(() => {
                    navigate('/people/users')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getDepartmentList = () => {
        axios
            .get(`/api/v1/department/list?roleType=USER`)
            .then((res) => {
                setDepartmentList(res.data)
            })
            .catch((err) => console.error(err.response.data.message))
    }

    const getRoleList = () => {
        axios
            .get(`/api/v1/role/type/USER`)
            .then((res) => {
                setRoleList(res.data)
            })
            .catch((err) => console.error(err.response.data.message))
    }

    useEffect(() => {
        getDepartmentList()
        getRoleList()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={'mb-0'}>Add User</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/people/users')}>
                    People
                </BreadcrumbItem>
                <BreadcrumbItem active>User</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <UserAddForm
                    departmentList={departmentList}
                    roleList={roleList}
                    name={name}
                    setName={setName}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    jobTitle={jobTitle}
                    setJobTitle={setJobTitle}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setRoleId={setRoleId}
                    departmentId={departmentId}
                    setDepartmentId={setDepartmentId}
                    email={email}
                    password={password}
                    roleId={roleId}
                    onSubmit={onSubmit}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}

export default UserAdd
