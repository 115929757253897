import {Button, Col, Row} from "@govtechsg/sgds-react";
import {Link} from "react-router-dom";

export default function ReportMenu({activeMenu}) {

    const listMenu = [
        {
            url: "/report",
            label: "Audit log",
            name: "audit_log",
            access: JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                (access) => access.name === 'Audit Log'
            )
        },
        {
            url: "/report/asset",
            label: "Asset list",
            name: "asset_list",
            access: JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                (access) => access.name === 'Asset List Report'
            )
        },
        {
            url: "/report/maintenance",
            label: "Service & maintenance",
            name: "service_maintenance",
            access: JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                (access) => access.name === 'Service & Maintenance Report'
            )
        },
        {
            url: "/report/individual-asset-history",
            label: "Individual Asset Historical",
            name: "individual_asset_history",
            access: JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                (access) => access.name === 'Individual Asset Report'
            )
        },
        {
            url: "/report/fault",
            label: "Fault",
            name: "fault",
            access: JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                (access) => access.name === 'Fault Report'
            )
        },
    ]
    let activeMenuVariant = 'outline-primary';
    return (<>
        <Row>
            <Col md={12}>
                <div className="asset-menu d-block  d-lg-flex gap-2 align-item-center wrap">
                    {listMenu.map((item) =>
                        item.access ? (
                            <Link to={item.url} key={item.name}>
                                <Button
                                    variant={item.name === activeMenu ? activeMenuVariant : ''}
                                    size="sm"
                                    className={`${item.name === activeMenu ? 'active ' : ''}text-uppercase fw-bold`}
                                >
                                    {item.label}
                                </Button>
                            </Link>
                        ) : null
                    )}
                </div>
            </Col>
        </Row>
    </>)
}
