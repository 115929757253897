import { Col, FileUpload, Form, Row } from '@govtechsg/sgds-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { FormSelect } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CustomDatePicker from '../CustomDatePicker'
import RequiredSymbol from '../RequiredSymbol'

export default function AssetForm({
    disabled = false,
    data = null,
    onChange = () => {},
    isUser = false,
}) {
    const [selectedFile, setSelectedFile] = useState([])
    const [asset, setAsset] = useState(data)

    const [listUnit, setListUnit] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [listSubCategory, setListSubCategory] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [listAssetAdministrator, setListAssetAdministrator] = useState([])
    const [listAssetUser, setListAssetUser] = useState([])
    const [listVendor, setListVendor] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(
        data?.assetCategoryId || null
    )
    const [selectedStatus, setSelectedStatus] = useState(data?.status || null)
    const [currentListStatus, setCurrentListStatus] = useState([])
    const [assetFields, setAssetFields] = useState({
        supplierPurchaseRequest: true,
        hanaTag: false,
        supplierPoDate: false,
        assetCategoryId: false,
        assetSubCategoryId: false,
        name: false,
        supplierPoNo: false,
        cost: false,
        supplierId: false,
        serialNumber: false,
        invoiceNo: false,
        invoiceDate: false,
        commissionDate: false,
        assetAdministratorId: false,
        unitId: false,
    })

    const listStatus = [
        'Requested', //0
        'Pending', //1
        'Commissioned', //2
        'Deployed', //3
        'In Repair', //4
        'Decommissioned', //5
        'Archived', //6
        'Borrowed Out', //7
        'On Loan', //8
        'Transferred', //9
        'MIA', //10
    ]

    const checkRequiredFields = (val) => {
        switch (val) {
            case 'Requested':
                setAssetFields({
                    supplierPurchaseRequest: true,
                    hanaTag: false,
                    supplierPoDate: false,
                    assetCategoryId: false,
                    assetSubCategoryId: false,
                    name: false,
                    supplierPoNo: false,
                    cost: false,
                    supplierId: false,
                    serialNumber: false,
                    invoiceNo: false,
                    invoiceDate: false,
                    commissionDate: false,
                    assetAdministratorId: false,
                    unitId: false,
                })
                break
            case 'Pending':
                setAssetFields({
                    supplierPurchaseRequest: true,
                    hanaTag: true,
                    supplierPoDate: true,
                    assetCategoryId: true,
                    assetSubCategoryId: true,
                    name: true,
                    supplierPoNo: true,
                    cost: true,
                    supplierId: true,
                    serialNumber: false,
                    invoiceNo: false,
                    invoiceDate: false,
                    commissionDate: false,
                    assetAdministratorId: false,
                    unitId: false,
                })
                break
            case 'Commissioned':
                setAssetFields({
                    supplierPurchaseRequest: true,
                    hanaTag: true,
                    supplierPoDate: true,
                    assetCategoryId: true,
                    assetSubCategoryId: true,
                    name: true,
                    supplierPoNo: true,
                    cost: true,
                    supplierId: true,
                    serialNumber: true,
                    invoiceNo: true,
                    invoiceDate: true,
                    commissionDate: true,
                    assetAdministratorId: false,
                    unitId: false,
                })
                break
            case 'Deployed':
            case 'Decommissioned':
                setAssetFields({
                    supplierPurchaseRequest: true,
                    hanaTag: true,
                    supplierPoDate: true,
                    assetCategoryId: true,
                    assetSubCategoryId: true,
                    name: true,
                    supplierPoNo: true,
                    cost: true,
                    supplierId: true,
                    serialNumber: true,
                    invoiceNo: true,
                    invoiceDate: true,
                    commissionDate: true,
                    assetAdministratorId: true,
                    unitId: true,
                })
                break
            case 'MIA':
            case 'Archived':
                setAssetFields({
                    supplierPurchaseRequest: true,
                    hanaTag: false,
                    supplierPoDate: false,
                    assetCategoryId: false,
                    assetSubCategoryId: false,
                    name: false,
                    supplierPoNo: false,
                    cost: false,
                    supplierId: false,
                    serialNumber: false,
                    invoiceNo: false,
                    invoiceDate: false,
                    commissionDate: false,
                    assetAdministratorId: false,
                    unitId: false,
                })
                break

            default:
                break
        }
    }

    const checkStatus = (val) => {
        if (disabled === true) {
            {
                setCurrentListStatus(listStatus)
                return
            }
        }
        switch (val) {
            case '':
            case null:
                setCurrentListStatus([
                    listStatus[0],
                    listStatus[1],
                    listStatus[2],
                    listStatus[3],
                ])
                break
            case 'Requested':
                setCurrentListStatus([
                    listStatus[0],
                    listStatus[1],
                    listStatus[2],
                    listStatus[3],
                ])
                break
            case 'Pending':
                setCurrentListStatus([
                    listStatus[1],
                    listStatus[2],
                    listStatus[3],
                ])
                break
            case 'Commissioned':
                setCurrentListStatus([listStatus[2], listStatus[3]])
                break
            case 'Deployed':
                setCurrentListStatus([
                    listStatus[3],
                    listStatus[5],
                    listStatus[6],
                    listStatus[10],
                ])
                break
            case 'Decommissioned':
                setCurrentListStatus([
                    listStatus[3],
                    listStatus[5],
                    listStatus[6],
                    listStatus[10],
                ])
                break

            default:
                setCurrentListStatus([val])
                break
        }
    }

    const updateFileAsset = (input) => {
        axios.post('/api/v1/asset/add_new_file', input).then((data) => {})
    }
    const deleteFileAsset = (id) => {
        axios.delete('/api/v1/asset/delete_file/' + id).then((data) => {})
    }

    const uploadFile = (file, callback) => {
        const formData = new FormData()
        formData.append('file', file)
        axios.post('/api/v1/asset/upload_file', formData).then((data) => {
            const response = data?.data
            const assetFileDtoList_ = asset?.assetFileDtoList || []
            assetFileDtoList_.push({
                fileName: response?.fileName,
                fileUrl: response?.url,
            })
            if (asset?.id) {
                updateFileAsset({
                    assetId: asset.id,
                    fileName: response?.fileName,
                    fileUrl: response?.url,
                })
            }
            setAsset((prev) => {
                return { ...prev, assetFileDtoList: assetFileDtoList_ }
            })
            callback()
        })
    }

    const onChangeFile = (file) => {
        if (file.length === 0) {
            setSelectedFile((prev) => {
                const assetFileDtoList_ = asset?.assetFileDtoList
                const deletedAsset = assetFileDtoList_.shift()
                if (deletedAsset && deletedAsset?.id) {
                    deleteFileAsset(deletedAsset.id)
                }
                setAsset((prev) => {
                    return { ...prev, assetFileDtoList: assetFileDtoList_ }
                })
                const prev_ = prev.shift()
                if (typeof prev_ === 'undefined') {
                    return []
                }
                return prev_
            })
            return false
        }

        if (selectedFile.length > 0) {
            const selectedFile_ = selectedFile.filter(
                (item) => item.name === file[0].name
            )
            if (selectedFile_.length > 0) {
                // delete file
                if (selectedFile_[0]?.id) {
                    deleteFileAsset(selectedFile_[0]?.id)
                }
                const assetFileDtoList_ = asset?.assetFileDtoList
                setAsset((prev) => {
                    return {
                        ...prev,
                        assetFileDtoList: assetFileDtoList_.filter(
                            (item) => item.fileName !== file[0].name
                        ),
                    }
                })
                setSelectedFile((prev) => {
                    return prev.filter((item) => item.name !== file[0].name)
                })
                return false
            }
        }

        if (selectedFile.length > 2) {
            toast.warning('Oops... the maximum number of images allowed is 2!')
            return
        }

        const size_ = file[0].size
        const type_ = file[0].type
        if (size_ > 5000000) {
            toast.warning('Oops... the maximum size of image allowed is 5MB!')
            return
        }
        if (
            ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'].includes(
                type_
            )
        ) {
            uploadFile(file[0], function () {
                setSelectedFile((prev) => {
                    return [...prev, file[0]]
                })
            })
            return
        }
        toast.warning(
            'Oops... only images with the following formats are allowed: png,jpg,jpeg and webp!'
        )
        return
    }

    const getUnit = () => {
        if (listUnit.length > 0) {
            return
        }
        axios
            .get('/api/v1/location/unit/list')
            .then((res) => {
                if (res.status === 200) {
                    setListUnit(res.data)
                }
            })
            .catch((error) => {})
    }
    const getVendor = () => {
        if (listVendor.length > 0) {
            return
        }
        axios
            .get('/api/v1/attributes/vendor/list')
            .then((res) => {
                if (res.status === 200) {
                    setListVendor(res.data)
                }
            })
            .catch((error) => {})
    }
    const getCategory = () => {
        if (listCategory.length > 0) {
            return
        }
        axios
            .get('/api/v1/attributes/asset_category/list')
            .then((res) => {
                if (res.status === 200) {
                    setListCategory(res.data)
                }
            })
            .catch((error) => {})
    }
    const getSubCategory = () => {
        if (selectedCategory == null) {
            return
        }
        axios
            .get(
                `/api/v1/attributes/asset_sub_category/list?assetCategoryId=${selectedCategory}`
            )
            .then((res) => {
                if (res.status === 200) {
                    setListSubCategory(res.data)
                }
            })
            .catch((error) => {})
    }
    const getDepartment = () => {
        if (listDepartment.length > 0) {
            return
        }
        axios
            .get('/api/v1/department/list?roleType=USER')
            .then((res) => {
                if (res.status === 200) {
                    setListDepartment(res.data)
                }
            })
            .catch((error) => {})
    }
    const getAssetAdministrator = () => {
        if (listAssetAdministrator.length > 0) {
            return
        }
        axios
            .get('/api/v1/department/list?roleType=ADMIN')
            .then((res) => {
                if (res.status === 200) {
                    setListAssetAdministrator(res.data)
                }
            })
            .catch((error) => {})
    }
    const getAssetUser = () => {
        if (listAssetUser.length > 0) {
            return
        }
        axios
            .get('/api/v1/attributes/asset_user/list')
            .then((res) => {
                if (res.status === 200) {
                    setListAssetUser(res.data)
                }
            })
            .catch((error) => {})
    }

    useEffect(function () {
        const files = []

        asset?.assetFileDtoList?.forEach((item) => {
            files.push({ name: item.fileName })
        })
        setSelectedFile(files)
        if (disabled) {
            setListUnit([
                {
                    id: asset.unitId,
                    name: asset.unitName,
                    buildingName: asset.buildingName,
                    room: asset.unitRoom,
                },
            ])
            setListCategory([
                { id: asset.assetCategoryId, name: asset.assetCategoryName },
            ])
            setListSubCategory([
                {
                    id: asset.assetSubCategoryId,
                    name: asset.assetSubCategoryName,
                },
            ])
            setListDepartment([
                {
                    id: asset.assetDepartmentId,
                    name: asset.assetDepartmentName,
                },
            ])
            setListAssetAdministrator([
                {
                    id: asset.assetAdministratorId,
                    name: asset.assetAdministratorName,
                },
            ])
            setListAssetUser([
                { id: asset.assetUserId, name: asset.assetUserName },
            ])
            setListVendor([{ id: asset.supplierId, name: asset.supplierName }])
            return
        }
        getUnit()
        getCategory()
        getDepartment()
        getAssetAdministrator()
        getAssetUser()
        getVendor()
    }, [])

    useEffect(
        function () {
            getSubCategory()
        },
        [selectedCategory]
    )

    useEffect(
        function () {
            if (disabled) {
                return
            }
            onChange(asset, selectedFile)
        },
        [asset, selectedFile]
    )

    useEffect(function () {
        checkStatus(asset?.status || null)
        checkRequiredFields(asset?.status || null)
    }, [])

    useEffect(
        function () {
            checkRequiredFields(selectedStatus || null)
        },
        [selectedStatus]
    )

    return (
        <div>
            <div>
                <p className="text-purple fw-bold mb-1" id="asset_product_info">
                    ASSET PRODUCT INFO
                </p>
            </div>
            <div className="btn-purple">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Asset Name{' '}
                                {disabled === false &&
                                    assetFields.name === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={asset?.name}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return { ...prev, name: e.target.value }
                                    })
                                }}
                                disabled={disabled || isUser}
                                placeholder=""
                                size="sm"
                                required={assetFields.name}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Current Location{' '}
                                {disabled === false &&
                                    assetFields.unitId === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled}
                                value={asset?.unitId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            unitId: e.target.value,
                                        }
                                    })
                                }}
                                required={assetFields.unitId}
                            >
                                <option value="">Select Location</option>
                                {listUnit.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`unit-${index}`}
                                        >
                                            {item.buildingName +
                                                ' - ' +
                                                item.name}
                                            {item.room !== null &&
                                            item.room !== ''
                                                ? ' - ' + item.room
                                                : ''}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Tag{' '}
                                {disabled === false &&
                                    assetFields.hanaTag === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.hanaTag}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            hanaTag: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.hanaTag}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>ECC6 Number</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.ecc6Tag}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            ecc6Tag: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>IT Tag</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.itTag}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            itTag: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Category{' '}
                                {disabled === false &&
                                    assetFields.assetCategoryId === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.assetCategoryId}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value)
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            assetCategoryId: e.target.value,
                                        }
                                    })
                                }}
                                required={assetFields.assetCategoryId}
                            >
                                <option value="">Select Category</option>
                                {listCategory.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`category-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Sub Category{' '}
                                {disabled === false &&
                                    assetFields.assetSubCategoryId === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.assetSubCategoryId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            assetSubCategoryId: e.target.value,
                                        }
                                    })
                                }}
                                required={assetFields.assetSubCategoryId}
                            >
                                <option value="">Select Sub Category</option>
                                {listSubCategory.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`sub-category-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Status {!disabled && <RequiredSymbol />}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.status}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            status: e.target.value,
                                        }
                                    })
                                    setSelectedStatus(e.target.value)
                                }}
                                required
                            >
                                {typeof asset?.status === 'undefined' && (
                                    <option value={''}>Select status</option>
                                )}
                                {currentListStatus.map((item, index) => {
                                    return (
                                        <option
                                            value={item}
                                            key={`status-${index}`}
                                        >
                                            {item}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Department</Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.assetDepartmentId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            assetDepartmentId: e.target.value,
                                        }
                                    })
                                }}
                            >
                                <option value="">Select Department</option>
                                {listDepartment.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`department-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Charging Account</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.chargingAccount}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            chargingAccount: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Asset Administrator{' '}
                                {disabled === false &&
                                    assetFields.assetAdministratorId ===
                                        true && <RequiredSymbol />}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.assetAdministratorId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            assetAdministratorId:
                                                e.target.value,
                                        }
                                    })
                                }}
                                required={assetFields.assetAdministratorId}
                            >
                                <option value="">Select Administrator</option>
                                {listAssetAdministrator.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`administrator-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Asset User</Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.assetUserId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            assetUserId: e.target.value,
                                        }
                                    })
                                }}
                            >
                                <option value="">Select Asset User</option>
                                {listAssetUser.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`asset-user-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-3" id="model_detail">
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        model detail & Commission Information
                    </p>
                </div>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Serial Number{' '}
                                {disabled === false &&
                                    assetFields.serialNumber === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.serialNumber}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            serialNumber: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.serialNumber}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Commissioned Date{' '}
                                {disabled === false &&
                                    assetFields.commissionDate === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <CustomDatePicker
                                disabled={disabled || isUser}
                                value={asset?.commissionDate}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            commissionDate: e,
                                        }
                                    })
                                }}
                                required={assetFields.commissionDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Cost (before GST){' '}
                                {disabled === false &&
                                    assetFields.cost === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.cost}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return { ...prev, cost: e.target.value }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.cost}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>License Certification</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.licenseCertification}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            licenseCertification:
                                                e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-3" id="supplier">
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        Supplier
                    </p>
                </div>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Purchase Request{' '}
                                {disabled === false &&
                                    assetFields.supplierPurchaseRequest ===
                                        true && <RequiredSymbol />}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.supplierPurchaseRequest}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            supplierPurchaseRequest:
                                                e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.supplierPurchaseRequest}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Supplier Name{' '}
                                {disabled === false &&
                                    assetFields.supplierId === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <FormSelect
                                size="sm"
                                disabled={disabled || isUser}
                                value={asset?.supplierId}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            supplierId: e.target.value,
                                        }
                                    })
                                }}
                                required={assetFields.supplierId}
                            >
                                <option value="">Select Supplier</option>
                                {listVendor.map((item, index) => {
                                    return (
                                        <option
                                            value={item.id}
                                            key={`supplier-${index}`}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                PO Number{' '}
                                {disabled === false &&
                                    assetFields.supplierPoNo === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.supplierPoNo}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            supplierPoNo: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.supplierPoNo}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                PO Date{' '}
                                {disabled === false &&
                                    assetFields.supplierPoDate === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <CustomDatePicker
                                disabled={disabled || isUser}
                                value={asset?.supplierPoDate}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            supplierPoDate: e,
                                        }
                                    })
                                }}
                                size="sm"
                                required={assetFields.supplierPoDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Invoice Number{' '}
                                {disabled === false &&
                                    assetFields.invoiceNo === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.invoiceNo}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            invoiceNo: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                                required={assetFields.invoiceNo}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Invoice Date{' '}
                                {disabled === false &&
                                    assetFields.invoiceDate === true && (
                                        <RequiredSymbol />
                                    )}
                            </Form.Label>
                            <CustomDatePicker
                                disabled={disabled || isUser}
                                value={asset?.invoiceDate}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            invoiceDate: e,
                                        }
                                    })
                                }}
                                size="sm"
                                required={assetFields.invoiceDate}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="mt-3" id="asset_media">
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        Asset Media
                    </p>
                </div>
                <Row>
                    <Col xs={12}>
                        <Form>
                            <FileUpload
                                controlId="fileupload"
                                onChangeFile={onChangeFile}
                                selectedFile={selectedFile}
                                multiple={true}
                                accept="jpg,png,jpeg,webp"
                                disabled={disabled || isUser}
                            >
                                <i className="bi bi-upload me-2"></i>Choose a
                                file
                            </FileUpload>
                        </Form>
                    </Col>
                </Row>
                <div className="mt-3" id="additional_notes">
                    <p className="text-purple fw-bold mb-1 text-uppercase">
                        Additional Notes
                    </p>
                </div>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={disabled || isUser}
                                value={asset?.additionalInformation}
                                onChange={(e) => {
                                    setAsset((prev) => {
                                        return {
                                            ...prev,
                                            additionalInformation:
                                                e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
