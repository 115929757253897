import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import Cookies from 'js-cookie'

export default function EditProfile() {
    const navigate = useNavigate()

    const [password, setPassword] = useState('')

    const [email, setEmail] = useState(Cookies.get('email'))

    const [error, setError] = useState('')

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)

        const passwordRegex =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,15}$/

        if (passwordRegex.test(e.target.value)) {
            setError('')
        } else {
            setError(
                'Password must contain 1 Special Character, 1 Upper Case Letter, 1 LowerCase Letter, 1 Special Character, and be 8-15 characters long'
            )
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        axios
            .patch(`/my_profile/`, {
                email,
                password,
            })
            .then((res) => {
                toast.success('Success Edit Profile')

                setTimeout(() => {
                    navigate('/dashboard')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Edit Profile</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <div className="bg-white py-2 px-4">
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formBasicText">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder=""
                                        value={email}
                                        size="sm"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formBasicText">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder=""
                                        size="sm"
                                        onChange={handlePasswordChange}
                                    />
                                </Form.Group>
                                {error && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {error}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Row className={`mt-3`}>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => navigate('/dashboard')}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary" type={'submit'}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
