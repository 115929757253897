import React, { useEffect, useState } from 'react'
import {
    Badge,
    Button,
    Dropdown,
    Form,
    FormControl,
    FormControlGroup,
    FormSelect,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import axios from 'axios'
import TemplateDashboard from '@/components/TemplateDashboard'
import CustomPagination from '@/components/CustomPagination'
import { Link, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { toast, ToastContainer } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BadgeCustom from '@/components/BadgeCustom'

function Asset() {
    const [exportLoading, setExportLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [data, setData] = useState(null)
    const [dataDelete, setDataDelete] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [status, setStatus] = useState('All')
    const listStatus = [
        'Requested',
        'Pending',
        'Commissioned',
        'Deployed',
        'In Repair',
        'Decommissioned',
        'Archived',
        'Borrowed Out',
        'On Loan',
        'Transferred',
        'MIA',
    ]
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = (page = currentPage) => {
        setLoading(true)
        axios
            .get(
                `/api/v1/asset/?page=${page}&size=${itemsPerPage}&name=${
                    search.length === 1 ? '' : search
                }&status=${status !== 'All' ? status : ''}&commissionYear=${
                    startDate ? startDate : ''
                }`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                setLoading(false)
                setData({ content: [] })
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }

    const deleteAsset = (id, name) => {
        axios
            .delete(`/api/v1/asset/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setDataDelete({})
                    toast.success(`Success Delete ${name}`)
                    getData()
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const dateNow = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    }

    const exportExcel = (data) => {
        let newData = []
        data.forEach((item, index) => {
            const {
                assetAttachmentDtoList,
                assetFileDtoList,
                assetMaintenanceContractDtoList,
                ..._item
            } = item

            let newItem = []
            let header = []
            for (const [key, value] of Object.entries(_item)) {
                newItem.push(value)
                if (index === 0) {
                    header.push(key)
                }
            }
            if (index === 0) {
                newData.push(header)
            }

            newData.push(newItem)
        })
        toast.success(`Success Export Assets`)
        const ws = XLSX.utils.aoa_to_sheet(newData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        XLSX.writeFile(wb, 'Assets-' + dateNow() + '.xlsx')
    }

    const handleExportToExcel = () => {
        setExportLoading(true)
        axios
            .get('api/v1/asset/list')
            .then((res) => {
                setExportLoading(false)
                if (res.status === 200) {
                    exportExcel(res.data)
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                setExportLoading(false)
                error.handleGlobally && error.handleGlobally()
            })
    }

    useEffect(
        function () {
            if (search === '') {
                getData()
                return
            }
            clearTimeout(searchTimeout)
            setSearchTimeout(
                setTimeout(function () {
                    getData(1)
                }, 1000)
            )
        },
        [currentPage, itemsPerPage, search, status, startDate]
    )

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Asset Dashboard</h2>
            </sgds-content-header-top>
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Search asset</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Filter by status</Form.Label>
                            <FormSelect
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                }}
                            >
                                <option>All</option>
                                {listStatus.map((item, index) => {
                                    return (
                                        <option value={item} key={index}>
                                            {item}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group controlId="formControlWithIcon">
                            <Form.Label>Filter by year</Form.Label>
                            <div style={{ display: 'flex', columnGap: '4px' }}>
                                <DatePicker
                                    className="form-control"
                                    selected={startDate}
                                    onChange={(date) => {
                                        var d = new Date(date)
                                        setStartDate(d.getFullYear().toString())
                                    }}
                                    showYearPicker
                                    dateFormat="yyyy"
                                />
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        setStartDate(null)
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="bi bi-x-circle"
                                    />
                                </Button>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-end justify-content-end h-100">
                            <div className="d-flex mt-3">
                                {JSON.parse(
                                    localStorage.getItem('currentAccessDtoList')
                                ).some(
                                    (access) => access.name === 'Create Asset'
                                ) ? (
                                    <div className="ps-1 pe-1 pb-0">
                                        <Button
                                            variant="outline-dark"
                                            onClick={() => {
                                                window.location.href =
                                                    '/asset/import'
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="bi bi-arrow-bar-up"
                                            />
                                        </Button>
                                    </div>
                                ) : null}
                                <div className="ps-1 pe-1 pb-0">
                                    <Button
                                        disabled={false}
                                        variant="outline-dark"
                                        onClick={handleExportToExcel}
                                    >
                                        {!exportLoading && (
                                            <i
                                                aria-hidden="true"
                                                className="bi bi-printer"
                                            />
                                        )}

                                        {exportLoading && <>Loading...</>}
                                    </Button>
                                </div>
                                {JSON.parse(
                                    localStorage.getItem('currentAccessDtoList')
                                ).some(
                                    (access) => access.name === 'Create Asset'
                                ) ? (
                                    <div className="ps-1 pe-1 pb-0">
                                        <Link
                                            to={'/asset/add'}
                                            className="btn btn-outline-dark sgds"
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="bi bi-plus-circle"
                                            />
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table borderless={true} hover={true} size="sm" responsive>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>TAG</TableHeaderCell>
                                <TableHeaderCell>CATEGORY</TableHeaderCell>
                                <TableHeaderCell>ASSET NAME</TableHeaderCell>
                                <TableHeaderCell>
                                    CURRENT LOCATION
                                </TableHeaderCell>
                                <TableHeaderCell>SERIAL NUMBER</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={7}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={7}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {item.hanaTag}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.assetCategoryName || '-'}
                                            </TableDataCell>

                                            <TableDataCell>
                                                <Link to={`/asset/${item.id}`}>
                                                    {item.name}
                                                </Link>
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.buildingName || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.serialNumber || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <BadgeCustom
                                                    status={item.status}
                                                />
                                            </TableDataCell>
                                            <TableDataCell>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Update Asset'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/asset/${item.id}`
                                                                    )
                                                                }
                                                            >
                                                                See Asset
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Delete Asset'
                                                        )
                                                            ? (item.status.toLowerCase() ===
                                                                  'requested' ||
                                                                  item.status.toLowerCase() ===
                                                                      'pending') && (
                                                                  <Dropdown.Item
                                                                      onClick={() => {
                                                                          setDataDelete(
                                                                              {
                                                                                  title:
                                                                                      item?.name ||
                                                                                      '',
                                                                                  id: item.id,
                                                                                  show: true,
                                                                              }
                                                                          )
                                                                      }}
                                                                      className={`text-danger`}
                                                                  >
                                                                      Delete
                                                                  </Dropdown.Item>
                                                              )
                                                            : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete Asset user"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteAsset(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}

export default Asset
