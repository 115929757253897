import React, { useRef } from 'react'
import Barcode from 'react-barcode'
import { Button, Col, Modal, Row } from '@govtechsg/sgds-react'
import ReactToPrint from 'react-to-print'

function ShowBarcode({ show, setShow, value, assetDetail }) {
    const handleClose = () => setShow(false)
    const componentRef = useRef()

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple"
        >
            <div className="d-flex justify-content-end me-4 mt-4">
                <ReactToPrint
                    trigger={() => (
                        <button className="btn btn-primary">Print</button>
                    )}
                    content={() => componentRef.current}
                />
            </div>
            <div
                className="d-flex align-items-center p-3 border border-1 border-black m-4 rounded"
                ref={componentRef}
            >
                <div>
                    <div>
                        <b>{assetDetail?.asset_name}</b>
                    </div>
                    <div>Asset Number: {assetDetail?.tag}</div>
                    <div>Serial Number: {assetDetail?.serial_number}</div>
                </div>
                <div
                    style={{
                        height: 'auto',
                        margin: '0px 0px 0px auto',
                        maxWidth: 300,
                        width: '300px',
                        // padding: '2rem 2rem',
                    }}
                >
                    <Barcode value={value} />
                </div>
            </div>
        </Modal>
    )
}

export default ShowBarcode
