import { Col, Form, Row } from '@govtechsg/sgds-react'
import Phone from '@/components/Phone'
import { useEffect, useState } from 'react'

export default function VendorForm({ data = null, onChange }) {
    const [vendor, setVendor] = useState(data)

    useEffect(
        function () {
            onChange(vendor)
        },
        [vendor]
    )

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="name">Vendor Name</Form.Label>
                            <Form.Control
                                id="name"
                                type="text"
                                value={vendor.name}
                                onChange={(e) => {
                                    setVendor((prev) => {
                                        return { ...prev, name: e.target.value }
                                    })
                                }}
                                placeholder=""
                                required
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="contact_name">
                                Contact Name
                            </Form.Label>
                            <Form.Control
                                id="contact_name"
                                type="text"
                                placeholder=""
                                value={vendor.contactNo}
                                onChange={(e) => {
                                    setVendor((prev) => {
                                        return {
                                            ...prev,
                                            contactNo: e.target.value,
                                        }
                                    })
                                }}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="phone">Phone</Form.Label>
                            <Phone
                                id="phone"
                                value={vendor.contactPersonName}
                                onChange={(e) => {
                                    setVendor((prev) => {
                                        return {
                                            ...prev,
                                            contactPersonName: e.target.value,
                                        }
                                    })
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                id="email"
                                type="email"
                                placeholder=""
                                value={vendor.email}
                                onChange={(e) => {
                                    setVendor((prev) => {
                                        return {
                                            ...prev,
                                            email: e.target.value,
                                        }
                                    })
                                }}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="web">Web</Form.Label>
                            <Form.Control
                                id="web"
                                type="text"
                                placeholder=""
                                value={vendor.webUrl}
                                onChange={(e) => {
                                    setVendor((prev) => {
                                        return {
                                            ...prev,
                                            webUrl: e.target.value,
                                        }
                                    })
                                }}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
