import React, { useEffect, useRef } from 'react'
import { Modal } from '@govtechsg/sgds-react'
import { QRCode } from 'react-qrcode-logo'
import ReactToPrint from 'react-to-print'

function ShowQR({ show, setShow, value, assetDetail }) {
    const handleClose = () => setShow(false)
    const componentRef = useRef()
    const qrCodeOnly = useRef()
    const pageStyle = `{ size: 17mm 54mm }`

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            className="btn-purple "
        >
            <div className="d-flex justify-content-end me-4 mt-4 gap-2">
                <ReactToPrint
                    trigger={() => (
                        <button className="btn btn-outline-primary">
                            Print QR Only
                        </button>
                    )}
                    content={() => qrCodeOnly.current}
                />
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => (
                        <button className="btn btn-primary">Print</button>
                    )}
                    content={() => componentRef.current}
                />
            </div>
            <div
                className="d-flex pt-4 pb-4 ps-3 pe-4 border border-1 border-black ms-3 me-3 mt-5 mb-5 rounded"
                ref={componentRef}
                style={{
                    fontSize: '23px',
                    fontFamily: 'Arial',
                }}
            >
                <div style={{ width: '70vw' }}>
                    <div
                        className="w-80 ms-3"
                        style={{
                            fontSize:
                                assetDetail?.asset_name?.length > 150
                                    ? '23px'
                                    : assetDetail?.asset_name?.length > 60
                                    ? '23px'
                                    : '',
                        }}
                    >
                        <span style={{ fontWeight: '400' }}>
                            {assetDetail?.asset_name}
                        </span>
                    </div>
                    <div className="ms-3">
                        <b>Asset Number: {assetDetail?.tag}</b>
                    </div>
                    <div className="ms-3">
                        <b>Serial Number: {assetDetail?.serial_number}</b>
                    </div>
                </div>
                <div
                    style={{
                        height: 'auto',
                        margin: '0px 0px 0px auto',
                        // maxWidth: 64,
                        width:
                            assetDetail?.asset_name?.length > 150
                                ? '200px'
                                : '200px',
                        padding: '',
                    }}
                    ref={qrCodeOnly}
                >
                    <div className="d-none">
                        {process.env.REACT_APP_SCAN_TAG_URL +
                            '?scanTag=' +
                            value}
                    </div>
                    {value && (
                        <QRCode
                            size={200}
                            style={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: '200px',
                            }}
                            value={
                                process.env.REACT_APP_SCAN_TAG_URL +
                                '?scanTag=' +
                                value
                            }
                            viewBox={`0 0 256 256`}
                        />
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ShowQR
