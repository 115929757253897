import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import DepartmentForm from '@/components/Department/DepartmentForm'
import { Form } from '@govtechsg/sgds-react/Form'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { toast, ToastContainer } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

export default function AddDepartment() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Department'
        )
    ) {
        navigate('/dashboard')
    }

    const [name, setName] = useState('')
    const [assetTagPrefix, setAssetTagPrefix] = useState('')
    const [note, setNote] = useState('')
    const [roleId, setRoleId] = useState('')
    const [roleList, setRoleList] = useState([])

    const postDepartment = () => {
        axios
            .post(`/api/v1/department/`, {
                name,
                assetTagPrefix,
                note,
                roleId,
            })
            .then((response) => {
                //toast
                toast.success('Success Add Department')

                setTimeout(() => {
                    navigate('/department')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        postDepartment()
    }

    const getRoleList = () => {
        axios
            .get(`/api/v1/role/`)
            .then((res) => {
                setRoleList(res.data.content)
            })
            .catch((err) => {
                err.handleGlobally && err.handleGlobally()
            })
    }

    useEffect(() => {
        getRoleList()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>New Department</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/department')}>
                    Departments
                </BreadcrumbItem>
                <BreadcrumbItem active>New department</BreadcrumbItem>
            </Breadcrumb>
            <Form onSubmit={handleSubmitForm}>
                <sgds-content-body>
                    <div className="bg-white py-2 px-4">
                        <DepartmentForm
                            name={name}
                            setName={setName}
                            assetTagPrefix={assetTagPrefix}
                            setAssetTagPrefix={setAssetTagPrefix}
                            note={note}
                            setNote={setNote}
                            roleId={roleId}
                            setRoleId={setRoleId}
                            roleList={roleList}
                        />
                    </div>
                    <Row>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/department')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type={'submit'}
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </sgds-content-body>
            </Form>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
