import { Button, Col, Form, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import AssetHistoryList from '@/pages/Report/assetHistoryList'
import MaintenaceList from '@/pages/Report/maintenanceList'
import * as XLSX from 'xlsx'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function ReportMaintenance() {
    const [list, setList] = useState([])

    const [assetId, setAssetId] = useState('')
    const [assetList, setAssetList] = useState([])

    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Service & Maintenance Report'
        )
    ) {
        navigate('/dashboard')
    }

    const getAssetList = () => {
        axios
            .get(`/api/v1/maintenance_schedule/?assetName=${searchAsset}`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.assetName,
                    label: `${item.assetName} (TAG: ${item.assetTag}) (PO: ${item.poNo})`,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const fetchData = async () => {
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/maintenance_schedule/?assetName=${assetId}&fromDate=${fromDate}&toDate=${toDate}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            if (response.content.length === 0) {
                toast.warning('Opps..., No records found!')
            }
            setList(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    const handleGenerate = (e) => {
        e.preventDefault()

        fetchData()
    }

    const handleExportExcel = () => {
        axios
            .get(
                `/api/v1/maintenance_schedule/?assetName=${assetId}&fromDate=${fromDate}&toDate=${toDate}`
            )
            .then((res) => {
                const data = res.data.content.map((item, index) => ({
                    'NO.': item.runNumber,
                    'ASSET NAME': item.assetName,
                    CATEGORY: item.category,
                    'SCHEDULED DATE': item.scheduledDate,
                    'CHECK IN DATE': item.checkInDate ?? '-',
                    'CHECK OUT DATE': item.checkOutDate ?? '-',
                    'TOTAL DOWN TIME': item.totalDowntime ?? '-',
                    STATUS: item.status,
                }))

                const wb = XLSX.utils.book_new()

                const ws = XLSX.utils.json_to_sheet(data)

                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

                const fileName = `Service-Maintenance-${moment().format(
                    'YYYY-MM-DD'
                )}.xlsx`

                toast.success('Success Export to Excel')

                XLSX.writeFile(wb, fileName)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleSetFromDate = (e) => {
        setFromDate(e)
    }

    const handleSetToDate = (e) => {
        setToDate(e)
    }

    useEffect(() => {
        if (assetId) fetchData()
    }, [itemsPerPage, currentPage])

    useEffect(() => {
        getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="service_maintenance" />

            <sgds-content-body>
                {list.length > 0 ? (
                    <MaintenaceList
                        handleExportExcel={handleExportExcel}
                        list={list}
                        setList={setList}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPage={itemsPerPage}
                        totalData={totalData}
                    />
                ) : (
                    <div className="bg-white shadow-sm rounded p-4 btn-purple">
                        <h2>Service & Maintenance Report</h2>
                        <p className="fw-bold">
                            Generate a report with all the tasks in the selected
                            period
                        </p>
                        <Row className="px-3">
                            <Col md={12}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formBasicText"
                                >
                                    <Form.Label>Asset</Form.Label>
                                    <Form.Text className="text-muted">
                                        Search by typing asset name, tag, or PO
                                        number.
                                    </Form.Text>
                                    <Select
                                        options={assetList}
                                        required={true}
                                        onInputChange={(val) =>
                                            setSearchAsset(val)
                                        }
                                        onChange={(e) => setAssetId(e.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formBasicText"
                                >
                                    <Form.Label>Start Date</Form.Label>
                                    <CustomDatePicker
                                        size="md"
                                        required={true}
                                        onChange={handleSetFromDate}
                                        value={fromDate}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formBasicText"
                                >
                                    <Form.Label>End Date</Form.Label>
                                    <CustomDatePicker
                                        size="md"
                                        required={true}
                                        onChange={handleSetToDate}
                                        value={toDate}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="mt-4">
                                <Button
                                    size="sm"
                                    className="px-3"
                                    onClick={handleGenerate}
                                >
                                    Generate
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
