import { useEffect, useState } from 'react'

import {
    Button,
    Card,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import ShowQR from '@/components/Asset/modal/ShowQR'
import ShowBarcode from '@/components/Asset/modal/ShowBarcode'
import ShowImage from '@/components/Asset/modal/ShowImage'
import axios from 'axios'
import { useParams } from 'react-router-dom'

export default function AssetSummary({ item = null }) {
    const { id } = useParams()
    const [showModalQR, setShowModalQR] = useState(false)
    const [showModalBarcode, setShowModalBarcode] = useState(false)
    const [ShowModalImage, setShowModalImage] = useState(false)
    const [assetDetail, setAssetDetail] = useState(item)

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                if (res.status === 200) {
                    setAssetDetail({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                    })
                    return
                }
            })
            .catch((error) => {})
    }

    useEffect(function () {
        if (assetDetail !== null) {
            return
        }
        getAsset()
    }, [])

    return (
        <>
            {assetDetail == null && <>Loading...</>}
            {assetDetail !== null && (
                <Card className="p-4 shadow-sm bg-white btn-purple">
                    <Row>
                        <Col md={6}>
                            <h4 className="text-purple">ASSET SUMMARY</h4>
                        </Col>
                        <Col md={6}>
                            <div className="d-flex gap-3 justify-content-end">
                                {/* show image */}
                                <Button onClick={() => setShowModalImage(true)}>
                                    <svg
                                        width="18"
                                        height="16"
                                        viewBox="0 0 18 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.75 5.1875C6.75 5.63505 6.57221 6.06427 6.25574 6.38074C5.93927 6.69721 5.51005 6.875 5.0625 6.875C4.61495 6.875 4.18572 6.69721 3.86926 6.38074C3.55279 6.06427 3.375 5.63505 3.375 5.1875C3.375 4.73995 3.55279 4.31072 3.86926 3.99426C4.18572 3.67779 4.61495 3.5 5.0625 3.5C5.51005 3.5 5.93927 3.67779 6.25574 3.99426C6.57221 4.31072 6.75 4.73995 6.75 5.1875Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M2.25 0.125C1.65326 0.125 1.08097 0.362053 0.65901 0.78401C0.237053 1.20597 0 1.77826 0 2.375V13.625C0 14.2217 0.237053 14.794 0.65901 15.216C1.08097 15.6379 1.65326 15.875 2.25 15.875H15.75C16.3467 15.875 16.919 15.6379 17.341 15.216C17.7629 14.794 18 14.2217 18 13.625V2.375C18 1.77826 17.7629 1.20597 17.341 0.78401C16.919 0.362053 16.3467 0.125 15.75 0.125H2.25ZM15.75 1.25C16.0484 1.25 16.3345 1.36853 16.5455 1.5795C16.7565 1.79048 16.875 2.07663 16.875 2.375V9.6875L12.6259 7.49713C12.5204 7.44428 12.4009 7.42594 12.2844 7.44472C12.1679 7.4635 12.0603 7.51843 11.9767 7.60175L7.803 11.7755L4.8105 9.782C4.70246 9.71007 4.57286 9.67771 4.44368 9.69043C4.3145 9.70314 4.1937 9.76013 4.10175 9.85175L1.125 12.5V2.375C1.125 2.07663 1.24353 1.79048 1.4545 1.5795C1.66548 1.36853 1.95163 1.25 2.25 1.25H15.75Z"
                                            fill="white"
                                        />
                                    </svg>
                                </Button>
                                {/* show qr */}

                                {assetDetail?.tag && (
                                    <Button
                                        onClick={() => setShowModalQR(true)}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7 3H4C3.44772 3 3 3.44772 3 4V7C3 7.55228 3.44772 8 4 8H7C7.55228 8 8 7.55228 8 7V4C8 3.44772 7.55228 3 7 3Z"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M20 3H17C16.4477 3 16 3.44772 16 4V7C16 7.55228 16.4477 8 17 8H20C20.5523 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3Z"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M7 16H4C3.44772 16 3 16.4477 3 17V20C3 20.5523 3.44772 21 4 21H7C7.55228 21 8 20.5523 8 20V17C8 16.4477 7.55228 16 7 16Z"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 16H18C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18V21M21 21V21.01M12 7V10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12H7M3 12H3.01M12 3H12.01M12 16V16.01M16 12H17M21 12V12.01M12 21V20"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </Button>
                                )}
                                {/* show Barcode */}
                                {assetDetail?.tag && (
                                    <Button
                                        onClick={() =>
                                            setShowModalBarcode(true)
                                        }
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3 7V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H7M17 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V7M21 17V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H17M7 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V17M8 7V17M12 7V17M17 7V17"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </Button>
                                )}
                            </div>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <Table borderless={true} size="sm" responsive="lg">
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell
                                            style={{ paddingLeft: 0 }}
                                        >
                                            Asset Name
                                        </TableHeaderCell>
                                        <TableHeaderCell>Tag</TableHeaderCell>
                                        <TableHeaderCell>
                                            Category
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            Status
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableDataCell
                                            style={{
                                                paddingLeft: 0,
                                                maxWidth: '300px',
                                            }}
                                        >
                                            {assetDetail.asset_name}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {assetDetail.tag}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {assetDetail.category}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {assetDetail.status}
                                        </TableDataCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                    <ShowQR
                        show={showModalQR}
                        setShow={setShowModalQR}
                        value={assetDetail?.tag}
                        assetDetail={assetDetail}
                    />
                    <ShowBarcode
                        show={showModalBarcode}
                        setShow={setShowModalBarcode}
                        value={assetDetail?.tag}
                        assetDetail={assetDetail}
                    />
                    <ShowImage
                        show={ShowModalImage}
                        setShow={setShowModalImage}
                        value={assetDetail}
                    />
                </Card>
            )}
        </>
    )
}
