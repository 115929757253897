import React, {useEffect} from 'react'
import Cookies from 'js-cookie'
import {useNavigate} from "react-router-dom";

function Logout() {
  const navigate = useNavigate()

  useEffect(() => {
    Cookies.remove('authKey')
    navigate('/login')
  })

  return (
    <div>Logout...</div>
  )
}

export default Logout