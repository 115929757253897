import {Col, Form, Row} from "@govtechsg/sgds-react";

export default function LocationForm({
                                         setName, name
                                     }) {

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Building</Form.Label>
                            <Form.Control type="text" placeholder="" value={name} size="sm" onChange={(e) => {
                                setName(e.target.value)
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
