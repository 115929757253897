import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetForm from '@/components/Asset/AssetForm'
import AssetMenuVertical from '@/components/Asset/AssetMenuVertical'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { useState } from 'react'

export default function AddAsset() {
    const [asset, setAsset] = useState({})

    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const postCreate = () => {
        axios
            .post(`/api/v1/asset/`, asset)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success Add Asset')
                    setTimeout(function () {
                        navigate('/asset/' + response.data.id)
                    }, 2500)
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postCreate()
    }
    const menuItems = [
        {
            key: 'asset_product_info',
            label: 'Asset Product Info',
        },
        {
            key: 'model_detail',
            label: 'Model Detail & Commission Information',
        },
        {
            key: 'supplier',
            label: 'Supplier',
        },
        {
            key: 'asset_media',
            label: 'Asset Media',
        },
        {
            key: 'additional_notes',
            label: 'Additional Notes',
        },
    ]
    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Create New Asset</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to={'/asset'}>Asset</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Create new asset</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        <Row>
                            <Col md={3}>
                                <AssetMenuVertical items={menuItems} />
                            </Col>
                            <Col md={9}>
                                <AssetForm data={asset} onChange={setAsset} />
                            </Col>
                        </Row>
                    </div>
                    <Row className="mt-4">
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/asset')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
