import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import VendorForm from '@/components/Attribute/VendorForm'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'

export default function EditVendor() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [vendor, setVendor] = useState(null)

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Vendor'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = () => {
        axios
            .get(`/api/v1/attributes/vendor/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    const {
                        id,
                        name,
                        contactNo,
                        contactPersonName,
                        email,
                        webUrl,
                    } = response.data
                    setVendor({
                        id,
                        name,
                        contactNo,
                        contactPersonName,
                        email,
                        webUrl,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }

    const postUpdate = () => {
        axios
            .patch(`/api/v1/attributes/vendor`, {
                id: vendor.id,
                name: vendor.name,
                contactNo: vendor.contactNo,
                contactPersonName: vendor.contactPersonName,
                email: vendor.email,
                webUrl: vendor.webUrl,
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success Update Vendor')
                    setTimeout(function () {
                        navigate('/attribute/vendor')
                    }, 2500)
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                if (error.response.data.message.includes('same Name')) {
                    toast.error(
                        'Opps,... This vendor has already been added to the system. Please check your details!'
                    )
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postUpdate()
    }

    useEffect(function () {
        if (typeof id !== 'undefined' && id !== null) {
            getData()
        }
    }, [])
    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Vendor</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/attribute/vendor">Attributes</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit Vendor</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        {!vendor && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '80px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <p>Loading...</p>
                            </div>
                        )}
                        {vendor && (
                            <VendorForm data={vendor} onChange={setVendor} />
                        )}
                    </div>
                    <Row className="mt-4">
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/attribute/vendor')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
