import { Form } from '@govtechsg/sgds-react/Form'
import {
    Dropdown,
    FormControl,
    FormControlGroup,
    Modal,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import { Link, useNavigate } from 'react-router-dom'
import CustomPagination from '@/components/CustomPagination'
import { Button } from 'react-bootstrap'
import TemplateDashboard from '@/components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import BadgeCustom from '@/components/BadgeCustom'

export default function Transfer() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Transfer Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const [showModalApprove, setShowModalApprove] = useState(false)

    const [showModalReject, setShowModalReject] = useState(false)

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const [searchAssetName, setSearchAssetName] = useState('')

    const [id, setId] = useState('')

    const handleCloseApprove = () => {
        setShowModalApprove(false)
    }

    const handleCloseReject = () => {
        setShowModalReject(false)
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/asset_transfer/?assetName=${searchAssetName}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setData(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }

    const handleApproveReject = (e, action, successMessage) => {
        e.preventDefault()
        axios
            .patch(`/api/v1/asset_transfer/`, {
                id,
                action,
            })
            .then(() => {
                toast.success(`Asset Transfer ${successMessage}`)
                setShowModalApprove(false)
                setShowModalReject(false)
                fetchData()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    useEffect(() => {
        fetchData()
    }, [searchAssetName, itemsPerPage, currentPage])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Transfer Asset</h2>
            </sgds-content-header-top>

            <Row className={`align-items-end justify-content-between`}>
                <Col xs={4}>
                    <Form.Group controlId="search">
                        <Form.Label>Search Asset</Form.Label>
                        <FormControlGroup
                            icon={<i className="bi bi-search"></i>}
                        >
                            <FormControl
                                placeholder={'Search Asset'}
                                onChange={(e) =>
                                    setSearchAssetName(e.target.value)
                                }
                            />
                        </FormControlGroup>
                    </Form.Group>
                </Col>

                {JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                    (access) => access.name === 'Create Transfer Asset'
                ) ? (
                    <Col xs={6} className={`d-flex justify-content-end`}>
                        <Link
                            to={'/transfer/add'}
                            className="btn btn-outline-dark sgds"
                        >
                            <i className="bi bi-plus-circle form-control-icon"></i>
                        </Link>
                    </Col>
                ) : null}
            </Row>

            <sgds-content-body>
                <Table
                    responsive
                    borderless={true}
                    className={`shadow-sm bg-white`}
                >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>NO</TableHeaderCell>
                            <TableHeaderCell width={'15%'}>
                                ASSET NAME
                            </TableHeaderCell>
                            <TableHeaderCell>TAG</TableHeaderCell>
                            <TableHeaderCell>CURRENT LOCATION</TableHeaderCell>
                            <TableHeaderCell>REMARK</TableHeaderCell>
                            <TableHeaderCell>TRANSFER STATUS</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableDataCell
                                    className="text-center w-100"
                                    colSpan={7}
                                >
                                    Loading...
                                </TableDataCell>
                            </TableRow>
                        )}

                        {!loading && data.length <= 0 && (
                            <TableRow>
                                <TableDataCell
                                    className="text-center w-100"
                                    colSpan={7}
                                >
                                    No Data
                                </TableDataCell>
                            </TableRow>
                        )}

                        {!loading &&
                            data.map((row, index) => (
                                <TableRow>
                                    <TableDataCell>
                                        {itemsPerPage * (currentPage - 1) +
                                            index +
                                            1}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.assetName}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.assetHanaTag ?? '-'}
                                    </TableDataCell>
                                    <TableDataCell>
                                        {row.assetLocation ?? '-'}
                                    </TableDataCell>
                                    <TableDataCell>{row.remark}</TableDataCell>
                                    <TableDataCell>
                                        <BadgeCustom status={row.status} />
                                    </TableDataCell>
                                    <TableDataCell>
                                        {JSON.parse(
                                            localStorage.getItem(
                                                'currentAccessDtoList'
                                            )
                                        ).some(
                                            (access) =>
                                                access.name ===
                                                'Update Transfer Asset'
                                        )
                                            ? row.status.toLowerCase() ===
                                                  'pending' && (
                                                  <Dropdown>
                                                      <Dropdown.Toggle
                                                          className={`btn-clear`}
                                                      >
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="16"
                                                              height="5"
                                                              viewBox="0 0 16 5"
                                                              fill="none"
                                                          >
                                                              <path
                                                                  d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                                  fill="#667085"
                                                              />
                                                          </svg>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                          <Dropdown.Item
                                                              onClick={() => {
                                                                  setId(row.id)
                                                                  setShowModalApprove(
                                                                      true
                                                                  )
                                                              }}
                                                          >
                                                              Approve Request
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                              onClick={() => {
                                                                  setId(row.id)
                                                                  setShowModalReject(
                                                                      true
                                                                  )
                                                              }}
                                                          >
                                                              Reject Request
                                                          </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                  </Dropdown>
                                              )
                                            : null}
                                    </TableDataCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
            </sgds-content-body>

            <Modal
                show={showModalApprove}
                onHide={handleCloseApprove}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className={`text-center`}>
                        Confirm Transfer Approval
                    </Modal.Title>
                </Modal.Header>
                <Form
                    onSubmit={(e) =>
                        handleApproveReject(e, 'Approve', 'Approved')
                    }
                >
                    <Modal.Body>
                        <Row className={`justify-content-center`}>
                            <Col xs={12}>
                                Are you sure you want to approve this transfer
                                request?
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-center`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseApprove}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Approve Request
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showModalReject}
                onHide={handleCloseReject}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className={`text-center`}>
                        Confirm Transfer Rejection
                    </Modal.Title>
                </Modal.Header>
                <Form
                    onSubmit={(e) =>
                        handleApproveReject(e, 'Reject', 'Rejected')
                    }
                >
                    <Modal.Body>
                        <Row className={`justify-content-center`}>
                            <Col xs={12}>
                                Are you sure you want to reject this transfer
                                request?
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className={`justify-content-center`}>
                        <Button
                            variant="outline-dark"
                            onClick={handleCloseReject}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type={'submit'}>
                            Reject Transfer
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
