import TemplateDashboard from '../../components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify'
import CustomDatePicker from '@/components/CustomDatePicker'
import RequiredSymbol from '@/components/RequiredSymbol'

export default function EditMaintenance() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    const { id } = useParams()

    const [assetList, setAssetList] = useState([])

    const [vendorList, setVendorList] = useState([])

    const [unitList, setUnitList] = useState([])

    const [assetId, setAssetId] = useState('')

    const [category, setCategory] = useState('Ad-hoc')

    const [vendorId, setVendorId] = useState('')

    const [prNo, setPrNo] = useState('')

    const [poNo, setPoNo] = useState('')

    const [poDate, setPoDate] = useState()

    const [invoiceNo, setInvoiceNo] = useState('')

    const [invoiceDate, setInvoiceDate] = useState(null)

    const [cost, setCost] = useState('')

    const [chargingAccount, setChargingAccount] = useState('')

    const [unitId, setUnitId] = useState('')

    const [note, setNote] = useState('')

    const [scheduledDate, setScheduledDate] = useState(null)

    const [repairLocation, setRepairLocation] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getVendorList = () => {
        axios
            .get(`/api/v1/attributes/vendor/list`)
            .then((res) => {
                const newArray = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setVendorList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getUnitList = () => {
        axios
            .get(`/api/v1/location/unit/list`)
            .then((res) => {
                const newArray = res.data.map((item) => ({
                    value: item.id,
                    label: `${item.buildingName} - ${item.name} - ${item.room}`,
                }))
                setUnitList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            id,
            assetId,
            category,
            scheduledDate:
                scheduledDate !== null
                    ? moment(scheduledDate).format('YYYY-MM-DD')
                    : null,
            vendorId,
            prNo,
            poNo,
            poDate: moment(poDate).format('YYYY-MM-DD'),
            invoiceNo,
            invoiceDate:
                invoiceDate !== null
                    ? moment(invoiceDate).format('YYYY-MM-DD')
                    : null,
            cost,
            chargingAccount,
            // unitId,
            note,
            repairLocation,
        }

        axios
            .patch(`/api/v1/maintenance_schedule/`, data)
            .then((res) => {
                toast.success('Success Edit Maintenance Schedule')

                setTimeout(() => {
                    navigate('/maintenance')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getCurrentSchedule = () => {
        axios
            .get(`/api/v1/maintenance_schedule/${id}`)
            .then((res) => {
                setAssetId(res.data.assetId)
                setAssetList([
                    {
                        value: res.data.assetId,
                        label: res.data.assetName,
                    },
                ])
                setCategory(res.data.category)
                setScheduledDate(res.data.scheduledDate)
                setVendorId(res.data.vendorId)
                setPrNo(res.data.prNo)
                setPoNo(res.data.poNo)
                setPoDate(res.data.poDate)
                setInvoiceNo(res.data.invoiceNo)
                setInvoiceDate(res.data.invoiceDate)
                setCost(res.data.cost)
                setChargingAccount(res.data.chargingAccount)
                // setUnitId(res.data.unitId)
                setNote(res.data.note)
                setRepairLocation(res.data.repairLocation)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        // getAssetList()
        getVendorList()
        getUnitList()
        getCurrentSchedule()
    }, [])

    useEffect(() => {
        if (searchAsset) getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Maintenance Schedule</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/maintenance')}>
                    Maintenance schedule
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    Edit maintenance schedule
                </BreadcrumbItem>
            </Breadcrumb>

            {isLoading && <>Loading...</>}
            {!isLoading && (
                <sgds-content-body>
                    <Form onSubmit={handleSubmit}>
                        <Row
                            className={`shadow-sm bg-white p-7`}
                            style={{ rowGap: '10px' }}
                        >
                            <Col xs={12}>
                                <Form.Group controlId="Form.asset">
                                    <Form.Label>
                                        Choose Asset <RequiredSymbol />
                                    </Form.Label>
                                    <Select
                                        value={assetList.find(
                                            (option) => option.value === assetId
                                        )}
                                        options={assetList}
                                        required={true}
                                        onInputChange={(val) =>
                                            setSearchAsset(val)
                                        }
                                        onChange={(e) => setAssetId(e.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.category">
                                    <Form.Label className={'form-label'}>
                                        Category <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        disabled={true}
                                        value={category}
                                        onChange={() => {}}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.expired">
                                    <Form.Label className={'form-label'}>
                                        Scheduled Date
                                    </Form.Label>
                                    <CustomDatePicker
                                        size={'md'}
                                        value={scheduledDate}
                                        onChange={(e) => setScheduledDate(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.vendor">
                                    <Form.Label className={'form-label'}>
                                        Vendor <RequiredSymbol />
                                    </Form.Label>
                                    <Select
                                        options={vendorList}
                                        required={true}
                                        value={vendorList.find(
                                            (option) =>
                                                option.value === vendorId
                                        )}
                                        onChange={(e) => setVendorId(e.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.prnumber">
                                    <Form.Label className={'form-label'}>
                                        PR Number <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        required={true}
                                        value={prNo}
                                        onChange={(e) => {
                                            setPrNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.ponumber">
                                    <Form.Label className={'form-label'}>
                                        PO Number <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        required={true}
                                        value={poNo}
                                        onChange={(e) => {
                                            setPoNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.podate">
                                    <Form.Label className={'form-label'}>
                                        PO Date <RequiredSymbol />
                                    </Form.Label>
                                    <CustomDatePicker
                                        size={'md'}
                                        required={true}
                                        value={poDate}
                                        onChange={(e) => setPoDate(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.invoice">
                                    <Form.Label className={'form-label'}>
                                        Invoice Number
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        value={invoiceNo}
                                        onChange={(e) => {
                                            setInvoiceNo(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.invoicedate">
                                    <Form.Label className={'form-label'}>
                                        Invoice Date
                                    </Form.Label>
                                    <CustomDatePicker
                                        size={'md'}
                                        value={invoiceDate}
                                        onChange={(e) => setInvoiceDate(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6}>
                                <Form.Group controlId="Form.cost">
                                    <Form.Label className={'form-label'}>
                                        Cost (before GST) <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        required={true}
                                        value={cost}
                                        onChange={(e) => {
                                            setCost(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="Form.charging">
                                    <Form.Label className={'form-label'}>
                                        Charging Account <RequiredSymbol />
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        required={true}
                                        value={chargingAccount}
                                        onChange={(e) => {
                                            setChargingAccount(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group controlId="Form.location">
                                    <Form.Label className={'form-label'}>
                                        Location
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        value={repairLocation}
                                        onChange={(e) =>
                                            setRepairLocation(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group controlId="Form.message">
                                    <Form.Label className={'form-label'}>
                                        Message
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className={'form-control'}
                                        value={note}
                                        onChange={(e) => {
                                            setNote(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className={'d-flex mt-4 gap-4'}>
                            <Link
                                className="btn btn-outline-dark sgds"
                                to={'/maintenance'}
                            >
                                Cancel
                            </Link>
                            <Button variant="primary" type={'submit'}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </sgds-content-body>
            )}
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
