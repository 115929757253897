import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetUserForm from '@/components/Attribute/AssetUserForm'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'

export default function EditAssetUser() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [assetUser, setAssetUser] = useState(null)

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Asset User'
        )
    ) {
        navigate('/dashboard')
    }

    const getData = () => {
        axios
            .get(`/api/v1/attributes/asset_user/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    const { id, name, email, phoneNumber } = response.data
                    setAssetUser({
                        id,
                        name,
                        email,
                        phoneNumber,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }

    const postUpdate = () => {
        axios
            .patch(`/api/v1/attributes/asset_user`, {
                id: assetUser.id,
                name: assetUser.name,
                email: assetUser.email,
                phoneNumber: assetUser.phoneNumber,
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success Update Asset User')
                    setTimeout(function () {
                        navigate('/attribute/asset-user')
                    }, 2500)
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                if (error.response.data.message.includes('unique')) {
                    toast.error(
                        'Opps,... This Asset User has already been added to the system. Please check your details!'
                    )
                    return
                }
                toast.error(error.response.data.message)
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postUpdate()
    }

    useEffect(function () {
        if (typeof id !== 'undefined' && id !== null) {
            getData()
        }
    }, [])
    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit User</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/attribute/asset-user">Attributes</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit User</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        {!assetUser && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '80px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <p>Loading...</p>
                            </div>
                        )}
                        {assetUser && (
                            <AssetUserForm
                                data={assetUser}
                                onChange={setAssetUser}
                            />
                        )}
                    </div>
                    <Row className="mt-4">
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/attribute/asset-user')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
