import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import CategoryForm from '@/components/Attribute/Category/CategoryForm'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

export default function EditCategory() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [name, setName] = useState('')

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Asset Category'
        )
    ) {
        navigate('/dashboard')
    }

    const getCategory = () => {
        axios
            .get(`/api/v1/attributes/asset_category/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setName(response.data.name)
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }

    const postCategory = () => {
        axios
            .patch(`/api/v1/attributes/asset_category`, {
                id,
                name,
            })
            .then((response) => {
                //toast
                toast.success('Success Update Category')
                setTimeout(function () {
                    navigate('/attribute/category')
                }, 2500)
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
                if (error.response.data.message.includes('Category Name')) {
                    toast.error(
                        'Opps,... This Category has already been added to the system. Please check your details!'
                    )
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postCategory()
    }

    useEffect(function () {
        if (typeof id !== 'undefined' && id !== null) {
            getCategory()
        }
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Asset Category</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/attribute/category">Attributes</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit Asset Category</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmitForm}>
                    <div className="bg-white py-2 px-4">
                        <CategoryForm name={name} setName={setName} />
                    </div>
                    <Row>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/attribute/category')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
