import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import LocationForm from '@/components/Location/LocationForm'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { Form } from '@govtechsg/sgds-react/Form'

export default function EditUnit() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Unit'
        )
    ) {
        navigate('/dashboard')
    }

    const { id } = useParams()

    const [buildingList, setBuildingList] = useState([])

    const [name, setName] = useState('')

    const [room, setRoom] = useState('')

    const [buildingId, setBuildingId] = useState('')

    const getBuildingList = () => {
        axios
            .get('/api/v1/location/building/list')
            .then((res) => {
                setBuildingList(res.data)
            })
            .catch((err) => {
                console.error(err.response.data.message)
                err.handleGlobally && err.handleGlobally()
            })
    }

    const getUnitDetail = () => {
        axios
            .get(`/api/v1/location/unit/${id}`)
            .then((res) => {
                setName(res.data.name)
                setRoom(res.data.room)
                setBuildingId(res.data.buildingId)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios
            .patch(`/api/v1/location/unit`, {
                id,
                name,
                room,
                buildingId,
            })
            .then((response) => {
                //toast
                toast.success('Success Edit Unit')

                setTimeout(() => {
                    navigate('/location/unit')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        getUnitDetail()
        getBuildingList()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={'mb-0'}>Edit Unit</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/location/unit')}>
                    Units
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit Unit</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <div className="bg-white py-2 px-4">
                        <LocationForm
                            buildingList={buildingList}
                            name={name}
                            setName={setName}
                            room={room}
                            setRoom={setRoom}
                            buildingId={buildingId}
                            setBuildingId={setBuildingId}
                        />
                    </div>
                    <Row className={`mt-3`}>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => navigate('/location/unit')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="px-3"
                                    type={'submit'}
                                >
                                    Save
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
