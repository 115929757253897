import moment from 'moment'

export default function CustomDate({ date = null, time = false }) {
    if (date === '-') {
        return <>-</>
    }
    if (time === true) {
        return <>{moment(date).format('DD-MM-YYYY h:mm a')}</>
    }
    return <>{moment(date).format('DD-MM-YYYY')}</>
}
