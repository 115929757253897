import {
    Button,
    Col,
    Dropdown,
    Form,
    FormSelect,
    Modal,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import CustomPagination from '@/components/CustomPagination'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'
import BadgeCustom from '@/components/BadgeCustom'
import Select from 'react-select'

export default function AssetTicket() {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [data, setData] = useState(null)
    const [dataDelete, setDataDelete] = useState(null)
    const [filterStatus, setFilterStatus] = useState('All')
    const [status, setStatus] = useState()
    const [asset, setAsset] = useState(null)
    const [showModalStatus, setShowModalStatus] = useState(false)
    const [selectedId, setSelectedId] = useState('')

    const [comment, setComment] = useState(null)
    const [currentStatusList, setCurrentListStatus] = useState([])

    const statusList = [
        {
            label: 'Open',
            value: 'Open',
        },
        {
            label: 'Assigned',
            value: 'Assigned',
            isDisabled: true,
        },
        {
            label: 'In Progress',
            value: 'In Progress',
            isDisabled: true,
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
        {
            label: 'Closed',
            value: 'Closed',
        },
        {
            label: 'Reopened',
            value: 'Reopened',
        },
        {
            label: 'Archived',
            value: 'Archived',
            isDisabled: true,
        },
    ]
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Ticket'
        )
    ) {
        navigate('/dashboard')
    }

    const listStatus = [
        'Open',
        'Assigned',
        'In Progress',
        'Completed',
        'Closed',
        'Reopened',
        'Archived',
    ]

    const getData = (page = currentPage) => {
        if (typeof id === 'undefined') {
            return
        }
        setLoading(true)
        axios
            .get(
                `/api/v1/ticket/?assetId=${id}&page=${page}&size=${itemsPerPage}${
                    filterStatus !== 'All' ? '&statusList=' + filterStatus : ''
                }`
            )
            .then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    setData(response.data)
                    return
                }
            })
            .catch((error) => {
                if (error.response?.data) {
                    toast.error(error.response?.data.message)
                }
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    const handleChangeStatus = (e) => {
        e.preventDefault()

        const inputChangeStatus_ = {
            id: selectedId,
            status,
        }

        if (status === 'Closed' && comment !== null) {
            inputChangeStatus_.closeRemark = comment
        }

        axios
            .patch(`/api/v1/ticket/change_status`, inputChangeStatus_)
            .then((res) => {
                toast.success('Success Change Status')
                setShowModalStatus(false)
                getData()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleCloseStatus = () => {
        setShowModalStatus(false)
    }

    const deleteTicket = (id, name) => {
        axios
            .delete(`/api/v1/ticket/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Ticket ${name}`)
                getData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAsset({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                        ...res.data,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    useEffect(function () {
        if (asset === null) {
            getAsset()
        }
    }, [])

    useEffect(
        function () {
            getData()
        },
        [currentPage, itemsPerPage, filterStatus]
    )

    useEffect(() => {
        switch (status) {
            case 'Closed':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Reopened')
                )
                break
            case 'Open':
            case 'Reopened':
            case 'Assigned':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Closed')
                )

                break

            default:
                break
        }
    }, [status])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Asset Detail</h2>
            </sgds-content-header-top>

            {asset !== null && (
                <>
                    <AssetSummary item={asset} />

                    <AssetMenu id={id} item={asset} activeMenu="asset_ticket" />
                </>
            )}

            <div>
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group
                            className="mb-0"
                            controlId="formControlWithIcon"
                        >
                            <Form.Label>Filter by status</Form.Label>
                            <FormSelect
                                onChange={(e) => {
                                    setFilterStatus(e.target.value)
                                }}
                            >
                                <option>All</option>
                                {listStatus.map((item, index) => {
                                    return (
                                        <option value={item} key={index}>
                                            {item}
                                        </option>
                                    )
                                })}
                            </FormSelect>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>TICKET NUMBER</TableHeaderCell>
                                <TableHeaderCell>SUBJECT</TableHeaderCell>
                                <TableHeaderCell>SUBMIT BY</TableHeaderCell>
                                <TableHeaderCell>ASSIGN TO</TableHeaderCell>
                                <TableHeaderCell>BUILDING</TableHeaderCell>
                                <TableHeaderCell>REMARKS</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={8}
                                        className="text-center"
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading && data?.content.length === 0 && (
                                <TableRow>
                                    <TableDataCell
                                        colSpan={8}
                                        className="text-center"
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data?.content.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableDataCell>
                                                {item.runNumber}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.name}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.creatorUsername}
                                            </TableDataCell>

                                            <TableDataCell>
                                                {item.assignedUserName || '-'}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.assetBuildingLocation}
                                            </TableDataCell>
                                            <TableDataCell>
                                                {item.note}
                                            </TableDataCell>
                                            <TableDataCell>
                                                <BadgeCustom
                                                    status={item.status}
                                                />
                                            </TableDataCell>
                                            <TableDataCell>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className={`btn-clear`}
                                                    >
                                                        <i className="bi bi-three-dots"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'View Ticket'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/ticket/detail/${item.id}`
                                                                    )
                                                                }
                                                            >
                                                                See Ticket
                                                                Detail
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Update Ticket'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setSelectedId(
                                                                        item.id
                                                                    )
                                                                    setStatus(
                                                                        item.status
                                                                    )
                                                                    setShowModalStatus(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                Change Status
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Create Maintenance Schedule'
                                                        ) ? (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/ticket/maintenance/${item.id}`
                                                                    )
                                                                }
                                                            >
                                                                Maintenance
                                                                Schedule
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        {JSON.parse(
                                                            localStorage.getItem(
                                                                'currentAccessDtoList'
                                                            )
                                                        ).some(
                                                            (access) =>
                                                                access.name ===
                                                                'Delete Ticket'
                                                        ) &&
                                                        (item.status ===
                                                            'Open' ||
                                                            item.status ===
                                                                'Reopened') ? (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setDataDelete(
                                                                        {
                                                                            title:
                                                                                item?.runNumber ||
                                                                                '',
                                                                            id: item.id,
                                                                            show: true,
                                                                        }
                                                                    )
                                                                }}
                                                                className={`text-danger`}
                                                            >
                                                                Delete
                                                            </Dropdown.Item>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableDataCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Row>
                    <CustomPagination
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageDefault={10}
                        totalData={data?.totalElements}
                    />
                </Row>
                <Modal
                    show={showModalStatus}
                    onHide={handleCloseStatus}
                    centered={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Status</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleChangeStatus}>
                        <Modal.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group controlId={'Form.status'}>
                                        <Form.Label>Status</Form.Label>
                                        <Select
                                            options={currentStatusList}
                                            // isDisabled={disabled}
                                            value={currentStatusList.find(
                                                (option) =>
                                                    option.value === status
                                            )}
                                            onChange={(e) => setStatus(e.value)}
                                            required
                                        />
                                    </Form.Group>
                                    {status === 'Closed' && (
                                        <Form.Group
                                            controlId="Form.remarks"
                                            style={{ marginTop: '15px' }}
                                        >
                                            <Form.Label
                                                className={'form-label'}
                                            >
                                                Add Remarks
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required={true}
                                                onChange={(e) => {
                                                    setComment(e.target.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className={`justify-content-start`}>
                            <Button
                                variant="outline-dark"
                                onClick={handleCloseStatus}
                            >
                                Close
                            </Button>
                            <Button variant="primary" type={'submit'}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete Ticket"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteTicket(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
