import { Col, Form, Row } from '@govtechsg/sgds-react'
import Phone from '@/components/Phone'
import { useEffect, useState } from 'react'

export default function AssetUserForm({ data = null, onChange }) {
    const [assetUser, setAssetUser] = useState(data)

    useEffect(
        function () {
            onChange(assetUser)
        },
        [assetUser]
    )
    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="name">Contact Name</Form.Label>
                            <Form.Control
                                id="name"
                                value={assetUser.name}
                                onChange={(e) => {
                                    setAssetUser((prev) => {
                                        return { ...prev, name: e.target.value }
                                    })
                                }}
                                type="text"
                                placeholder=""
                                required
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="phoneNumber">Phone</Form.Label>
                            <Phone
                                value={assetUser.phoneNumber}
                                onChange={(e) => {
                                    setAssetUser((prev) => {
                                        return {
                                            ...prev,
                                            phoneNumber: e.target.value,
                                        }
                                    })
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                value={assetUser.email}
                                onChange={(e) => {
                                    setAssetUser((prev) => {
                                        return {
                                            ...prev,
                                            email: e.target.value,
                                        }
                                    })
                                }}
                                placeholder=""
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
