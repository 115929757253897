import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

export default function ImportAsset() {
    const refUpload = useRef()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [importedAssets, setImportedAssets] = useState([])
    const [bulkImportData, setBulkImportData] = useState([])
    const headers = [
        'Supplier Purchase Request',
        'Tag',
        'ECC6 Number',
        'IT Tag',
        'PO Date',
        'Category',
        'Sub Category',
        'Name',
        'PO Number',
        'Cost (before GST)',
        'Department',
        'Supplier',
        'Serial Number',
        'Invoice Number',
        'Invoice Date',
        'Commissioned Date',
        'Asset Administrator',
        'Asset User',
        'Building',
        'Unit',
        'Room/Location',
        'Point',
        'License Certification',
        'Notes',
        'Status',
    ]

    const listStatus = ['Requested', 'Pending', 'Commissioned', 'Deployed']

    const validateData = (data, callback) => {
        let input = []
        data.forEach((item) => {
            const asset = {
                name: item['Name'],
                hanaTag: item['Tag'],
                ecc6Tag: item['ECC6 Number'],
                itTag: item['IT Tag'],
                status: item['Status'],
                assetCategoryName: item['Category'],
                assetSubCategoryName: item['Sub Category'],
                assetDepartmentName: item['Department'],
                assetAdministratorName: item['Asset Administrator'],
                assetUserName: item['Asset User'],
                chargingAccount: null,
                unitName: item['Unit'],
                serialNumber: item['Serial Number'],
                commissionDate: item['Commissioned Date'],
                cost: item['Cost (before GST)'],
                licenseCertification: item['License Certification'],
                supplierPurchaseRequest: item['Supplier Purchase Request'],
                supplierName: item['Supplier'],
                supplierPoNo: item['PO Number'],
                supplierPoDate: item['PO Date'],
                invoiceNo: item['Invoice Number'],
                invoiceDate: item['Invoice Date'].replace('\r\n', ''),
                additionalInformation: item['Notes'],
            }

            let status = ''
            if (isNotNullAndEmpty(asset.supplierPurchaseRequest)) {
                status = 'Requested'
            }
            if (
                status === 'Requested' &&
                isNotNullAndEmpty(asset.hanaTag) &&
                isNotNullAndEmpty(asset.supplierPoDate) &&
                isNotNullAndEmpty(asset.assetCategoryName) &&
                isNotNullAndEmpty(asset.assetSubCategoryName) &&
                isNotNullAndEmpty(asset.cost) &&
                isNotNullAndEmpty(asset.supplierPoNo) &&
                isNotNullAndEmpty(asset.supplierName)
            ) {
                status = 'Pending'
            }
            if (
                status === 'Pending' &&
                isNotNullAndEmpty(asset.serialNumber) &&
                isNotNullAndEmpty(asset.invoiceDate) &&
                isNotNullAndEmpty(asset.invoiceNo) &&
                isNotNullAndEmpty(asset.commissionDate)
            ) {
                status = 'Commissioned'
            }
            if (
                status === 'Commissioned' &&
                isNotNullAndEmpty(asset.assetAdministratorName) &&
                isNotNullAndEmpty(asset.unitName)
            ) {
                status = 'Deployed'
            }
            if (asset.status !== 'MIA' && asset.status !== 'Archived') {
                asset.status = status
            }
            input.push(asset)
        })

        setBulkImportData(input)

        axios
            .post(`/api/v1/asset/validate_import/`, input)
            .then((response) => {
                setIsLoading(false)
                if (response.status === 200) {
                    if (response.data.length === 0) {
                        {
                            callback()
                            return
                        }
                    }
                    let hannaTags = []
                    let adminDepartments = []
                    let locations = []
                    let categories = []
                    let subcategories = []
                    let assetUsers = []
                    let suppliers = []
                    response.data.forEach((item) => {
                        if (
                            item.errorCause.includes(
                                'Hana Tag already Preset'
                            ) &&
                            !hannaTags.includes(item.hanaTag)
                        ) {
                            hannaTags.push(item.hanaTag)
                        }
                        if (
                            item.errorCause.includes(
                                'No Department with Role Type Admin'
                            ) &&
                            !adminDepartments.includes(
                                item.assetAdministratorName
                            )
                        ) {
                            adminDepartments.push(item.assetAdministratorName)
                        }
                        if (
                            item.errorCause.includes('No Location') &&
                            !locations.includes(item.unitName)
                        ) {
                            locations.push(item.unitName)
                        }
                        if (
                            item.errorCause.includes('No Asset Category') &&
                            !categories.includes(item.assetCategoryName)
                        ) {
                            categories.push(item.assetCategoryName)
                        }
                        if (
                            item.errorCause.includes('No Asset Sub Category') &&
                            !subcategories.includes(item.assetSubCategoryName)
                        ) {
                            subcategories.push(item.assetSubCategoryName)
                        }
                        if (
                            item.errorCause.includes('No Asset User') &&
                            !assetUsers.includes(item.assetUserName)
                        ) {
                            assetUsers.push(item.assetUserName)
                        }
                        if (
                            item.errorCause.includes('No Supplier') &&
                            !suppliers.includes(item.supplierName)
                        ) {
                            suppliers.push(item.supplierName)
                        }
                    })
                    let message = ''
                    if (hannaTags.length > 0) {
                        message += `Hanna Tag already Preset: ${hannaTags.join(
                            ','
                        )};`
                    }
                    if (adminDepartments.length > 0) {
                        message += `No Department with Role Type Admin: ${adminDepartments.join(
                            ','
                        )};`
                    }
                    if (categories.length > 0) {
                        message += `Category not found: ${categories.join(
                            ','
                        )};`
                    }
                    if (subcategories.length > 0) {
                        message += `Sub Category not found: ${subcategories.join(
                            ','
                        )};`
                    }
                    if (assetUsers.length > 0) {
                        message += `Asset User not found: ${assetUsers.join(
                            ','
                        )};`
                    }
                    if (suppliers.length > 0) {
                        message += `Suppliers User not found: ${suppliers.join(
                            ','
                        )};`
                    }
                    if (locations.length > 0) {
                        message += `Location not found: ${locations.join(',')};`
                    }
                    if (message === '') {
                        message += 'Status is incorrect'
                    }
                    toast.error('Opps,... ' + message)
                    refUpload.current.value = ''
                    setImportedAssets([])
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                setIsLoading(false)
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }

    const handleFileUpload = (e) => {
        setIsLoading(true)
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result)
            const workbook = XLSX.read(data, { type: 'array' })

            const sheet = workbook.Sheets[workbook.SheetNames[0]]

            const jsonData = XLSX.utils.sheet_to_json(sheet)

            validateData(jsonData, function () {
                setImportedAssets(jsonData)
            })
        }

        if (file) reader.readAsArrayBuffer(file)
    }

    const downloadTemplate = () => {
        const wb = XLSX.utils.book_new()

        //Sheet 1

        let sheet1 = []

        const rowData = [
            'PR/RES/15082012/WKN',
            '012333222',
            '66612322',
            '00012322',
            '2020-10-10',
            'Fridge',
            '4 deg',
            'Thermo Scientific Refrigerator PLR-1006, 1006L',
            'SN-001',
            '8000.45',
            'User Department',
            'Fisher Scientific Pte Ltd',
            'CT005183',
            'INV001',
            '2021-12-11',
            '2021-12-12',
            'Research',
            'Animal Facility',
            'CSB',
            '#11-South Wing',
            'Laboratory Isle',
            'point 1',
            'CR001',
            'Notes',
            'Deployed',
        ]

        sheet1.push(headers)
        sheet1.push(rowData)

        const ws = XLSX.utils.aoa_to_sheet(sheet1)
        XLSX.utils.book_append_sheet(wb, ws, 'Template')

        //Export
        XLSX.writeFile(wb, 'import_template.xlsx')
    }

    const isNotNullAndEmpty = (val) => {
        if (typeof val !== 'undefined' && val !== null && val !== '') {
            return true
        }
        return false
    }

    const handleSubmitBulk = () => {
        if (bulkImportData.length === 0) {
            return
        }
        setSubmitLoading(true)
        axios
            .post(`/api/v1/asset/bulk/`, bulkImportData)
            .then((response) => {
                setSubmitLoading(false)
                if (response.status === 200) {
                    toast.success('Success Add Bulk Assets')
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                setSubmitLoading(false)
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2>Import Asset</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/asset">Asset</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Import asset</BreadcrumbItem>
            </Breadcrumb>
            <div>
                <Button variant={'primary'} onClick={downloadTemplate}>
                    Download Template
                </Button>
            </div>
            <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                ref={refUpload}
            />
            <sgds-content-body>
                {isLoading && (
                    <div className="shadow-sm bg-white px-3 py-2 rounded w-100">
                        Load and Validate data...
                    </div>
                )}

                {!isLoading && importedAssets.length !== 0 && (
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive={'lg'}
                        className="shadow-sm bg-white px-3 py-2 rounded w-100"
                    >
                        <TableHeader>
                            <TableRow>
                                {headers.map((header) => {
                                    return (
                                        <TableHeaderCell>
                                            {header}
                                        </TableHeaderCell>
                                    )
                                })}
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {importedAssets.map((item, index) => (
                                <TableRow key={index}>
                                    {headers.map((header) => {
                                        return (
                                            <TableDataCell>
                                                {item[header]}
                                            </TableDataCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!isLoading && importedAssets.length !== 0 && (
                    <Row>
                        <Col>
                            <div className="d-flex gap-3 btn-purple">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                        navigate('/asset')
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={handleSubmitBulk}
                                    disabled={submitLoading}
                                >
                                    {submitLoading ? 'Loading...' : 'Submit'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                )}
            </sgds-content-body>
        </TemplateDashboard>
    )
}
