import {Col, Form, FormSelect, Row} from "@govtechsg/sgds-react";

export default function LocationForm({
                                         buildingList, name, setName, room, setRoom,
    buildingId,setBuildingId
                                     }) {

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Building</Form.Label>
                            <FormSelect
                                aria-label="Category"
                                id="defaultSelect"
                                size="sm"
                                value={buildingId}
                                required={true}
                                onChange={(e) => {setBuildingId(e.target.value)}}>
                            >
                                <option value={""}>Select Building</option>
                                {buildingList.map((building) => (
                                    <option key={building.id} value={building.id}>{building.name}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Unit Name</Form.Label>
                            <Form.Control type="text" placeholder="" size="sm" value={name}
                                          required={true}
                                          onChange={e => setName(e.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Room/Location</Form.Label>
                            <Form.Control type="text" placeholder="" size="sm" value={room}
                                          required={true}
                                          onChange={e => setRoom(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
