import { FormSelect, Pagination } from '@govtechsg/sgds-react'

export default function CustomPagination({
    setCurrentPage,
    setItemsPerPage,
    currentPage,
    itemsPerPage,
    totalData,
}) {
    const _setCurrentPage = (val) => {
        setCurrentPage(val)
    }
    const _setItemsPerPage = (e) => {
        setItemsPerPage(e.target.value)
    }
    return (
        <>
            <div className="d-flex gap-4 align-items-center justify-content-start">
                <small className="flex-shrink-0">Items per page:</small>
                <FormSelect
                    aria-label="Default select example"
                    id="defaultSelect"
                    size="sm"
                    onChange={_setItemsPerPage}
                    value={itemsPerPage}
                    className="w-auto"
                >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </FormSelect>
            </div>

            <div className="d-flex gap-3 justify-content-between align-items-end">
                <div className="d-flex gap-3 align-items-start flex-column">
                    <div className="d-flex justify-content-start">
                        <small>
                            Showing {(currentPage - 1) * itemsPerPage + 1}-
                            {currentPage * itemsPerPage} of {totalData}
                        </small>
                    </div>
                </div>
                <Pagination
                    dataLength={totalData}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    size="sm"
                    setCurrentPage={_setCurrentPage}
                    directionVariant="icon"
                />
            </div>
        </>
    )
}
