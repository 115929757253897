import { Col, Form, Row } from "@govtechsg/sgds-react";

export default function CategoryForm({ name, setName }) {

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required={true} placeholder="" size="sm" />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}