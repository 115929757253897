import {Button, Col, Row} from "@govtechsg/sgds-react";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";

export default function AttributeMenu({activeMenu}) {
    const menuList = JSON.parse(Cookies.get('menuList'))

    const listMenu = [
        {
            url: "/attribute/category",
            label: "Asset Category",
            name: "asset_categories",
            access: menuList.includes('Attributes,Asset Category') ?? false
        },
        {
            url: "/attribute/sub-category",
            label: "Asset Sub-Category",
            name: "sub_category",
            access: menuList.includes('Attributes,Asset Sub Category') ?? false
        },
        {
            url: "/attribute/vendor",
            label: "Vendors",
            name: "vendors",
            access: menuList.includes('Attributes,Vendor') ?? false
        },
        {
            url: "/attribute/asset-user",
            label: "Asset Users",
            name: "asset_users",
            access: menuList.includes('Asset User') ?? false
        }
    ]
    let activeMenuVariant = 'outline-primary';
    return (<>
        <Row>
            <Col md={12}>
                <div className="asset-menu d-block  d-lg-flex gap-2 align-item-center wrap">
                    {listMenu.map((item) =>
                        item.access ? (
                            <Link to={item.url} key={item.name}>
                                <Button
                                    variant={item.name === activeMenu ? activeMenuVariant : ''}
                                    size="sm"
                                    className={`${item.name === activeMenu ? 'active ' : ''}text-uppercase fw-bold`}
                                >
                                    {item.label}
                                </Button>
                            </Link>
                        ) : null
                    )}
                </div>
            </Col>
        </Row>
    </>)
}
