import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Row,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import ReportMenu from '@/components/Report/ReportMenu'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CustomDate from '@/components/CustomDate'
import CustomPagination from '@/components/CustomPagination'
import BadgeCustom from '@/components/BadgeCustom'

export default function AssetList() {
    let { id } = useParams()
    const [content, setContent] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const handleExportExcel = () => {
        axios
            .get(
                `/api/v1/asset/?size=999999${
                    id !== null && id !== '' && id !== 'all'
                        ? `&status=${id}`
                        : ''
                }`
            )
            .then((res) => {
                const data = res.data.content.map((asset, index) => ({
                    'NO.': index + 1,
                    ASSET: asset.name,
                    STATUS: asset.status,
                    'CURRENT LOCATION': `${asset.buildingName} ${asset.unitName}`,
                    'LAST UPDATED LOCATION': asset.updatedAt
                        ? moment(asset.updatedAt).format('YYYY-MM-DD HH:mm:ss')
                        : moment(asset.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                    'ASSET USER': asset.assetUserName,
                    'ASSET ADMIN': asset.assetAdministratorName,
                    'CREATED BY': asset.creatorUsername,
                }))

                const wb = XLSX.utils.book_new()

                const ws = XLSX.utils.json_to_sheet(data)

                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

                const fileName = `Asset-List-${moment().format(
                    'YYYY-MM-DD'
                )}.xlsx`

                toast.success('Success Export to Excel')

                XLSX.writeFile(wb, fileName)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        axios
            .get(
                `/api/v1/asset/?page=${currentPage}&size=${itemsPerPage}${
                    id !== null && id !== '' && id !== 'all'
                        ? `&status=${id}`
                        : ''
                }`
            )
            .then((resp) => {
                if (resp.status == 200) {
                    setContent(resp.data.content)
                    setTotalData(resp.data.totalElements)
                }
            })
    }, [currentPage, itemsPerPage])

    return (
        <TemplateDashboard>
            <ReportMenu activeMenu="asset_list" />

            <sgds-content-body>
                <div className="bg-white shadow-sm rounded p-4 btn-purple">
                    <Breadcrumb>
                        <BreadcrumbItem href="/report/asset">
                            ASSET LIST REPORT
                        </BreadcrumbItem>
                        <BreadcrumbItem active>ALL</BreadcrumbItem>
                    </Breadcrumb>
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>ASSET</TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell>
                                    CURRENT LOCATION
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    LAST UPDATED LOCATION
                                </TableHeaderCell>
                                <TableHeaderCell>ASSET USER</TableHeaderCell>
                                <TableHeaderCell>ASSET ADMIN</TableHeaderCell>
                                <TableHeaderCell>CREATED BY</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {content.map((data, index) => {
                                return (
                                    <TableRow>
                                        <TableDataCell>
                                            {index +
                                                1 +
                                                (currentPage - 1) *
                                                    itemsPerPage}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.name}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <BadgeCustom status={data.status} />
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.buildingName}{' '}
                                            {data.unitName
                                                ? '(' + data.unitName + ')'
                                                : '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={
                                                    data.updatedAt ||
                                                    data.createdAt
                                                }
                                                time={true}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.assetAdministratorName ?? '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.assetUserName ?? '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {data.creatorUsername}
                                        </TableDataCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
                <Row className="btn-purple">
                    <Col>
                        <Button
                            size="sm"
                            className="px-3"
                            onClick={handleExportExcel}
                        >
                            Export to excel
                        </Button>
                    </Col>
                </Row>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
