import { Button, Col, Row } from "@govtechsg/sgds-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import NewContract from "./modal/NewContract";

export default function MaintenanceMenu({ activeMenu, assetId, vendorList, formMaintenanceVendor, onChangeFormMaintenanceVendor, handleClickSubmitNewFormMaintenance }) {
    const [showModalAddContract, setShowModalAddContract] = useState(false);
    const listMenu = [
        {
            url: "/asset/maintenance/" + assetId,
            label: "Maintenance Contract",
            name: "maintenance_contract"
        },
        {
            url: "/asset/maintenance-log/" + assetId,
            label: "Maintenance Log",
            name: "maintenance_log"
        },
    ]
    let activeMenuVariant = 'outline-primary';
    return (
        <>
            <Row className="justify-content-between">
                <Col md={6}>
                    <div className="maintenance-menu d-flex wrap gap-3">
                        {listMenu.map(item => {
                            return (<Link to={item.url} key={item.name}>
                                <Button
                                    variant={item.name === activeMenu ? activeMenuVariant : ''}
                                    size="sm"
                                    className={`${item.name === activeMenu ? 'active ' : ''}text-uppercase fw-bold`}
                                >
                                    {item.label}
                                </Button>
                            </Link>)
                        })}
                    </div>
                </Col>
                <Col md={6} className="btn-purple text-end">
                    <Button size="sm px-4 rounded-pill" onClick={() => { setShowModalAddContract(true) }}>
                        <i className="bi bi-plus"></i>NEW CONTRACT
                    </Button>
                </Col>
            </Row>
            <NewContract
                show={showModalAddContract}
                setShow={setShowModalAddContract}
                assetId={assetId}
                isEdit={false}
                vendorList={vendorList}
                formMaintenanceVendor={formMaintenanceVendor}
                onChangeFormMaintenanceVendor={onChangeFormMaintenanceVendor}
                handleClickSubmitNewFormMaintenance={handleClickSubmitNewFormMaintenance}
            />
        </>
    )
}