import React from 'react'
import { Button, Col, Row } from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetSummary from '@/components/Asset/AssetSummary'
import AssetMenu from '@/components/Asset/AssetMenu'
import AssetForm from '@/components/Asset/AssetForm'
import AssetMenuVertical from '@/components/Asset/AssetMenuVertical'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

export default function AssetDetail() {
    const { id } = useParams()
    const [asset, setAssset] = useState(null)
    const [assetDetail, setAssetDetail] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const menuItems = [
        {
            key: 'asset_product_info',
            label: 'Asset Product Info',
        },
        {
            key: 'model_detail',
            label: 'Model Detail & Commission Information',
        },
        {
            key: 'supplier',
            label: 'Supplier',
        },
        {
            key: 'asset_media',
            label: 'Asset Media',
        },
        {
            key: 'additional_notes',
            label: 'Additional Notes',
        },
    ]

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAssset(res.data)
                    setAssetDetail({
                        serial_number: res.data.serialNumber,
                        asset_name: res.data.name,
                        tag: res.data.hanaTag,
                        category: res.data.assetCategoryName,
                        status: res.data.status,
                        images: res.data.assetFileDtoList,
                    })
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setTimeout(function () {
                    navigate('/asset')
                }, 2000)
            })
    }

    useEffect(function () {
        if (asset === null) {
            getAsset()
        }
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Asset Detail</h2>
            </sgds-content-header-top>

            {loading && <>Loading...</>}

            {!loading && (
                <>
                    {assetDetail !== null && (
                        <>
                            <AssetSummary item={assetDetail} />

                            <AssetMenu
                                id={id}
                                item={assetDetail}
                                activeMenu="asset_overview"
                            />
                        </>
                    )}

                    <sgds-content-body>
                        <div className="bg-white shadow-sm rounded p-4 asset-detail">
                            {asset !== null &&
                                asset.status !== 'MIA' &&
                                asset.status !== 'Archived' &&
                                asset.status !== 'Transferred' && (
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    navigate(
                                                        '/asset/edit/' + id
                                                    )
                                                }}
                                                variant="light"
                                            >
                                                <i className="bi bi-pencil"></i>{' '}
                                                <small>Edit information</small>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            <Row>
                                <Col md={3}>
                                    <AssetMenuVertical items={menuItems} />
                                </Col>
                                <Col md={9}>
                                    {asset !== null && (
                                        <AssetForm
                                            disabled={true}
                                            data={asset}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </sgds-content-body>
                </>
            )}
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
