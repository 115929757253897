import {
    Badge,
    Breadcrumb,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import { Form } from '@govtechsg/sgds-react/Form'
import { useNavigate, useParams } from 'react-router-dom'
import ShowQR from '@/components/Asset/modal/ShowQR'
import ShowBarcode from '@/components/Asset/modal/ShowBarcode'
import ShowImage from '@/components/Asset/modal/ShowImage'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import moment from 'moment'
import BadgeCustom from '@/components/BadgeCustom'

export default function TicketMaintenance() {
    const navigate = useNavigate()

    const [showModalQR, setShowModalQR] = useState(false)
    const [showModalBarcode, setShowModalBarcode] = useState(false)
    const [ShowModalImage, setShowModalImage] = useState(false)

    const { id } = useParams()

    const [detail, setDetail] = useState({})

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    const getTicketDetail = () => {
        axios
            .get(`/api/v1/ticket/${id}`)
            .then((res) => {
                setDetail({
                    ...res.data,
                    serial_number: res.data.assetSerialNumber,
                    asset_name: res.data.assetName,
                    tag: res.data.assetHanaTag,
                    category: res.data.assetCategoryName,
                    status: res.data.assetStatus,
                    images: res.data.assetFileDtoList,
                    maintenanceScheduleDtoList:
                        res.data.maintenanceScheduleDtoList,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getTicketDetail()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Maintenance Schedule</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/ticket')}>
                    Ticket
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    Maintenance schedule
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <div
                    className={`border border-1 rounded shadow-sm bg-white border-primary py-3 px-6`}
                >
                    <Row className={`mb-4`}>
                        <Col xs={6}>
                            <h4 className={`text-primary`}>ASSET SUMMARY</h4>
                        </Col>
                        <Col
                            xs={6}
                            className={`d-flex gap-3 justify-content-end`}
                        >
                            <Button
                                variant={'primary'}
                                onClick={() => setShowModalImage(true)}
                            >
                                <i className={'bi bi-image'}></i>
                            </Button>

                            <Button
                                variant={'primary'}
                                onClick={() => setShowModalQR(true)}
                            >
                                <i className={'bi bi-qr-code-scan'}></i>
                            </Button>

                            <Button
                                variant={'primary'}
                                onClick={() => setShowModalBarcode(true)}
                            >
                                <i className={'bi bi-upc-scan'}></i>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <Form.Label className={'form-label text-black-50'}>
                                Asset Name
                            </Form.Label>
                            <p>{detail.asset_name}</p>
                        </Col>
                        <Col>
                            <Form.Label className={'form-label text-black-50'}>
                                Tag
                            </Form.Label>
                            <p>{detail.assetHanaTag ?? '-'}</p>
                        </Col>
                        <Col>
                            <Form.Label className={'form-label text-black-50'}>
                                Category
                            </Form.Label>
                            <p>{detail.assetCategoryName ?? '-'}</p>
                        </Col>
                        <Col>
                            <Form.Label className={'form-label text-black-50'}>
                                Status
                            </Form.Label>
                            <p>{detail.assetStatus ?? '-'}</p>
                        </Col>
                    </Row>
                </div>

                {JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                    (access) => access.name === 'Create Maintenance Schedule'
                ) ? (
                    <div>
                        <Button
                            variant={'primary'}
                            className={`d-flex gap-2`}
                            style={{ width: 'fit-content' }}
                            onClick={() =>
                                navigate(
                                    `/maintenance/add/${detail.id}/${detail.runNumber}?faultReport=true`
                                )
                            }
                        >
                            <i className={`bi bi-plus-circle`}></i>
                            Add Maintenance schedule
                        </Button>
                    </div>
                ) : null}

                <div>
                    <Table borderless={true} className={`shadow-sm bg-white`}>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell>
                                    SCHEDULED DATE
                                </TableHeaderCell>
                                <TableHeaderCell>CHECK IN DATE</TableHeaderCell>
                                <TableHeaderCell>
                                    CHECK OUT DATE
                                </TableHeaderCell>
                                {/*<TableHeaderCell>*/}
                                {/*    TOTAL DOWN TIME*/}
                                {/*</TableHeaderCell>*/}
                                <TableHeaderCell>CREATED DATE</TableHeaderCell>
                                <TableHeaderCell>
                                    MAINTENANCE STATUS
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {detail.maintenanceScheduleDtoList?.map(
                                (maintenance, index) => (
                                    <TableRow>
                                        <TableDataCell>
                                            {maintenance.runNumber}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {maintenance.scheduledDate}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {maintenance.checkInDate ?? '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {maintenance.checkOutDate ?? '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {moment(
                                                maintenance.createdAt
                                            ).format('YYYY-MM-DD')}
                                        </TableDataCell>
                                        {/*<TableDataCell>*/}
                                        {/*    -*/}
                                        {/*</TableDataCell>*/}
                                        <TableDataCell>
                                            <BadgeCustom
                                                status={maintenance.status}
                                            />
                                        </TableDataCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </div>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
            <ShowQR
                show={showModalQR}
                setShow={setShowModalQR}
                value={detail?.tag ?? ''}
                assetDetail={detail}
            />
            <ShowBarcode
                show={showModalBarcode}
                setShow={setShowModalBarcode}
                value={detail?.tag ?? ''}
                assetDetail={detail}
            />
            <ShowImage
                show={ShowModalImage}
                setShow={setShowModalImage}
                value={detail}
            />
        </TemplateDashboard>
    )
}
