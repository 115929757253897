import { useState } from 'react'
import './AssetMenuVertical.css'

export default function AssetMenuVertical({ items, activeItem = "asset_product_info", isHaveChildren = false, onChangeActiveItems }) {
    const [active, setActive] = useState(activeItem)
    const handleClickScroll = (val) => {
        if (isHaveChildren) {
            onChangeActiveItems(val)
        }

        const element = document.getElementById(val);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
        setActive(val)
    };
    return (<div className="vertical-menu">
        <ul>
            {items.map(item => {
                if (isHaveChildren) {
                    return (<>
                        <li key={item.key} className={`${item.key === active ? "active" : ""}`} onClick={() => { handleClickScroll(item.key) }}>{item.label}
                        </li>
                        <ul>
                            {item.children.map((data, index2) => {
                                return (
                                    <li key={data.key} className={`${data.key === active ? "active" : ""}`} onClick={() => { handleClickScroll(data.key) }}>{data.label}</li>
                                )
                            })}
                        </ul>
                    </>)
                } else {
                    return <li key={item.key} className={`${item.key === active ? "active" : ""}`} onClick={() => { handleClickScroll(item.key) }}>{item.label}</li>

                }
            })}
        </ul>
    </div>)
}