import TemplateDashboard from '../../components/TemplateDashboard'
import {
    Dropdown,
    FormControl,
    FormControlGroup,
    Modal,
    Table,
    TableBody,
    TableDataCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from '@govtechsg/sgds-react'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from '@govtechsg/sgds-react/Form'
import CustomPagination from '../../components/CustomPagination'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'
import BadgeCustom from '@/components/BadgeCustom'
import CustomDate from '@/components/CustomDate'
import { checkAccess } from '@/helpers/checkAccess'

export default function Ticket() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Ticket'
        )
    ) {
        navigate('/dashboard')
    }

    const [showModalStatus, setShowModalStatus] = React.useState(false)

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const [filterStatus, setFilterStatus] = useState('')

    const [searchAssetName, setSearchAssetName] = useState('')

    const [selectedId, setSelectedId] = useState('')

    const [status, setStatus] = useState('')
    const [comment, setComment] = useState(null)

    const [dataDelete, setDataDelete] = useState(null)

    const [currentStatusList, setCurrentListStatus] = useState([])

    const statusList = [
        {
            label: 'Open',
            value: 'Open',
        },
        {
            label: 'Assigned',
            value: 'Assigned',
            isDisabled: true,
        },
        {
            label: 'In Progress',
            value: 'In Progress',
            isDisabled: true,
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
        {
            label: 'Closed',
            value: 'Closed',
        },
        {
            label: 'Reopened',
            value: 'Reopened',
        },
        {
            label: 'Archived',
            value: 'Archived',
            isDisabled: true,
        },
    ]

    const filterStatusList = [
        {
            label: 'All',
            value: '',
        },
        {
            label: 'Open',
            value: 'Open',
        },
        {
            label: 'Assigned',
            value: 'Assigned',
        },
        {
            label: 'In Progress',
            value: 'In Progress',
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
        {
            label: 'Closed',
            value: 'Closed',
        },
        {
            label: 'Reopened',
            value: 'Reopened',
        },
        {
            label: 'Archived',
            value: 'Archived',
        },
    ]

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/ticket/?assetName=${searchAssetName}&statusList=${filterStatus}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })

            setData(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }

    const handleChangeStatus = (e) => {
        e.preventDefault()

        const inputChangeStatus_ = {
            id: selectedId,
            status,
        }

        if (status === 'Closed' && comment !== null) {
            inputChangeStatus_.closeRemark = comment
        }

        axios
            .patch(`/api/v1/ticket/change_status`, inputChangeStatus_)
            .then((res) => {
                toast.success('Success Change Status')
                setShowModalStatus(false)
                fetchData()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleCloseStatus = () => {
        setShowModalStatus(false)
    }

    const deleteTicket = (id, name) => {
        axios
            .delete(`/api/v1/ticket/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Ticket ${name}`)
                fetchData()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    useEffect(() => {
        switch (status) {
            case 'Closed':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Reopened')
                )
                break
            case 'Open':
            case 'Reopened':
            case 'Assigned':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Closed')
                )

                break

            default:
                break
        }
    }, [status])

    useEffect(() => {
        fetchData()
    }, [searchAssetName, filterStatus, itemsPerPage, currentPage])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Tickets</h2>
            </sgds-content-header-top>

            <sgds-content-body>
                <Row>
                    <Col xs={6} lg={3}>
                        <Form.Group controlId="search">
                            <Form.Label>Search Asset</Form.Label>
                            <FormControlGroup
                                icon={<i className="bi bi-search"></i>}
                            >
                                <FormControl
                                    placeholder={'Search Asset'}
                                    onChange={(e) =>
                                        setSearchAssetName(e.target.value)
                                    }
                                />
                            </FormControlGroup>
                        </Form.Group>
                    </Col>

                    <Col xs={6} lg={3}>
                        <Form.Group controlId="filter">
                            <Form.Label>Status</Form.Label>
                            <Select
                                options={filterStatusList}
                                onChange={(e) => setFilterStatus(e.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/*<Col xs={6} className={`d-flex gap-3 align-items-end justify-content-end`}>*/}
                    {/*    <Link to={"/ticket/add"}*/}
                    {/*          className="btn btn-outline-dark sgds">*/}
                    {/*        <i className="bi bi-plus-circle form-control-icon"></i>*/}
                    {/*    </Link>*/}
                    {/*</Col>*/}
                </Row>
                <div className={`shadow-sm bg-white px-3 py-2 rounded`}>
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>NO</TableHeaderCell>
                                <TableHeaderCell width={'20%'}>
                                    ASSET NAME
                                </TableHeaderCell>
                                <TableHeaderCell>SUBJECT</TableHeaderCell>
                                <TableHeaderCell>SUBMIT BY</TableHeaderCell>
                                <TableHeaderCell>ASSIGN TO</TableHeaderCell>
                                <TableHeaderCell>BUILDING</TableHeaderCell>
                                <TableHeaderCell>CREATED DATE</TableHeaderCell>
                                <TableHeaderCell>
                                    FAULT RECTIFIED DATE
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    EQUIPMENT DOWNTIME
                                </TableHeaderCell>
                                <TableHeaderCell>STATUS</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        className="text-center w-100"
                                        colSpan={9}
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}

                            {!loading && data.length <= 0 && (
                                <TableRow>
                                    <TableDataCell
                                        className="text-center w-100"
                                        colSpan={9}
                                    >
                                        No Records
                                    </TableDataCell>
                                </TableRow>
                            )}

                            {!loading &&
                                data.map((row, index) => (
                                    <TableRow>
                                        <TableDataCell>
                                            {row.runNumber}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.assetName}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.name}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.creatorUsername}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.assignedUserName ?? '-'}
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.assetBuildingLocation}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={row.createdAt}
                                                time={true}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            <CustomDate
                                                date={row.updatedAt || '-'}
                                                time={true}
                                            />
                                        </TableDataCell>
                                        <TableDataCell>
                                            {row.totalDowntime}
                                        </TableDataCell>
                                        <TableDataCell>
                                            <BadgeCustom status={row.status} />
                                        </TableDataCell>
                                        <TableDataCell>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className={`btn-clear`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="5"
                                                        viewBox="0 0 16 5"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                            fill="#667085"
                                                        />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'View Ticket'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(
                                                                    `/ticket/detail/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            See Ticket Detail
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {checkAccess(
                                                        'Update Ticket'
                                                    ) &&
                                                    row.status.toLowerCase() !==
                                                        'completed' ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setSelectedId(
                                                                    row.id
                                                                )
                                                                setStatus(
                                                                    row.status
                                                                )
                                                                setShowModalStatus(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            Change Status
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Create Maintenance Schedule'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(
                                                                    `/ticket/maintenance/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            Maintenance Schedule
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Delete Ticket'
                                                    ) &&
                                                    (row.status === 'Open' ||
                                                        row.status ===
                                                            'Reopened') ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDataDelete({
                                                                    title:
                                                                        row?.runNumber ||
                                                                        '',
                                                                    id: row.id,
                                                                    show: true,
                                                                })
                                                            }}
                                                            className={`text-danger`}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    ) : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TableDataCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>

                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
                <Modal
                    show={showModalStatus}
                    onHide={handleCloseStatus}
                    centered={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Status</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleChangeStatus}>
                        <Modal.Body>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group controlId={'Form.status'}>
                                        <Form.Label>Status</Form.Label>
                                        <Select
                                            options={currentStatusList}
                                            // isDisabled={disabled}
                                            value={currentStatusList.find(
                                                (option) =>
                                                    option.value === status
                                            )}
                                            onChange={(e) => setStatus(e.value)}
                                            required
                                        />
                                    </Form.Group>
                                    {status === 'Closed' && (
                                        <Form.Group
                                            controlId="Form.remarks"
                                            style={{ marginTop: '15px' }}
                                        >
                                            <Form.Label
                                                className={'form-label'}
                                            >
                                                Add Remarks
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required={true}
                                                onChange={(e) => {
                                                    setComment(e.target.value)
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className={`justify-content-start`}>
                            <Button
                                variant="outline-dark"
                                onClick={handleCloseStatus}
                            >
                                Close
                            </Button>
                            <Button variant="primary" type={'submit'}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete Ticket"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteTicket(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
