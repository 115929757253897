import TemplateDashboard from '../../components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import axios from 'axios'
import Select from 'react-select'
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify'
import RequiredSymbol from '@/components/RequiredSymbol'
import CustomDatePicker from '@/components/CustomDatePicker'

export default function AddMaintenance() {
    const [searchParams, setSearchParams] = useSearchParams()
    const faultReport = searchParams.get('faultReport')
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Maintenance Schedule'
        )
    ) {
        navigate('/dashboard')
    }

    let { ticketId, ticketNo } = useParams()

    const [assetList, setAssetList] = useState([])

    const [vendorList, setVendorList] = useState([])

    const [unitList, setUnitList] = useState([])

    const [assetId, setAssetId] = useState('')

    const [category, setCategory] = useState(
        faultReport ? 'Fault Reporting' : 'Ad-hoc'
    )

    const [vendorId, setVendorId] = useState('')

    const [prNo, setPrNo] = useState('')

    const [poNo, setPoNo] = useState('')

    const [poDate, setPoDate] = useState()

    const [invoiceNo, setInvoiceNo] = useState('')

    const [invoiceDate, setInvoiceDate] = useState(null)

    const [cost, setCost] = useState('')

    const [chargingAccount, setChargingAccount] = useState('')

    const [unitId, setUnitId] = useState('')

    const [note, setNote] = useState('')

    const [scheduledDate, setScheduledDate] = useState(null)

    const [repairLocation, setRepairLocation] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const [ticketDetail, setTicketDetail] = useState({})

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}`)
            .then((res) => {
                const newArray = res.data.content.map((item) => ({
                    value: item.id,
                    label: `${item.name} ${
                        item.hanaTag !== null ? ` - ${item.hanaTag}` : ''
                    }${
                        item.assetCategoryName !== null
                            ? ` - ${item.assetCategoryName}`
                            : ''
                    }${
                        item.serialNumber !== null
                            ? ` - ${item.serialNumber}`
                            : ''
                    }${
                        item.supplierPoNo !== null
                            ? ` - ${item.supplierPoNo}`
                            : ''
                    }${
                        item.buildingName !== null
                            ? ` - ${item.buildingName}`
                            : ''
                    }${
                        item.supplierName !== null
                            ? ` - ${item.supplierName}`
                            : ''
                    }`,
                }))
                setAssetList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getVendorList = () => {
        axios
            .get(`/api/v1/attributes/vendor/list`)
            .then((res) => {
                const newArray = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
                setVendorList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getUnitList = () => {
        axios
            .get(`/api/v1/location/unit/list`)
            .then((res) => {
                const newArray = res.data.map((item) => ({
                    value: item.id,
                    label: `${item.buildingName} - ${item.name} - ${item.room}`,
                }))
                setUnitList(newArray)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let data = {}

        if (ticketId) {
            data = {
                ticketId,
                category,
                scheduledDate:
                    scheduledDate !== null
                        ? moment(scheduledDate).format('YYYY-MM-DD')
                        : null,
                vendorId,
                prNo,
                poNo,
                poDate: moment(poDate).format('YYYY-MM-DD'),
                invoiceNo,
                invoiceDate:
                    invoiceDate !== null
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : null,
                cost,
                chargingAccount,
                note,
                repairLocation,
            }
        } else {
            data = {
                assetId,
                category,
                scheduledDate:
                    scheduledDate !== null
                        ? moment(scheduledDate).format('YYYY-MM-DD')
                        : null,
                vendorId,
                prNo,
                poNo,
                poDate: moment(poDate).format('YYYY-MM-DD'),
                invoiceNo,
                invoiceDate:
                    invoiceDate !== null
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : null,
                cost,
                chargingAccount,
                note,
                repairLocation,
            }
        }

        axios
            .post(`/api/v1/maintenance_schedule/`, data)
            .then((res) => {
                toast.success('Success Add Maintenance Schedule')

                setTimeout(() => {
                    navigate('/maintenance')
                }, 1000)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const getTicketDetail = () => {
        axios
            .get(`/api/v1/ticket/${ticketId}`)
            .then((response) => {
                setTicketDetail(response.data)
            })
            .catch((error) => {
                console.error(error.response.data.message)
            })
    }

    useEffect(() => {
        // getAssetList()
        if (ticketId) {
            getTicketDetail()
        }

        getVendorList()
        getUnitList()
    }, [])

    useEffect(() => {
        getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>New Maintenance Schedule</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/maintenance')}>
                    Maintenance schedule
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    New maintenance schedule
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <Row
                        className={`shadow-sm bg-white p-7`}
                        style={{ rowGap: '10px' }}
                    >
                        {ticketNo && ticketId && (
                            <Col xs={12} className={`text-primary fw-bold`}>
                                # TICKET NO {ticketNo}
                            </Col>
                        )}

                        <Col xs={12}>
                            <Form.Group controlId="Form.asset">
                                <Form.Label>
                                    Choose Asset <RequiredSymbol />
                                </Form.Label>
                                {ticketNo && ticketId ? (
                                    <Form.Control
                                        type={'text'}
                                        disabled={true}
                                        value={ticketDetail.assetName}
                                    />
                                ) : (
                                    <Select
                                        options={assetList}
                                        required={true}
                                        onInputChange={(val) =>
                                            setSearchAsset(val)
                                        }
                                        onChange={(e) => setAssetId(e.value)}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.category">
                                <Form.Label className={'form-label'}>
                                    Category <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    disabled={true}
                                    value={category}
                                    onChange={() => {}}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.expired">
                                <Form.Label className={'form-label'}>
                                    Scheduled Date
                                </Form.Label>
                                <CustomDatePicker
                                    size="md"
                                    onChange={(e) => setScheduledDate(e)}
                                    value={scheduledDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.vendor">
                                <Form.Label className={'form-label'}>
                                    Vendor <RequiredSymbol />
                                </Form.Label>
                                <Select
                                    options={vendorList}
                                    required={true}
                                    onChange={(e) => setVendorId(e.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.prnumber">
                                <Form.Label className={'form-label'}>
                                    PR Number <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={prNo}
                                    onChange={(e) => {
                                        setPrNo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.ponumber">
                                <Form.Label className={'form-label'}>
                                    PO Number <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={poNo}
                                    onChange={(e) => {
                                        setPoNo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.podate">
                                <Form.Label className={'form-label'}>
                                    PO Date <RequiredSymbol />
                                </Form.Label>
                                <CustomDatePicker
                                    required={true}
                                    size="md"
                                    onChange={(e) => setPoDate(e)}
                                    value={poDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.invoice">
                                <Form.Label className={'form-label'}>
                                    Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    value={invoiceNo}
                                    onChange={(e) => {
                                        setInvoiceNo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.invoicedate">
                                <Form.Label className={'form-label'}>
                                    Invoice Date
                                </Form.Label>
                                <CustomDatePicker
                                    size="md"
                                    onChange={(e) => setInvoiceDate(e)}
                                    value={invoiceDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.cost">
                                <Form.Label className={'form-label'}>
                                    Cost (before GST) <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={cost}
                                    onChange={(e) => {
                                        setCost(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.charging">
                                <Form.Label className={'form-label'}>
                                    Charging Account <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    required={true}
                                    value={chargingAccount}
                                    onChange={(e) => {
                                        setChargingAccount(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.location">
                                <Form.Label className={'form-label'}>
                                    Location
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    onChange={(e) =>
                                        setRepairLocation(e.target.value)
                                    }
                                />
                                {/*<Select options={unitList} required={true} onChange={(val) => setUnitId(val)}/>*/}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.message">
                                <Form.Label className={'form-label'}>
                                    Message
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className={'form-control'}
                                    value={note}
                                    onChange={(e) => {
                                        setNote(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className={'d-flex mt-4 gap-4'}>
                        <Link
                            className="btn btn-outline-dark sgds"
                            to={'/maintenance'}
                        >
                            Cancel
                        </Link>
                        <Button variant="primary" type={'submit'}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
