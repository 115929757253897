import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Form,
    Row,
} from '@govtechsg/sgds-react'
import TemplateDashboard from '@/components/TemplateDashboard'
import AssetForm from '@/components/Asset/AssetForm'
import AssetMenuVertical from '@/components/Asset/AssetMenuVertical'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { checkAccess } from '@/helpers/checkAccess'

export default function EditAsset() {
    const { id } = useParams()
    const [asset, setAssset] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Update Asset'
        )
    ) {
        navigate('/dashboard')
    }

    const menuItems = [
        {
            key: 'asset_product_info',
            label: 'Asset Product Info',
        },
        {
            key: 'model_detail',
            label: 'Model Detail & Commission Information',
        },
        {
            key: 'supplier',
            label: 'Supplier',
        },
        {
            key: 'asset_media',
            label: 'Asset Media',
        },
        {
            key: 'additional_notes',
            label: 'Additional Notes',
        },
    ]

    const postUpdate = () => {
        const {
            assetAdministratorName,
            assetCategoryName,
            assetDepartmentName,
            assetSubCategoryName,
            assetUserName,
            buildingName,
            supplierName,
            unitName,
            creatorUsername,
            createdAt,
            createdBy,
            assetFileDtoList,
            ..._asset
        } = asset
        axios
            .patch(`/api/v1/asset/`, _asset)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Success Update Asset')
                    setTimeout(function () {
                        navigate('/asset/' + id)
                    }, 2500)
                    return
                }

                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                error.handleGlobally && error.handleGlobally()
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postUpdate()
    }

    const getAsset = () => {
        if (typeof id === 'undefined' || id === null || id === '') {
            return
        }
        axios
            .get('/api/v1/asset/' + id)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    const {
                        assetAttachmentDtoList,
                        assetMaintenanceContractDtoList,
                        unitPoint,
                        unitRoom,
                        updatedAt,
                        updatedBy,
                        updaterUsername,
                        ...data
                    } = res.data
                    setAssset(data)
                    return
                }
                toast.error(
                    'Opps,... Something went wrong, please try again later!'
                )
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            })
    }

    useEffect(function () {
        getAsset()
    }, [])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Edit Asset Information</h2>
            </sgds-content-header-top>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to={'/asset'}>Asset</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to={'/asset/' + id}>Asset Detail</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Edit Asset information</BreadcrumbItem>
            </Breadcrumb>
            <sgds-content-body>
                {loading && <>Loading...</>}
                {!loading && (
                    <>
                        <Form onSubmit={handleSubmitForm}>
                            <div className="bg-white py-2 px-4">
                                <Row>
                                    <Col md={3}>
                                        <AssetMenuVertical items={menuItems} />
                                    </Col>
                                    <Col md={9}>
                                        <AssetForm
                                            data={asset}
                                            onChange={setAssset}
                                            isUser={checkAccess('USER')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Row className="mt-4">
                                <Col>
                                    <div className="d-flex gap-3 btn-purple">
                                        <Button
                                            variant="outline-dark"
                                            onClick={() => {
                                                navigate('/asset')
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Save Changes
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
