import {Col, Form, FormSelect, Row} from "@govtechsg/sgds-react";

export default function DepartmentForm({
                                           name, assetTagPrefix, note,
                                           setAssetTagPrefix, setNote, setName,
                                           roleId, setRoleId,
                                           roleList
                                       }) {

    const handleRoleChange = (e) => {
        setRoleId(e.target.value)
    }

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Department Name</Form.Label>
                            <Form.Control type="text" placeholder="" size="sm"
                                          value={name}
                                          required={true}
                                          onChange={(e) => setName(e.target.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Role</Form.Label>
                            <FormSelect
                                aria-label="Category"
                                id="defaultSelect"
                                size="sm"
                                onChange={handleRoleChange}
                                value={roleId}
                            >
                                <option>Select role</option>
                                {
                                    roleList?.map((role, index) => (
                                        <option value={role.id} key={role.id}>{role.type} ({role.name})</option>
                                    ))
                                }

                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control type="text" placeholder="" size="sm"
                                          value={note}
                                          onChange={(e) => setNote(e.target.value)}/>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
