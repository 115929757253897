import { Col, Form, FormSelect, Row } from "@govtechsg/sgds-react";
import axios from "axios";
import { useEffect, useState } from "react";

export default function SubCategoryForm({ categoryId = null, refCategory, name, setName }) {

    const [categoryData, setCategoryData] = useState([])

    const getCategory = () => {
        axios
            .get(
                `/api/v1/attributes/asset_category/list`
            )
            .then((response) => {
                if (response.status === 200) {
                    setCategoryData(response.data)
                    return
                }
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })
    }

    useEffect(function () {
        getCategory();
    }, [])

    return (
        <div>
            <div className="py-3 px-1">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Category</Form.Label>
                            <FormSelect
                                aria-label="Category"
                                id="defaultSelect"
                                size="sm"
                                ref={refCategory}
                            >
                                {categoryData.map(item => {
                                    if (categoryId === item.id) {
                                        return <option selected value={item.id} key={item.id}>{item.name}</option>
                                    }
                                    return <option value={item.id} key={item.id}>{item.name}</option>
                                })}
                            </FormSelect>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Sub Category</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required placeholder="" size="sm" />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}