import { Badge, Breadcrumb, FileUpload, Modal } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import TemplateDashboard from '@/components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import moment from 'moment'

export default function TicketDetail() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Ticket'
        )
    ) {
        navigate('/dashboard')
    }

    const { id } = useParams()

    const [selectedFile, setSelectedFile] = useState({})

    const [showMessageModal, setShowMessageModal] = useState(false)

    const [disabled, setDisabled] = useState(false)

    const [departmentList, setDepartmentList] = useState([])

    const [userList, setUserList] = useState([])

    const [departmentId, setDepartmentId] = useState('')

    const [assignedUserId, setAssignedUserId] = useState(null)
    const [assignedUser, setAssignedUser] = useState(null)

    const [note, setNote] = useState('')

    const [status, setStatus] = useState('')
    const [originalData, setOriginalData] = useState({})

    // const [fileList, setFileList] = useState([])

    const [comment, setComment] = useState('')

    // const [comments, setComments] = useState([])

    const [data, setData] = useState([])

    const [currentStatusList, setCurrentListStatus] = useState([])

    const [isDisabled, setIsDisabled] = useState(true)

    const statusList = [
        {
            label: 'Open',
            value: 'Open',
        },
        {
            label: 'Assigned',
            value: 'Assigned',
            isDisabled: true,
        },
        {
            label: 'In Progress',
            value: 'In Progress',
            isDisabled: true,
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
        {
            label: 'Closed',
            value: 'Closed',
        },
        {
            label: 'Reopened',
            value: 'Reopened',
        },
        {
            label: 'Archived',
            value: 'Archived',
            isDisabled: true,
        },
    ]

    const handleCloseMessageModal = () => {
        setShowMessageModal(false)
    }

    const getDeparmentList = () => {
        axios
            .get(`/api/v1/department/list`)
            .then((res) => {
                const optionList = res.data.map((data) => ({
                    label: data.name,
                    value: data.id,
                }))

                setDepartmentList(optionList)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getUserList = () => {
        axios
            .get(`/api/v1/user/list/?departmentId=${departmentId}`)
            .then((res) => {
                const optionList = res.data.map((data) => ({
                    label: data.name,
                    value: data.id,
                }))

                setUserList(optionList)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getTicketDetail = () => {
        axios
            .get(`/api/v1/ticket/${id}`)
            .then((res) => {
                setNote(res.data.name)
                setDepartmentId(res.data.departmentId)
                setAssignedUserId(res.data.assignedUserId)
                setAssignedUser({
                    label: res.data.assignedUserName,
                    value: res.data.assignedUserId,
                })
                setStatus(res.data.status)
                setOriginalData({
                    status: res.data.status,
                    departmentId: res.data.departmentId,
                    assignedUserId: res.data.assignedUserId,
                })
                // setFileList(res.data.ticketFileDtoList)
                // setComments(res.data.ticketCommentDtoList)
                setData(res.data)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const getFiles = () => {
        axios
            .get(`/api/v1/ticket/${id}`)
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    const onChangeFile = (files) => {
        const formData = new FormData()

        if (files.length > 0) {
            formData.append('file', files[0])

            setSelectedFile(files)

            axios
                .post('/api/v1/ticket/upload_file', formData)

                .then((res, index) => {
                    axios
                        .post(`/api/v1/ticket/add_file_to_ticket/`, {
                            ticketId: id,
                            fileName: res.data.fileName,
                            fileUrl: res.data.url,
                        })
                        .then((res) => {
                            setSelectedFile(null)

                            toast.success(`Success Add Media`)

                            getFiles()
                        })
                        .catch((error) => {
                            toast.error(error.response.data.message)
                        })
                })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (
            originalData?.assignedUserId !== assignedUserId ||
            originalData?.departmentId !== departmentId
        ) {
            axios
                .patch(`/api/v1/ticket/assign_user`, {
                    id,
                    assignedUserId,
                    departmentId,
                })
                .then((res) => {
                    toast.success('Success Assign User')

                    getTicketDetail()
                })
                .catch((err) => {
                    toast.error(err.response.data.message)
                })
        }

        if (originalData?.status !== status) {
            {
                axios
                    .patch(`/api/v1/ticket/change_status`, {
                        id,
                        status,
                    })
                    .then((res) => {
                        toast.success('Success Change Status')
                        getTicketDetail()
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message)
                        getTicketDetail()
                    })
            }
        }
    }

    const handleDeleteFile = (fileId) => {
        axios
            .delete(`/api/v1/ticket/delete_file_from_ticket/${fileId}`)
            .then((res) => {
                toast.success('Success Delete Media')

                getTicketDetail()
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const handleSubmitComment = (e) => {
        e.preventDefault()

        axios
            .post(`/api/v1/ticket/comment`, {
                ticketId: id,
                comment,
            })
            .then((res) => {
                toast.success(`Success Add Comment`)
                setComment('')
                getTicketDetail()
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const handleChangeStatus = (e) => {
        setStatus(e.value)
    }

    useEffect(() => {
        getDeparmentList()
        getTicketDetail()
    }, [])

    useEffect(() => {
        setUserList([])
        setAssignedUserId('')
        getUserList()
    }, [departmentId])

    useEffect(() => {
        switch (status) {
            case 'Closed':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Reopened')
                )
                break
            case 'Open':
            case 'Reopened':
            case 'Assigned':
                setCurrentListStatus(
                    statusList.filter((item) => item.label === 'Closed')
                )

                break

            default:
                break
        }
    }, [status])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>Tickets</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/ticket')}>
                    Ticket
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    Ticket detail information
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <Row
                        className={`shadow-sm bg-white p-7`}
                        style={{ rowGap: '10px' }}
                    >
                        <Col
                            xs={12}
                            className={`d-flex justify-content-end align-items-center`}
                        >
                            <Button
                                className={`d-flex gap-2 text-primary text-decoration-underline btn-clear`}
                                type={'button'}
                                onClick={() => setIsDisabled((prev) => !prev)}
                            >
                                <i className={`bi bi-pencil`}></i>
                                Edit information
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="Form.department">
                                <Form.Label
                                    className={'form-label text-black-50'}
                                >
                                    Department
                                </Form.Label>
                                <Select
                                    options={departmentList}
                                    required={true}
                                    isDisabled={isDisabled}
                                    value={departmentList.find(
                                        (option) =>
                                            option.value === departmentId
                                    )}
                                    onChange={(e) => {
                                        setDepartmentId(e.value)
                                        setAssignedUser(null)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="Form.assign">
                                <Form.Label
                                    className={'form-label text-black-50'}
                                >
                                    Assign to
                                </Form.Label>
                                <Select
                                    options={userList}
                                    isDisabled={isDisabled}
                                    required={true}
                                    value={assignedUser}
                                    onChange={(e) => {
                                        setAssignedUserId(e.value)
                                        setAssignedUser(e)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.subject">
                                <Form.Label
                                    className={'form-label text-black-50'}
                                >
                                    Subject
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    disabled={true}
                                    value={note}
                                    onChange={(e) => {
                                        setNote(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Label className={'form-label text-black-50'}>
                                Message
                            </Form.Label>
                            <div>
                                <Button
                                    type={'button'}
                                    variant={'outline-dark'}
                                    // disabled={isDisabled}
                                    onClick={() => setShowMessageModal(true)}
                                >
                                    See Message
                                </Button>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.status">
                                <Form.Label
                                    className={'form-label text-black-50'}
                                >
                                    Status
                                </Form.Label>
                                <Select
                                    options={currentStatusList}
                                    value={statusList.find(
                                        (option) => option.value === status
                                    )}
                                    onChange={handleChangeStatus}
                                    isDisabled={isDisabled}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.media">
                                <Form.Label
                                    className={'form-label text-black-50'}
                                >
                                    Media
                                </Form.Label>
                                <FileUpload
                                    controlId="fileupload1"
                                    onChangeFile={onChangeFile}
                                    selectedFile={selectedFile}
                                    disabled={isDisabled}
                                >
                                    <i className="bi bi-upload me-2"></i>
                                    Choose a file
                                </FileUpload>
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={`d-flex flex-column`}>
                            {data.ticketFileDtoList?.map((file, index) => (
                                <div
                                    className={`d-flex align-items-center`}
                                    key={index}
                                >
                                    <Link to={file.fileUrl}>
                                        {file.fileName}
                                    </Link>

                                    <button
                                        type={'button'}
                                        className={`btn-clear`}
                                        onClick={() =>
                                            handleDeleteFile(file.id)
                                        }
                                    >
                                        <i
                                            className={`bi bi-x-circle`}
                                            style={{ color: 'red' }}
                                        ></i>
                                    </button>
                                </div>
                            ))}
                        </Col>
                    </Row>
                    {!isDisabled && (
                        <div className={'d-flex mt-5'} style={{ gap: '20px' }}>
                            <Link
                                className="btn btn-outline-dark sgds"
                                onClick={() => setIsDisabled(true)}
                            >
                                Cancel
                            </Link>
                            <Button variant="primary" type={'submit'}>
                                Submit
                            </Button>
                        </div>
                    )}
                </Form>
            </sgds-content-body>

            <Modal
                size={'xl'}
                show={showMessageModal}
                onHide={handleCloseMessageModal}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={6} className={'d-flex flex-column gap-3'}>
                            <div className={'shadow-sm bg-white p-3'}>
                                <Form onSubmit={handleSubmitComment}>
                                    <Form.Group controlId="Form.remarks">
                                        <Form.Label className={'form-label'}>
                                            Add Remarks
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required={true}
                                            onChange={(e) => {
                                                setComment(e.target.value)
                                            }}
                                        />
                                    </Form.Group>

                                    <div
                                        className={
                                            'd-flex mt-3 justify-content-end'
                                        }
                                        style={{ gap: '20px' }}
                                    >
                                        <Link
                                            className="btn btn-outline-dark sgds"
                                            onClick={handleCloseMessageModal}
                                        >
                                            Cancel
                                        </Link>
                                        <Button
                                            variant="primary"
                                            type={'submit'}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>

                            <div className={'shadow-sm bg-white px-2 py-3'}>
                                {data.ticketCommentDtoList?.map(
                                    (comment, index) => (
                                        <div
                                            className={
                                                'shadow-sm bg-white px-2 py-3 mb-3'
                                            }
                                            key={index}
                                        >
                                            <Row className={`gap-2`}>
                                                <Col
                                                    xs={12}
                                                    className={'d-flex gap-2'}
                                                >
                                                    <div>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="32"
                                                            height="32"
                                                            viewBox="0 0 32 32"
                                                            fill="none"
                                                        >
                                                            <circle
                                                                cx="16"
                                                                cy="16"
                                                                r="16"
                                                                fill="#D9D9D9"
                                                            />
                                                            <path
                                                                d="M16 16C16.7956 16 17.5587 15.6839 18.1213 15.1213C18.6839 14.5587 19 13.7956 19 13C19 12.2044 18.6839 11.4413 18.1213 10.8787C17.5587 10.3161 16.7956 10 16 10C15.2044 10 14.4413 10.3161 13.8787 10.8787C13.3161 11.4413 13 12.2044 13 13C13 13.7956 13.3161 14.5587 13.8787 15.1213C14.4413 15.6839 15.2044 16 16 16ZM18 13C18 13.5304 17.7893 14.0391 17.4142 14.4142C17.0391 14.7893 16.5304 15 16 15C15.4696 15 14.9609 14.7893 14.5858 14.4142C14.2107 14.0391 14 13.5304 14 13C14 12.4696 14.2107 11.9609 14.5858 11.5858C14.9609 11.2107 15.4696 11 16 11C16.5304 11 17.0391 11.2107 17.4142 11.5858C17.7893 11.9609 18 12.4696 18 13ZM22 21C22 22 21 22 21 22H11C11 22 10 22 10 21C10 20 11 17 16 17C21 17 22 20 22 21ZM21 20.996C20.999 20.75 20.846 20.01 20.168 19.332C19.516 18.68 18.289 18 16 18C13.71 18 12.484 18.68 11.832 19.332C11.154 20.01 11.002 20.75 11 20.996H21Z"
                                                                fill="#344054"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        {
                                                            comment.creatorUsername
                                                        }
                                                    </div>
                                                    <div
                                                        className={
                                                            'text-black-50'
                                                        }
                                                    >
                                                        {moment(
                                                            comment.createdAt
                                                        ).format(
                                                            'YYYY-MM-DD HH:mm:ss'
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col xs={12}>
                                                    <div
                                                        className={`border border-1 border-black bg-dark-300 p-2`}
                                                    >
                                                        {comment.comment}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                )}
                            </div>
                        </Col>

                        <Col xs={6}>
                            <div
                                className={
                                    'd-flex flex-column gap-5 shadow-sm bg-white p-3'
                                }
                            >
                                <div>
                                    <h3 className={`mb-4`}>Ticket Summary</h3>

                                    <Row style={{ rowGap: '15px' }}>
                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                STATUS
                                            </Form.Label>
                                            <div
                                                className={`d-flex align-items-center gap-2`}
                                            >
                                                {disabled ? (
                                                    <Select
                                                        options={statusList}
                                                        // isDisabled={disabled}
                                                        value={statusList.find(
                                                            (option) =>
                                                                option.value ===
                                                                status
                                                        )}
                                                        onChange={
                                                            handleChangeStatus
                                                        }
                                                    />
                                                ) : (
                                                    <Badge
                                                        className={'me-2'}
                                                        bg="secondary"
                                                        text="white"
                                                    >
                                                        {status}
                                                    </Badge>
                                                )}

                                                {!disabled && (
                                                    <Link
                                                        onClick={() => {
                                                            setDisabled(
                                                                !disabled
                                                            )
                                                        }}
                                                    >
                                                        {' '}
                                                        {disabled
                                                            ? 'Cancel'
                                                            : 'Change'}
                                                    </Link>
                                                )}
                                            </div>
                                            {disabled && (
                                                <Link
                                                    onClick={() => {
                                                        setDisabled(!disabled)
                                                    }}
                                                >
                                                    {' '}
                                                    {disabled
                                                        ? 'Cancel'
                                                        : 'Change'}
                                                </Link>
                                            )}
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                CREATED
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {moment(data.createdAt).format(
                                                    'YYYY-MM-DD HH:mm:ss'
                                                )}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                SUBJECT
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.name}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                ASSIGN TO
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assignedUserName}
                                            </div>
                                        </Col>

                                        {/*<Col xs={6}>*/}
                                        {/*    <Form.Label*/}
                                        {/*        className={*/}
                                        {/*            'form-label text-black-50'*/}
                                        {/*        }*/}
                                        {/*    >*/}
                                        {/*        REMARK*/}
                                        {/*    </Form.Label>*/}
                                        {/*    <div className={`fw-bold`}>*/}
                                        {/*        -*/}
                                        {/*    </div>*/}
                                        {/*</Col>*/}

                                        <Col xs={12}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                ATTACHMENTS
                                            </Form.Label>
                                            <div className={``}>
                                                {data.ticketFileDtoList?.map(
                                                    (file, index) => (
                                                        <div
                                                            className={`overflow-hidden text-overflow-ellipsis text-nowrap`}
                                                            key={index}
                                                        >
                                                            <Link
                                                                to={
                                                                    file.fileUrl
                                                                }
                                                            >
                                                                {file.fileName}
                                                            </Link>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                DEPARTMENT
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.departmentName}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <h3 className={`mb-4`}>
                                        LATEST MAINTENANCE SCHEDULE
                                    </h3>

                                    <Row style={{ rowGap: '15px' }}>
                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                STATUS
                                            </Form.Label>
                                            <div>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0 ? (
                                                    <Badge
                                                        className={'me-2'}
                                                        bg="secondary"
                                                        text="white"
                                                    >
                                                        {
                                                            data
                                                                ?.maintenanceScheduleDtoList[0]
                                                                ?.status
                                                        }
                                                    </Badge>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                INVOICE NUMBER
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.invoiceNo
                                                    : '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                PR NUMBER
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.prNo
                                                    : '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                INVOICE DATE
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.invoiceDate
                                                    : '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                PO NUMBER
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.poNo
                                                    : '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                SERVICE REPORT NUMBER
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.runNumber
                                                    : '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                PO DATE
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data
                                                    ?.maintenanceScheduleDtoList
                                                    ?.length > 0
                                                    ? data
                                                          ?.maintenanceScheduleDtoList[0]
                                                          ?.poDate
                                                    : '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <h3 className={`mb-4`}>ASSET SUMMARY</h3>

                                    <Row style={{ rowGap: '15px' }}>
                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                STATUS
                                            </Form.Label>
                                            <div>
                                                <Badge
                                                    className={'me-2'}
                                                    bg="success"
                                                    text="white"
                                                >
                                                    {data.assetStatus ?? '-'}
                                                </Badge>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                CATEGORY
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetCategoryName ?? '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                ASSET NAME
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetName}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                ASSET ADMIN
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetAdministratorName ??
                                                    '-'}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                TAG
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetHanaTag}
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                SERIAL NUMBER
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetSerialNumber}
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Label
                                                className={
                                                    'form-label text-black-50'
                                                }
                                            >
                                                LOCATION
                                            </Form.Label>
                                            <div className={`fw-bold`}>
                                                {data.assetBuildingLocation +
                                                    ' - ' +
                                                    data.assetLocation}
                                                {data.assetRoomLocation !==
                                                    null &&
                                                data.assetRoomLocation !== ''
                                                    ? ' - ' +
                                                      data.assetRoomLocation
                                                    : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
