import { Button, Col, Row } from "@govtechsg/sgds-react";
import { Link } from "react-router-dom";

export default function PeopleMenu({ activeMenu }) {
    const listMenu = [
        {
            url: "/people/users",
            label: "User",
            name: "people_user"
        },
        {
            url: "/people/admin",
            label: "Admin",
            name: "people_admin"
        },
        {
            url: "/people/Roles",
            label: "User Role",
            name: "people_role"
        },
    ]
    let activeMenuVariant = 'outline-primary';
    return (<>
        <Row>
            <Col md={12}>
                <div className="asset-menu d-block  d-lg-flex gap-2 align-item-center wrap">
                    {listMenu.map(item => {
                        return (<Link to={item.url} key={item.name}>
                            <Button
                                variant={item.name === activeMenu ? activeMenuVariant : ''}
                                size="sm"
                                className={`${item.name === activeMenu ? 'active ' : ''}text-uppercase fw-bold`}
                            >
                                {item.label}
                            </Button>
                        </Link>)
                    })}
                </div>
            </Col>
        </Row>
    </>)
}
