import React, { useEffect, useState } from 'react'
import TemplateDashboard from '@/components/TemplateDashboard'
import { Dropdown, TableDataCell, TableRow } from '@govtechsg/sgds-react'
import { Form } from '@govtechsg/sgds-react/Form'
import { Table } from '@govtechsg/sgds-react/Table'
import CustomPagination from '@/components/CustomPagination'
import PeopleMenu from '@/components/People/PeopleMenu'
import { Col } from '@govtechsg/sgds-react/Col'
import { Link, useNavigate } from 'react-router-dom'
import { Row } from '@govtechsg/sgds-react/Row'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import ModalDelete from '@/components/ModalDelete'

function Roles() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'View Role'
        )
    ) {
        navigate('/dashboard')
    }

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const [search, setSearch] = useState('')
    const [dataDelete, setDataDelete] = useState(null)

    const headers = [
        { key: 'id', label: 'ID' },
        { key: 'roleName', label: 'ROLE NAME' },
        { key: 'roleType', label: 'ROLE TYPE' },
        { key: 'etc', label: '' },
    ]

    const fetchData = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios
                .get(
                    `/api/v1/role/?name=${search}&size=${itemsPerPage}&page=${currentPage}`
                )
                .catch((err) => {
                    err.handleGlobally && err.handleGlobally()
                })
            setData(response.content)
            setTotalData(response.totalElements)
        } catch (error) {
            console.error(error.response.data.message)
        }
        setLoading(false)
    }

    const deleteRole = (id, name) => {
        axios
            .delete(`/api/v1/role/${id}`)
            .then((response) => {
                //toast
                setDataDelete({})
                toast.success(`Success Delete Role ${name}`)
                fetchData()
            })
            .catch((error) => {
                console.error(error.response.data.message)
            })
    }

    useEffect(() => {
        fetchData()
    }, [search, currentPage, itemsPerPage])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <PeopleMenu activeMenu="people_role" />
            </sgds-content-header-top>

            <h2 className={`mb-0`}>Set User Role</h2>

            <Row className={`align-items-end justify-content-between`}>
                <Col xs={4}>
                    <Form.Group controlId="formControlWithIcon">
                        <Form.Label>Search</Form.Label>
                        <Form.Control.Group
                            icon={<i className="bi bi-search"></i>}
                        >
                            <Form.Control
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Form.Control.Group>
                    </Form.Group>
                </Col>
                {JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
                    (access) => access.name === 'Create User'
                ) ? (
                    <Col xs={6} className={`d-flex justify-content-end`}>
                        <Link
                            to={'/people/roles/add'}
                            className="btn btn-outline-dark sgds"
                        >
                            <i className="bi bi-plus-circle form-control-icon"></i>
                        </Link>
                    </Col>
                ) : null}
            </Row>

            <sgds-content-body>
                <div className="shadow-sm bg-white px-3 py-2 rounded">
                    <Table
                        borderless={true}
                        hover={true}
                        size="sm"
                        responsive="lg"
                    >
                        <Table.Header>
                            <Table.Row>
                                {headers.map((header, index) => (
                                    <Table.HeaderCell key={index}>
                                        {header.label}
                                        {/*{header.key === 'etc' ? (*/}
                                        {/*    header.label*/}
                                        {/*) : (*/}
                                        {/*    <Table.SortLabel*/}
                                        {/*        onClick={sortHandler(header.key)}*/}
                                        {/*        active={orderBy === header.key}*/}
                                        {/*        direction={order}*/}
                                        {/*    >*/}
                                        {/*        {header.label}*/}
                                        {/*    </Table.SortLabel>*/}
                                        {/*)}*/}
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {loading && (
                                <TableRow>
                                    <TableDataCell
                                        className="text-center w-100"
                                        colSpan={headers.length}
                                    >
                                        Loading...
                                    </TableDataCell>
                                </TableRow>
                            )}
                            {!loading &&
                                data.map((row, index) => (
                                    <Table.Row key={index}>
                                        <Table.DataCell>
                                            {row.id}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            {row.name}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            {row.type}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className={`btn-clear`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="5"
                                                        viewBox="0 0 16 5"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M2.6878 4.23083C2.22877 4.23083 1.78854 4.04848 1.46396 3.7239C1.13938 3.39931 0.957031 2.95908 0.957031 2.50006C0.957031 2.04103 1.13938 1.6008 1.46396 1.27622C1.78854 0.951635 2.22877 0.769287 2.6878 0.769287C3.14683 0.769287 3.58706 0.951635 3.91164 1.27622C4.23622 1.6008 4.41857 2.04103 4.41857 2.50006C4.41857 2.95908 4.23622 3.39931 3.91164 3.7239C3.58706 4.04848 3.14683 4.23083 2.6878 4.23083ZM8.45703 4.23083C7.998 4.23083 7.55778 4.04848 7.23319 3.7239C6.90861 3.39931 6.72626 2.95908 6.72626 2.50006C6.72626 2.04103 6.90861 1.6008 7.23319 1.27622C7.55778 0.951635 7.998 0.769287 8.45703 0.769287C8.91606 0.769287 9.35629 0.951635 9.68087 1.27622C10.0055 1.6008 10.1878 2.04103 10.1878 2.50006C10.1878 2.95908 10.0055 3.39931 9.68087 3.7239C9.35629 4.04848 8.91606 4.23083 8.45703 4.23083ZM14.2263 4.23083C13.7672 4.23083 13.327 4.04848 13.0024 3.7239C12.6778 3.39931 12.4955 2.95908 12.4955 2.50006C12.4955 2.04103 12.6778 1.6008 13.0024 1.27622C13.327 0.951635 13.7672 0.769287 14.2263 0.769287C14.6853 0.769287 15.1255 0.951635 15.4501 1.27622C15.7747 1.6008 15.957 2.04103 15.957 2.50006C15.957 2.95908 15.7747 3.39931 15.4501 3.7239C15.1255 4.04848 14.6853 4.23083 14.2263 4.23083Z"
                                                            fill="#667085"
                                                        />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Update Role'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(
                                                                    `/people/roles/edit/${row.id}`
                                                                )
                                                            }
                                                        >
                                                            Edit Role
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {JSON.parse(
                                                        localStorage.getItem(
                                                            'currentAccessDtoList'
                                                        )
                                                    ).some(
                                                        (access) =>
                                                            access.name ===
                                                            'Delete Role'
                                                    ) ? (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setDataDelete({
                                                                    title:
                                                                        row?.name ||
                                                                        '',
                                                                    id: row.id,
                                                                    show: true,
                                                                })
                                                            }}
                                                            className={`text-danger`}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    ) : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Table.DataCell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </div>
                <CustomPagination
                    setCurrentPage={setCurrentPage}
                    setItemsPerPage={setItemsPerPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageDefault={10}
                    totalData={totalData}
                />
                <ModalDelete
                    show={dataDelete?.show}
                    title="Delete User Role"
                    message={`Are you sure wanto to delete ${dataDelete?.title}?`}
                    handleClose={() => {
                        setDataDelete({})
                    }}
                    handleDelete={() => {
                        deleteRole(dataDelete.id, dataDelete.title)
                    }}
                />
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}

export default Roles
