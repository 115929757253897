import { Breadcrumb } from '@govtechsg/sgds-react'
import { BreadcrumbItem, Button } from 'react-bootstrap'
import { Form } from '@govtechsg/sgds-react/Form'
import { Row } from '@govtechsg/sgds-react/Row'
import { Col } from '@govtechsg/sgds-react/Col'
import { Link, useNavigate } from 'react-router-dom'
import TemplateDashboard from '@/components/TemplateDashboard'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import CustomDatePicker from '@/components/CustomDatePicker'
import RequiredSymbol from '@/components/RequiredSymbol'

export default function AddLoan() {
    const navigate = useNavigate()

    if (
        !JSON.parse(localStorage.getItem('currentAccessDtoList')).some(
            (access) => access.name === 'Create Asset Loan'
        )
    ) {
        navigate('/dashboard')
    }

    const [assetList, setAssetList] = useState([])

    const [assetId, setAssetId] = useState('')

    const [loanedTo, setLoanedTo] = useState('')

    const [borrowerEmail, setBorrowerEmail] = useState('')

    const [borrowerPhoneNo, setBorrowerPhoneNo] = useState('')

    const [searchAsset, setSearchAsset] = useState('')

    const [expectedLoanDate, setExpectedLoanDate] = useState('')

    const [expectedReturnDate, setExpectedReturnDate] = useState('')

    const [type, setType] = useState('Internal Loan')

    const handleSubmit = (e) => {
        e.preventDefault()

        axios
            .post(`/api/v1/asset_loan/`, {
                assetId,
                loanedTo,
                borrowerEmail,
                borrowerPhoneNo,
                expectedLoanDate,
                expectedReturnDate,
                type,
            })
            .then((response) => {
                toast.success('Success Add Loan')

                setTimeout(() => {
                    navigate('/loan')
                }, 1000)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }

    const getAssetList = () => {
        axios
            .get(`/api/v1/asset/?name=${searchAsset}&status=Deployed`)
            .then((res) => {
                const formattedData = res.data.content.map((item) => ({
                    label: `${item.name} ${
                        item.hanaTag !== null ? ` - ${item.hanaTag}` : ''
                    }${
                        item.assetCategoryName !== null
                            ? ` - ${item.assetCategoryName}`
                            : ''
                    }${
                        item.serialNumber !== null
                            ? ` - ${item.serialNumber}`
                            : ''
                    }${
                        item.supplierPoNo !== null
                            ? ` - ${item.supplierPoNo}`
                            : ''
                    }${
                        item.buildingName !== null
                            ? ` - ${item.buildingName}`
                            : ''
                    }${
                        item.supplierName !== null
                            ? ` - ${item.supplierName}`
                            : ''
                    }`,
                    value: item.id,
                }))
                setAssetList(formattedData)
            })
            .catch((err) => {
                console.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getAssetList()
    }, [searchAsset])

    return (
        <TemplateDashboard>
            <sgds-content-header-top>
                <h2 className={`mb-0`}>New Loan Asset</h2>
            </sgds-content-header-top>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate('/loan')}>
                    Loan asset
                </BreadcrumbItem>
                <BreadcrumbItem className={'fw-bold'} active>
                    New loan asset
                </BreadcrumbItem>
            </Breadcrumb>

            <sgds-content-body>
                <Form onSubmit={handleSubmit}>
                    <Row
                        className={`shadow-sm bg-white p-7`}
                        style={{ rowGap: '10px' }}
                    >
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="Form.asset">
                                <Form.Label className={'form-label'}>
                                    Choose asset <RequiredSymbol />
                                </Form.Label>

                                <Select
                                    required
                                    options={assetList}
                                    onInputChange={(val) => setSearchAsset(val)}
                                    onChange={(e) => setAssetId(e.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group controlId="Form.loanto">
                                <Form.Label className={'form-label'}>
                                    Loan to <RequiredSymbol />
                                </Form.Label>
                                <Form.Control
                                    required
                                    type={'text'}
                                    onChange={(e) => {
                                        setLoanedTo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Group controlId="Form.phone">
                                <Form.Label className={'form-label'}>
                                    Phone Number
                                </Form.Label>
                                <Form.Control
                                    type={'text'}
                                    onChange={(e) => {
                                        setBorrowerPhoneNo(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Group controlId="Form.email">
                                <Form.Label className={'form-label'}>
                                    Email
                                </Form.Label>
                                <Form.Control
                                    type={'email'}
                                    onChange={(e) => {
                                        setBorrowerEmail(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Group controlId="Form.loandate">
                                <Form.Label className={'form-label'}>
                                    Expected Loan Date <RequiredSymbol />
                                </Form.Label>
                                <CustomDatePicker
                                    size={'md'}
                                    required={true}
                                    onChange={(e) => setExpectedLoanDate(e)}
                                    value={expectedLoanDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Group controlId="Form.return">
                                <Form.Label className={'form-label'}>
                                    Expected Return Date <RequiredSymbol />
                                </Form.Label>
                                <CustomDatePicker
                                    size={'md'}
                                    required={true}
                                    onChange={(e) => setExpectedReturnDate(e)}
                                    value={expectedReturnDate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group controlId="Form.location">
                                <Form.Label className={'form-label'}>
                                    Internal/External Loan <RequiredSymbol />
                                </Form.Label>
                                <Form.Select
                                    onChange={(e) => setType(e.target.value)}
                                    value={type}
                                >
                                    <option value={'Internal Loan'}>
                                        Internal Loan
                                    </option>
                                    <option value={'External Loan'}>
                                        External Loan
                                    </option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className={'d-flex mt-4 gap-4'}>
                        <Link
                            className="btn btn-outline-dark sgds"
                            to={'/loan'}
                        >
                            Cancel
                        </Link>
                        <Button variant="primary" type={'submit'}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </sgds-content-body>
            <ToastContainer autoClose={10000} />
        </TemplateDashboard>
    )
}
